import { put, takeLatest, all } from 'redux-saga/effects'

import * as notificationActions from '../../notification/actions'
import * as tenantConfigActions from '../actions'

function* onGetConfigForUpdateFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to load the config' }))
}

function* getConfig() {
  yield all([takeLatest(tenantConfigActions.getConfigForUpdateFailed, onGetConfigForUpdateFailed)])
}

export default getConfig
