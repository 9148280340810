/* eslint-disable no-undef */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { getFormTitleLowerCase, getShowTimeZoneOnUploadAnswers } from '@tabeeb/modules/appConfigState/selectors'
import { FormAnswersFromFileLoadingStatus } from '@tabeeb/modules/forms/services/enums'
import CircularProgressBar from '@tabeeb/modules/presentation/components/CircularProgressBar'
import { addFormAnswersFromFile } from '@tabeeb/modules/forms/actions'
import './styles.less'
import TimeZonePicker from '@tabeeb/modules/shared/uikit/components/TimeZonePicker'
import moment from 'moment'
import { Box } from '@mui/material'

const FormAnswersUploadFileExampleUrl = new URL('@tabeeb/assets/docs/uploadformanswerstemplate.csv', import.meta.url)

const UploadFormAnswersDialog = ({ isOpen, onCloseDialog, loadingState }) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess())
  const showTimeZoneDropdown = useSelector(getShowTimeZoneOnUploadAnswers)

  const dispatch = useDispatch()

  const loadingStatus = loadingState.status
  const { errors } = loadingState

  const isLoading = loadingStatus === FormAnswersFromFileLoadingStatus.Loading
  const isFailed = loadingStatus === FormAnswersFromFileLoadingStatus.Failed

  const _handleSubmit = () => {
    const formData = new FormData()
    formData.append('File', selectedFile)
    const timeZoneOffsetInMinutes = showTimeZoneDropdown ? -moment.tz(selectedTimeZone).utcOffset() : 0

    dispatch(addFormAnswersFromFile({ formData, timeZoneOffsetInMinutes }))
    setSelectedFile(null)
  }

  const _handleCloseDialog = () => {
    setSelectedFile(null)
    onCloseDialog()
  }

  const formTitle = useSelector(getFormTitleLowerCase)

  return (
    <Dialog open={isOpen} onClose={_handleCloseDialog} disableEnforceFocus>
      <DialogTitle>Upload {formTitle} answers</DialogTitle>
      <DialogContent>
        <input
          type='file'
          accept='.csv, text/csv'
          onChange={(e) => setSelectedFile(e.target.files[0])}
          style={{ marginBottom: 16 }}
        />
        {showTimeZoneDropdown ? (
          <Box display='flex' alignItems='center' sx={{ mb: 2 }}>
            <TimeZonePicker selectedTimeZone={selectedTimeZone} onTimeZoneChange={(tz) => setSelectedTimeZone(tz)} />
          </Box>
        ) : null}
        {isLoading && <CircularProgressBar />}
        {isFailed && (
          <div className='upload-answers-csv-errors'>
            {errors.map((error) => (
              <span className='upload-answers-csv-error' key={error}>
                {error}
              </span>
            ))}
          </div>
        )}
        <div>
          Don't have the CSV for uploading?{' '}
          <a href={FormAnswersUploadFileExampleUrl}>Click here to download the template</a>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={_handleSubmit}
          color='primary'
          disabled={!selectedFile || (!selectedTimeZone && showTimeZoneDropdown)}
        >
          {isLoading ? 'Processing' : 'Upload'}
        </Button>
        <Button onClick={_handleCloseDialog} disabled={isLoading}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UploadFormAnswersDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loadingState: PropTypes.shape({
    status: PropTypes.number.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

export default UploadFormAnswersDialog
