import { memo, useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { isUndefined } from 'lodash'

import { Close } from '@material-ui/icons'
import { IconButton, Dialog, Divider, withStyles } from '@material-ui/core'
import { Paper } from '@mui/material'

import Resizable from '../Resizable'

import styles from './styles'
import { DIALOG_DEFAULT_SIZE, MIN_DIALOG_SIZE } from '../../constants'

const PaperComponent = ({ paperRef, ...other }) => {
  return <Paper ref={paperRef} {...other} />
}

PaperComponent.propTypes = {
  paperRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}

const ResizableDialog = ({
  classes,
  header,
  content,
  open,
  onClose,
  minDialogSize,
  defaultDialogSize,
  defaultPosition,
  allowBackgroundInteraction,
}) => {
  const dialogPaperRef = useRef()

  const [{ width, height }, setSize] = useState(defaultDialogSize)
  const [{ x, y }, setPosition] = useState({ x: defaultPosition.x, y: defaultPosition.y })

  const onResize = useCallback(
    (dimensions) => {
      const { width: newWidth, height: newHeight } = dimensions

      const widthLeft = window.innerWidth - (x || (window.innerWidth - newWidth) / 2)
      const heightLeft = window.innerHeight - (y || (window.innerHeight - newHeight) / 2)

      setSize({
        width: Math.min(Math.max(minDialogSize.width, newWidth), widthLeft),
        height: Math.min(Math.max(minDialogSize.height, newHeight), heightLeft),
      })
    },
    [x, y, minDialogSize]
  )

  const onDrag = useCallback(
    (coordinates) => {
      const { x: newX, y: newY } = coordinates

      const widthLeft = window.innerWidth - width

      // use actual (client) height of paper because maxHeight rule affects height rule
      const heightLeft = window.innerHeight - dialogPaperRef.current.clientHeight

      setPosition({
        x: Math.min(Math.max(0, newX), widthLeft),
        y: Math.min(Math.max(0, newY), heightLeft),
      })
    },
    [width]
  )

  const backgroundInteractionProps = {
    disableEnforceFocus: allowBackgroundInteraction,
    style: { position: allowBackgroundInteraction ? 'initial' : 'fixed' },
  }

  return (
    <Dialog
      {...backgroundInteractionProps}
      open={open}
      onClose={onClose}
      BackdropProps={{
        className: classes.backdrop,
      }}
      PaperProps={{
        component: PaperComponent,
        paperRef: dialogPaperRef,
        className: classes.paper,
        style: {
          top: isUndefined(y) ? '50%' : y,
          left: isUndefined(x) ? '50%' : x,
          transform: `translate(${isUndefined(x) ? '-50%' : 0}, ${isUndefined(y) ? '-50%' : 0})`,
          width,
          height,
          zIndex: 1200,
        },
      }}
      fullWidth
    >
      <Resizable width={width} height={height} onResize={onResize} onDrag={onDrag}>
        <div className={classes.header}>
          {header}
          <IconButton className={classes.button} onClick={onClose} title='Close'>
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.content}>{content}</div>
      </Resizable>
    </Dialog>
  )
}

ResizableDialog.defaultProps = {
  minDialogSize: MIN_DIALOG_SIZE,
  defaultDialogSize: DIALOG_DEFAULT_SIZE,
  defaultPosition: { x: undefined, y: undefined },
}

ResizableDialog.propTypes = {
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
    backdrop: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  minDialogSize: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  defaultDialogSize: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  defaultPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  open: PropTypes.bool.isRequired,
  content: PropTypes.element.isRequired,
  header: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  allowBackgroundInteraction: PropTypes.bool,
}

export default memo(withStyles(styles)(ResizableDialog))
