import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import * as rawActions from '../actions'
import { ConfirmationDialog } from '../../shared/dialogs'

class DeleteSessionFolderDialogContainer extends Component {
  onDeleteItem = () => {
    const {
      actions: { deleteSessionFolderRequest, clearDeleteSessionFolderId },
      deleteSessionFolderId,
    } = this.props

    deleteSessionFolderRequest({ folderId: deleteSessionFolderId })
    clearDeleteSessionFolderId(null)
  }

  onCloseDialog = () => {
    const {
      actions: { clearDeleteSessionFolderId },
    } = this.props

    clearDeleteSessionFolderId(null)
  }

  render() {
    const { deleteSessionFolderId } = this.props

    const props = {
      onCloseDialog: this.onCloseDialog,
      onSubmit: this.onDeleteItem,
      isOpen: !_.isNull(deleteSessionFolderId),
      title: 'The folder will be deleted.',
      submitButtonText: 'Delete',
    }

    return <ConfirmationDialog {...props} />
  }
}

DeleteSessionFolderDialogContainer.propTypes = {
  deleteSessionFolderId: PropTypes.number,
  actions: PropTypes.shape({
    deleteSessionFolderRequest: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = ({ gallery }) => {
  return {
    deleteSessionFolderId: gallery.deleteSessionFolderId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSessionFolderDialogContainer)
