import { put, call, takeLatest, select, take, all, delay, takeEvery } from 'redux-saga/effects'

import routes from '@tabeeb/routes'
import { push } from 'connected-react-router'

import { getSessionPermissionsSuccess } from '@tabeeb/modules/permissions/actions'
import { getIsSessionPermissionsLoaded, hasSessionPermission } from '@tabeeb/modules/permissions/selectors'

import { SessionPermission } from '@tabeeb/enums'
import { getIsTrainingMaterialsEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { getUnreadContentTrainingMaterialsCount } from '@tabeeb/modules/articles/actions'
import * as callNotificationActions from '@tabeeb/modules/callNotification/actions'
import * as callNotificationSelectors from '@tabeeb/modules/callNotification/selectors'
import loadGallery from '@tabeeb/modules/gallery/sagas/loadGallery'
import { getCurrentFolder } from '@tabeeb/modules/sessionsPage/selectors'
import { isMobileOnly } from 'react-device-detect'
import selector from '../../utils/selector'
import * as usersActions from '../../../../users/actions'
import { formsActions } from '../../../forms'
import { convertServiceUserToPluginUser } from '../../../../services/dataConverter'

import * as notificationActions from '../../../notification/actions'
import * as navbarActions from '../../../navbar/actions'

import * as aiActions from '../../../artificialIntelligence/actions'
import getErrorMessageFromResponse from '../../utils/getErrorMessageFromResponse'

import { signalrActions, signalrConstants, signalrEvents } from '../../../signalr'
import * as accountSelectors from '../../../account/selectors'
import * as contentActions from '../actions'
import * as contentStateSelectors from '../selectors'
import * as whiteboardActions from '../../../whiteboard/actions'
import * as whiteboardService from '../../../whiteboard/services'
import { getBrowserType } from '../../browser'

function* joinPresentation(contentId, contentData) {
  yield whiteboardService.enterRoom(contentId, contentData.PresenterId)
  yield put(contentActions.setPresenter(contentData.PresenterId))
}

function* getContentSuccess({ response, payload }) {
  const contentState = yield select(selector.getContent)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const { showVideobridgeRegionDropdown, videoBridgeRegions } = yield select((state) => state.appConfigState)
  const contentData = response.data
  const { contentId, pageId } = payload

  const addedUsers = contentData.Users.map((user) => convertServiceUserToPluginUser(user, contentState, currentUserId))
  yield put(usersActions.setUsers(addedUsers))

  const isSessionPermissionsLoaded = yield select(getIsSessionPermissionsLoaded)
  if (!isSessionPermissionsLoaded) {
    yield take(getSessionPermissionsSuccess)
  }

  const isTrainingMaterialsFeatureEnabled = yield select(getIsTrainingMaterialsEnabled)
  if (isTrainingMaterialsFeatureEnabled) {
    yield put(getUnreadContentTrainingMaterialsCount.request({ contentId }))
  }

  const hasJoinCallPermission = yield select((state) => hasSessionPermission(state, SessionPermission.JoinCall))

  if (showVideobridgeRegionDropdown && hasJoinCallPermission) {
    let videoBridgeRegion = ''
    if (contentData.VideoBridgeRegion) {
      videoBridgeRegion = videoBridgeRegions.find((reg) => reg.url === contentData.VideoBridgeRegion)
      if (videoBridgeRegion) {
        yield put(navbarActions.setSelectedVideoBridge(videoBridgeRegion))
      } else {
        videoBridgeRegion = videoBridgeRegions[0]
        yield put(
          notificationActions.onAddErrorNotification({
            message: `Videobridge region with URL ${contentData.VideoBridgeRegion} is not found. Setting default region.`,
          })
        )
        yield put(navbarActions.setSelectedVideoBridge(videoBridgeRegion))
        yield call(setVideobridge, { payload: { contentId, videobridgeUrl: videoBridgeRegion.url } })
      }
    } else {
      videoBridgeRegion = videoBridgeRegions[0]
      yield put(navbarActions.setSelectedVideoBridge(videoBridgeRegion))
      yield call(setVideobridge, { payload: { contentId, videobridgeUrl: videoBridgeRegion.url } })
    }
  }

  yield put(signalrActions.invokeHubAction({ method: 'JoinContent', args: [contentId, currentUserId] }))

  yield call(onContentLoaded, { payload: { selectedPageId: pageId } })

  if (contentData.PresenterId === currentUserId) {
    return
  }

  if (contentData.PresenterId && hasJoinCallPermission) {
    const deviceType = getBrowserType()
    const isCallAccepted = yield select((state) => callNotificationSelectors.isCallAcceptedSelector(state))

    if (isCallAccepted) {
      yield joinPresentation(contentId, contentData, currentUserId, deviceType)
      yield put(callNotificationActions.resetAcceptCallNotification())
      return
    }

    if (isMobileOnly) {
      yield put(whiteboardActions.openMobileJoinCallDialog())

      const joinCallResult = yield take([whiteboardActions.onMobileJoinCallDialogResult])
      const joinCallAccepted = joinCallResult.payload
      if (!joinCallAccepted) {
        yield whiteboardService.leaveContent({ force: true })
        return
      }
    }

    yield joinPresentation(contentId, contentData, currentUserId, deviceType)
  }
}

function* setVideobridge({ payload }) {
  const hasPermission = yield select((state) => hasSessionPermission(state, SessionPermission.SetVideobridge))

  if (!hasPermission) return

  const { contentId, videobridgeUrl } = payload

  const data = {
    ContentId: contentId,
    VideobridgeUrl: videobridgeUrl,
  }

  yield put(navbarActions.setVideoBridgeRequest(data))
}

function* onContentLoaded({ payload }) {
  const contentId = yield select(contentStateSelectors.getContentId)

  yield loadGallery({ selectedPageId: payload.selectedPageId })

  yield put(aiActions.getAiObjects.request())
  yield put(formsActions.getContentFormsRequest({ contentId }))
}

function* getContentFailed({ payload, message, status, response }) {
  const errorMessage = getErrorMessageFromResponse(response.data)
  yield put(notificationActions.onAddErrorNotification({ message: errorMessage }))

  yield delay(5000)

  yield put(push(routes.home))
}

function* makePresenter({ payload }) {
  const presenterId = payload
  const contentId = yield select(contentStateSelectors.getContentId)

  yield put(contentActions.setPresenterRequest({ contentId, presenterId }))
  yield take([contentActions.setPresenterSuccess])

  yield put(signalrActions.invokeHubAction({ method: 'SetPresenter', args: [contentId, presenterId] }))
}

function* onContentStateUpdated(action) {
  const [state] = action.payload

  yield put(contentActions.onUpdateContentState(state))
}

function* onNotifyContentDeleted(action) {
  const [deletedContentId] = action.payload

  const currentContentId = yield select(contentStateSelectors.getContentId)

  if (deletedContentId == currentContentId) {
    yield put(notificationActions.onAddErrorNotification({ message: 'This room has been deleted.' }))
    yield whiteboardService.leaveContent({ force: true })
  }
}

function* goToContent(action) {
  const { id, target = '_self' } = action.payload
  const currentFolder = yield select(getCurrentFolder)

  const link = `${routes.session}?sessionId=${id}${currentFolder?.Id ? `&folderId=${currentFolder.Id}` : ''}`
  if (target === '_blank') {
    window.open(link, '_blank')

    return
  }

  yield put(push(link))
}

function* contentSaga() {
  yield all([
    takeLatest(contentActions.getContentSuccess, getContentSuccess),
    takeLatest(contentActions.getContentFailed, getContentFailed),
    takeLatest(contentActions.makePresenter, makePresenter),
    takeLatest(contentActions.goToContent, goToContent),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onContentStateUpdated, onContentStateUpdated),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onNotifyContentDeleted, onNotifyContentDeleted),
  ])
}

export default contentSaga
