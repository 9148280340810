import { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'

import Button from '../Button'
import { groupingOperators } from '../../constants'

const Grouping = ({ onGroupingApply, operator }) => {
  const [groupingOperator, setGroupingOperator] = useState(operator)

  return (
    <div style={{ display: 'flex', gap: 8, padding: 8, alignItems: 'center' }}>
      <FormControl variant='outlined' size='small'>
        <InputLabel id='function-label'>Operator</InputLabel>
        <Select
          name='filterOperation'
          value={groupingOperator}
          onChange={(e) => setGroupingOperator(e.target.value)}
          style={{ width: 160 }}
          labelId='function-label'
          label='Function'
          variant='outlined'
        >
          <MenuItem value={groupingOperators.unset}>Unset</MenuItem>
          <MenuItem value={groupingOperators.max}>Max</MenuItem>
          <MenuItem value={groupingOperators.count}>Count</MenuItem>
        </Select>
      </FormControl>

      <Button label='Apply' onClick={() => onGroupingApply(groupingOperator)} />
    </div>
  )
}

Grouping.defaultProps = {
  operation: groupingOperators.unset,
}

Grouping.propTypes = {
  onGroupingApply: PropTypes.func.isRequired,
  operation: PropTypes.number,
}

export default Grouping
