// General Features
export const isCalendarEnabled = 'isCalendarEnabled'
export const showMenuAI = 'showMenuAI'
export const showMenuGeosearch = 'showMenuGeosearch'
export const showPageInfoOverlay = 'showPageInfoOverlay'
export const pageInfoOverlayPosition = 'pageInfoOverlayPosition'
export const showSessionNameInOverlay = 'showSessionNameInOverlay'
export const IsDetectLicensePlate = 'IsDetectLicensePlate'
export const useSessionNameConvention = 'useSessionNameConvention'
export const openNamingFormForCopiedSessions = 'openNamingFormForCopiedSessions'
export const isNamingConventionForReviewEnabled = 'isNamingConventionForReviewEnabled'
export const showCertificates = 'showCertificates'
export const showQualifications = 'showQualifications'
export const isQualificationsForInvitesEnabled = 'isQualificationsForInvitesEnabled'
export const isSendApproveOrRejectNotificationEnabled = 'isSendApproveOrRejectNotificationEnabled'

// Premium features
export const contentMetadataButtonLabel = 'contentMetadataButtonLabel'
export const contentMetadataPosition = 'contentMetadataPosition'
export const isAdvancedInviteSettingsEnabled = 'isAdvancedInviteSettingsEnabled'
export const isAutodeskViewerEnabled = 'isAutodeskViewerEnabled'
export const isBulkCopyEnabled = 'isBulkCopyEnabled'
export const isChatEnabled = 'isChatEnabled'
export const isChatSpeechToTextEnabled = 'isChatSpeechToTextEnabled'
export const isChatTranslationEnabled = 'isChatTranslationEnabled'
export const isCloudRecordingEnabled = 'isCloudRecordingEnabled'
export const isContentMetadataEnabled = 'isContentMetadataEnabled'
export const isSpreadsheetsImportEnabled = 'isSpreadsheetsImportEnabled'
export const isDetectText = 'isDetectText'
export const isFormsAndUsersTabCollapsed = 'isFormsAndUsersTabCollapsed'
export const isGalleryExpansionEnabled = 'isGalleryExpansionEnabled'
export const isLocalRecordingEnabled = 'isLocalRecordingEnabled'
export const isModelTrainingEnabled = 'isModelTrainingEnabled'
export const iterationDeploymentLifetimeInHours = 'iterationDeploymentLifetimeInHours'
export const isPageFolderAuthorizationEnabled = 'isPageFolderAuthorizationEnabled'
export const isPointCloudModelsEnabled = 'isPointCloudModelsEnabled'
export const isRecordingEnabled = 'isRecordingEnabled'
export const isTenantContentsCustomDashboardsEnabled = 'isTenantContentsCustomDashboardsEnabled'
export const isCustomIframeEnabled = 'isCustomIframeEnabled'
export const customIframeNomenclature = 'customIframeNomenclature'
export const customIframeUrl = 'customIframeUrl'
export const isVideoCallsEnabled = 'isVideoCallsEnabled'
export const show2dLineDrawing = 'show2dLineDrawing'
export const show360View = 'show360View'
export const show3dModel = 'show3dModel'
export const showSplatModel = 'showSplatModel'
export const showAdvancedFilterButton = 'showAdvancedFilterButton'
export const showBentleyModel = 'showBentleyModel'
export const showGridViewButton = 'showGridViewButton'
export const showMenuAnalytics = 'showMenuAnalytics'
export const showMenuAnalyticsReports = 'showMenuAnalyticsReports'
export const showStructureModel = 'showStructureModel'
export const showSwitchToAttachedFormDialog = 'showSwitchToAttachedFormDialog'
export const showTowerSideProfile = 'showTowerSideProfile'
export const showBentleyAnalyticsReport = 'showBentleyAnalyticsReport'
export const isExportCustomFormCsvEnabled = 'isExportCustomFormCsvEnabled'
export const showTimeZoneOnUploadAnswers = 'showTimeZoneOnUploadAnswers'
export const isAdvancedFormSettingsEnabled = 'isAdvancedFormSettingsEnabled'
export const isBentleyBoundingBoxManualInputEnabled = 'isBentleyBoundingBoxManualInputEnabled'
export const isSwitchTenantFeatureEnabled = 'isSwitchTenantFeatureEnabled'
export const isTableRecognitionEnabled = 'isTableRecognitionEnabled'
export const isTenantAnnouncementsEnabled = 'isTenantAnnouncementsEnabled'
export const isTrainingMaterialsEnabled = 'isTrainingMaterialsEnabled'
export const trainingMaterialsTitle = 'trainingMaterialsTitle'
export const isClassificationAnnotationsCreationEnabled = 'isClassificationAnnotationsCreationEnabled'
export const contextCaptureGigapixelLimit = 'contextCaptureGigapixelLimit'
export const isUtilityInfrastructureAnalysisEnabled = 'isUtilityInfrastructureAnalysisEnabled'
export const utilityInfrastructureAnalysisWorkflowId = 'utilityInfrastructureAnalysisWorkflowId'
export const isBulkImportEnabled = 'isBulkImportEnabled'

// Form builder
export const isConditionsEnabled = 'isConditionsEnabled'
export const isControlInfoEnabled = 'isControlInfoEnabled'
export const isMobileFormViewSwitchEnabled = 'isMobileFormViewSwitchEnabled'
export const isFormAnswerConfirmationEnabled = 'isFormAnswerConfirmationEnabled'
export const isFormActionEnabled = 'isFormActionEnabled'
export const isCreatePublishFormRestrictionEnabled = 'isCreatePublishFormRestrictionEnabled'
export const isFormSignatureEnabled = 'isFormSignatureEnabled'

// Mobile app
export const isOfflineAvailable = 'isOfflineAvailable'
export const isProModeAvailable = 'isProModeAvailable'
export const isAlertOwnerAvailable = 'isAlertOwnerAvailable'
export const isLidarScanningEnabled = 'isLidarScanningEnabled'
export const isCallRingNotificationsEnabled = 'isCallRingNotificationsEnabled'

// AI settings
export const showPredictionService = 'showPredictionService'
export const isPageTagsDetectionEnabled = 'isPageTagsDetectionEnabled'
export const isAnswersAiApproveEnabled = 'isAnswersAiApproveEnabled'
export const isAnswersAiApproveByVQAPolicyEnabled = 'isAnswersAiApproveByVQAPolicyEnabled'
export const isAiAutoAnswerEnabled = 'isAiAutoAnswerEnabled'
export const is3DModelCreationLimitEnabled = 'is3DModelCreationLimitEnabled'
export const showProcessingByVQAModelButton = 'showProcessingByVQAModelButton'
export const isAIExpressionsEnabled = 'isAIExpressionsEnabled'

export const isAIReportEnabled = 'isAIReportEnabled'
export const aiReportLabel = 'aiReportLabel'
export const aiReportPublishNameTemplate = 'aiReportPublishNameTemplate'

// Share Session
export const isSessionSharingEnabled = 'isSessionSharingEnabled'
export const selectedTenantsForSharing = 'selectedTenantsForSharing'
export const isSessionPublishingEnabled = 'isSessionPublishingEnabled'
export const isContentLinkingEnabled = 'isContentLinkingEnabled'
export const isContentReviewAutoAcceptEnabled = 'isContentReviewAutoAcceptEnabled'
export const isSearchTargetContentByTenantEnabled = 'isSearchTargetContentByTenantEnabled'
export const isContentSharingAutoAcceptEnabled = 'isContentSharingAutoAcceptEnabled'

// Reviewing Forms
export const isSessionReviewingEnabled = 'isSessionReviewingEnabled'
export const selectedTenantsForReviewing = 'selectedTenantsForReviewing'
export const showAllSessionsOriginalTenantCol = 'showAllSessionsOriginalTenantCol'
export const isInContentReviewEnabled = 'isInContentReviewEnabled'
export const isMergeForReviewEnabled = 'isMergeForReviewEnabled'

// Publish Forms
export const isFormPublishingEnabled = 'isFormPublishingEnabled'
export const selectedTenantsForFormPublishing = 'selectedTenantsForFormPublishing'

// Sas
export const sasExpirationInMinutesForContent = 'sasExpirationInMinutesForContent'
export const sasExpirationInMinutesForReport = 'sasExpirationInMinutesForReport'

// Server Information
export const ftpHostConfig = 'ftpHost'
export const ftpUserConfig = 'ftpUser'
export const ftpPasswordConfig = 'ftpPassword'

export const useInternalFTP = 'useInternalFTP'
export const useCustomerFTP = 'useCustomerFTP'

// Data Settings
export const isUploadCustomVideo = 'isUploadCustomVideo'
export const isUploadCsvReport = 'isUploadCsvReport'
export const IsUploadZipImages = 'IsUploadZipImages'
export const isActionsOverUsersReportsEnabled = 'isActionsOverUsersReportsEnabled'
export const isActivityDataReportsEnabled = 'isActivityDataReportsEnabled'
export const isLastActivityDataReportsEnabled = 'isLastActivityDataReportsEnabled'
export const isAllSessionsDataReportsEnabled = 'isAllSessionsDataReportsEnabled'
export const isCheckInCheckOutReportsEnabled = 'isCheckInCheckOutReportsEnabled'
export const isFormAnswersReportsEnabled = 'isFormAnswersReportsEnabled'

// General Tenant Appearance
export const mainBGColor = 'mainBGColor'
export const notificationColor = 'notificationColor'
export const mainBanner = 'mainBanner'
export const mainTextColor = 'mainTextColor'
export const mainSelectedTextColor = 'mainSelectedTextColor'
export const logoUrl = 'logoUrl'

// Login Page
export const homeBanner = 'homeBanner'
export const loginColor = 'loginColor'
export const homeTextColor = 'homeTextColor'
export const loginBackgroundColor = 'loginBackgroundColor'
export const loginBackgroundImage = 'loginBackgroundImage'
export const bannerUrl = 'bannerUrl'
export const mission = 'mission'
export const bullets = 'bullets'
export const bannerLink = 'bannerLink'
export const showGoogleButton = 'showGoogleButton'
export const showNokiaButton = 'showNokiaButton'
export const showEricssonButton = 'showEricssonButton'
export const showImmucorButton = 'showImmucorButton'
export const showTmobileButton = 'showTmobileButton'

// Session Thumbnails
export const sessionTileBackgroundColor = 'sessionTileBackgroundColor'
export const programTileColor = 'programTileColor'
export const sessionTitleColor = 'sessionTitleColor'
export const participantNumberColor = 'participantNumberColor'
export const pageNumberColor = 'pageNumberColor'
export const createSessionBackgroundColor = 'createSessionBackgroundColor'
export const addSessionUrl = 'addSessionUrl'
export const sessionsNavbarTitle = 'sessionsNavbarTitle'

// Session Buttons
export const addButtionColor = 'addButtionColor'
export const exportButtonColor = 'exportButtonColor'
export const createFolderButtonColor = 'createFolderButtonColor'

// Export Deliverables
export const showExportFormToPdfButton = 'showExportFormToPdfButton'
export const showSelectedOnlyOptionsExportFormToPdf = 'showSelectedOnlyOptionsExportFormToPdf'
export const introImageTextColor = 'introImageTextColor'
export const introImageUrl = 'introImageUrl'

// Roles
export const guestRoleDisplayName = 'guestRoleDisplayName'
export const sessionGuestRoleDisplayName = 'sessionGuestRoleDisplayName'
export const isSessionGuestRoleEnabled = 'isSessionGuestRoleEnabled'
export const isViewingAdminTenantRoleEnabled = 'isViewingAdminTenantRoleEnabled'
export const isBillingSettingsRoleEnabled = 'isBillingSettingsRoleEnabled'

// Presentations
export const showPdfRecieversPopup = 'showPdfRecieversPopup'
export const showImageRecieversPopup = 'showImageRecieversPopup'
export const showFormUsers = 'showFormUsers'
export const isFormRecordEnabled = 'isFormRecordEnabled'
export const isDeviceSwitchingEnabled = 'isDeviceSwitchingEnabled'
export const showReminders = 'showReminders'
export const soundNotificationOnCallJoin = 'soundNotificationOnCallJoin'
export const formsTitle = 'formsTitle'

// Video Bridge settings
export const showVideobridgeRegionDropdown = 'showVideobridgeRegionDropdown'
export const videoBridgeRegions = 'videoBridgeRegions'

// Mobile Settings
export const mobileBackgroundUrl = 'mobileBackgroundUrl'
export const mobilePrimaryColor = 'mobilePrimaryColor'
export const mobileLogoUrl = 'mobileLogoUrl'
export const isNewMainPageViewEnabled = 'isNewMainPageViewEnabled'

// Location tracking
export const isMobileTrackingEnabled = 'isMobileTrackingEnabled'
export const isTrackingMapEnabled = 'isTrackingMapEnabled'
export const isHospitalsLocationsEnabled = 'isHospitalsLocationsEnabled'

// Health data
export const showHealthData = 'showHealthData'
export const showTabletsAssignation = 'showTabletsAssignation'

// Account management
export const isAccountDeletionEnabled = 'isAccountDeletionEnabled'
export const isMfaEnabled = 'isMfaEnabled'

// Contracts management
export const isIncomingContractsEnabled = 'isIncomingContractsEnabled'
export const isOutgoingContractsEnabled = 'isOutgoingContractsEnabled'
export const selectedTenantsForContracts = 'selectedTenantsForContracts'
