import { useMemo } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import PropTypes from 'prop-types'
import { LinearProgress } from '@mui/material'

const Preview = ({ rows, previewReadProps, isInEditMode, columns, showFooterRowCount: showFooter, isLoading }) => {
  const props = useMemo(
    () => ({
      sx: { borderRadius: 0, color: 'text.primary', fontSize: 14, fontFamily: 'inherit' },
      rows,
      columns,
      disableMultipleRowSelection: true,
      disableColumnMenu: true,
      getRowId: (r) => r.mui_uid,
      disableColumnReorder: isInEditMode,
      hideFooter: !showFooter,
      isCellEditable: () => false,
      slots: {
        loadingOverlay: LinearProgress,
      },
      loading: isLoading,

      ...(!isInEditMode ? previewReadProps : {}),
    }),
    [columns, isInEditMode, isLoading, previewReadProps, rows, showFooter]
  )

  return <DataGridPro {...props} />
}

Preview.propTypes = {
  previewReadProps: PropTypes.instanceOf(Object),
  rows: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isInEditMode: PropTypes.bool.isRequired,
  showFooterRowCount: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
}

export default Preview
