import { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Mic, MicOff } from '@material-ui/icons'

import { accountSelectors } from '@tabeeb/modules/account'
import { contentStateSelectors } from '@tabeeb/shared/content'
import { presentationSelectors } from '@tabeeb/modules/presentation'
import { muteAudio, muteRemoteAudio } from '@tabeeb/modules/presentationToolbar/actions/muteAudio'
import { AUDIO_MUTED } from '@tabeeb/modules/presentation/constants'

import UserActionButton from '../UserActionButton'

const MuteUserAudioButton = ({ user }) => {
  const dispatch = useDispatch()

  const currentUserId = useSelector(accountSelectors.getCurrentUserId)
  const isPresenter = useSelector(contentStateSelectors.getIsCurrentUserPresenter)
  const isMuted = useSelector((state) => !presentationSelectors.getIsUserAudioPlaying(state, { Id: user.id }))
  const isMuteInProgress = useSelector((state) => state.presentationToolbar.muteAudioInProgress)
  const isCallPreviewOpen = useSelector(presentationSelectors.getIsCallPreviewOpen)

  const handleClick = () => {
    if (isMuteInProgress === true || (user.isOnline === false && !isCallPreviewOpen)) {
      return
    }

    if (user.id === currentUserId) {
      dispatch(muteAudio())
      if (isCallPreviewOpen) {
        localStorage.setItem(AUDIO_MUTED, JSON.stringify(!isMuted))
      }
    } else if (isPresenter) {
      dispatch(muteRemoteAudio(user.id))
    }
  }

  const icon = isMuted ? MicOff : Mic

  return (
    <UserActionButton
      icon={icon}
      title={isMuted ? 'Unmute audio' : 'Mute audio'}
      disabled={!isPresenter && currentUserId !== user.id}
      isMuted={isMuted}
      onClick={handleClick}
    />
  )
}

MuteUserAudioButton.propTypes = {
  user: PropTypes.shape({
    isOnline: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }),
}

export default memo(MuteUserAudioButton)
