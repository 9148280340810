import { put, takeLatest, all, select, delay } from 'redux-saga/effects'

import { forceCheck } from 'react-lazyload'

import * as rawContentActions from '@tabeeb/shared/content/actions'

import { appConfigStateActions, appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { tenantConfigActions } from '@tabeeb/modules/tenantConfigPage'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'

import { close3dModelDialog } from '@tabeeb/modules/3dmodel/actions'
import { closeBentleyModelTimeline } from '@tabeeb/modules/bentleyModel/actions'
import * as customVideoActions from '@tabeeb/modules/customVideo/actions'
import { closeReviewSessionPagesDialog } from '@tabeeb/modules/reviewSessionPagesList/actions'
import { closeShareSessionPagesDialog } from '@tabeeb/modules/shareSessionPagesList/actions'
import { closePagesDialog } from '@tabeeb/modules/spatialModel/actions'
import { closeStructureModelDialog } from '@tabeeb/modules/structureModel/actions'
import { closeTowerSideProfileDialog } from '@tabeeb/modules/towerSideProfile/actions'
import { closeSelectedPagesForMerging } from '@tabeeb/modules/contentSharings/actions'
import { close2dLineDrawingDialog } from '@tabeeb/modules/2dLineDrawing/actions'
import { closeClassificationAnnotationsTimeline } from '@tabeeb/modules/classificationAnnotationsPagesTimeline/actions'
import { resetNavbarState, setNavbarDefaultState, switchPanel } from '../actions'

function* resetState() {
  yield put(resetNavbarState())
}

function* onSwitchPanel(action) {
  window.dispatchEvent(new CustomEvent('resize'))

  const { panel } = action.payload

  if (panel === TabPanel.Gallery) {
    yield delay(50)
    forceCheck()
  }
}

function* onSetAppConfigState(action) {
  const updatedSubdomain = action.payload.subdomain
  const currentSubdomain = yield select(appConfigStateSelectors.getSubdomain)

  const config = action.response.data
  if (currentSubdomain === updatedSubdomain) {
    const defaultState = {
      left: config.isFormsAndUsersTabCollapsed ? '' : TabPanel.Forms,
      right: TabPanel.Gallery,
      bottom: config.isFormsAndUsersTabCollapsed ? '' : TabPanel.Users,
    }

    yield put(setNavbarDefaultState(defaultState))
  }
}

function* setDefaultBottomPanel() {
  yield put(switchPanel(TabPanel.Users, TabPanelLocation.Bottom))
}

export function* setDefaultLeftPanel() {
  yield put(switchPanel(TabPanel.Forms, TabPanelLocation.Left))
}

function* navbarSaga() {
  yield all([
    takeLatest([appConfigStateActions.getConfigSuccess, tenantConfigActions.updateConfigSuccess], onSetAppConfigState),
    takeLatest([rawContentActions.onSetContentId, rawContentActions.resetContentState], resetState),
    takeLatest(switchPanel, onSwitchPanel),
    takeLatest(
      [
        close3dModelDialog,
        closeBentleyModelTimeline,
        customVideoActions.closeTimeline,
        closeReviewSessionPagesDialog,
        closeShareSessionPagesDialog,
        closePagesDialog,
        closeStructureModelDialog,
        closeTowerSideProfileDialog,
        closeSelectedPagesForMerging,
        close2dLineDrawingDialog,
        closeClassificationAnnotationsTimeline,
      ],
      setDefaultBottomPanel
    ),
  ])
}

export default navbarSaga
