import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { ConfirmationDialog } from '@tabeeb/shared/dialogs'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'

import { initiateSessionCreation } from '../../../actions'

const CreateSessionWithAdminPermissionRequestDialog = ({ open, onClose }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(() => {
    dispatch(initiateSessionCreation({ needPermission: true }))

    onClose()
  }, [dispatch, onClose])

  return (
    <ConfirmationDialog
      isOpen={open}
      title={sessionTitleFormatter.format('Create session')}
      dialogMessage={sessionTitleFormatter.format(
        'You need permission from the admin to activate a session. Please click Continue to proceed, or Cancel'
      )}
      onCloseDialog={onClose}
      submitButtonText='Continue'
      onSubmit={onSubmit}
    />
  )
}

CreateSessionWithAdminPermissionRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(CreateSessionWithAdminPermissionRequestDialog)
