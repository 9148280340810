import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Chip,
} from '@mui/material'
import { Close, Add, Save } from '@mui/icons-material'
import { getPowerBIProviderModalState } from '@tabeeb/modules/powerBIReportProviders/selectors'
import { FetchStatus } from '@tabeeb/enums'
import {
  closePowerBIProviderModal,
  createPowerBIReportProvider,
  updatePowerBIReportProviderConfiguration,
} from '@tabeeb/modules/powerBIReportProviders/actions'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PreTag from '@tabeeb/modules/shared/uikit/components/PreTag'
import usePowerBIConnectionCheck from '@tabeeb/modules/powerBIReportProviders/hooks/usePowerBIConnectionCheck'
import { GUIDRegexp } from '@tabeeb/modules/shared/constants'
import { requiredField } from '@tabeeb/modules/shared/utils/validationErrorMessages'
import { StyledTextField } from './styles'
import CheckConnectionButton from '../../CheckConnectionButton'

const validationSchema = Yup.object({
  name: Yup.string().required(requiredField).notEmptyString(),
  azureTenantId: Yup.string().required(requiredField).matches(GUIDRegexp, 'Must be a valid GUID'),
  azureClientId: Yup.string().required(requiredField).matches(GUIDRegexp, 'Must be a valid GUID'),
  azureClientSecret: Yup.string().required(requiredField).notEmptyString(),
})

const PowerBIProviderModal = () => {
  const dispatch = useDispatch()

  const { open, fetchStatus, provider, isEditMode } = useSelector(getPowerBIProviderModalState)
  const {
    fetchStatus: checkConnectionFetchStatus,
    onResetState: onResetCheckConnectionState,
    errorObject: checkConnectionErrorObject,
    onInitCheck: onInitConnectionCheck,
  } = usePowerBIConnectionCheck()

  const onSubmit = useCallback(
    (values) => {
      const requestPayload = {
        name: values.name,
        azureTenantId: values.azureTenantId,
        azureClientId: values.azureClientId,
        azureClientSecret: values.azureClientSecret,
      }
      if (isEditMode) {
        dispatch(updatePowerBIReportProviderConfiguration.request({ id: provider.Id, data: requestPayload }))
      } else {
        dispatch(createPowerBIReportProvider.request(requestPayload))
      }
    },
    [dispatch, isEditMode, provider.Id]
  )

  const form = useFormik({
    initialValues: {
      name: provider.Name || '',
      azureTenantId: provider.AzureTenantId || '',
      azureClientId: provider.AzureClientId || '',
      azureClientSecret: provider.AzureClientSecret || '',
    },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  })

  const submitButtonStartIcon = useMemo(() => {
    if (fetchStatus === FetchStatus.Loading) {
      return <CircularProgress size={18} />
    }
    return isEditMode ? <Save color='primary' /> : <Add color='primary' />
  }, [fetchStatus, isEditMode])

  const isInputDisabled = useMemo(() => {
    return checkConnectionFetchStatus === FetchStatus.Loading || fetchStatus === FetchStatus.Loading
  }, [checkConnectionFetchStatus, fetchStatus])

  const resetState = useCallback(() => {
    onResetCheckConnectionState()
    form.resetForm()
  }, [form, onResetCheckConnectionState])

  useEffect(() => {
    if (fetchStatus === FetchStatus.Loaded) {
      dispatch(closePowerBIProviderModal())
      resetState()
    }
  }, [dispatch, fetchStatus, form, resetState])

  const onClose = useCallback(
    (_, reason) => {
      if (reason === 'backdropClick') {
        return
      }
      dispatch(closePowerBIProviderModal())
      resetState()
    },
    [dispatch, resetState]
  )

  const onCheckConnection = useCallback(() => {
    const { values } = form
    const requestParams = {
      azureTenantId: values.azureTenantId,
      azureClientId: values.azureClientId,
      azureClientSecret: values.azureClientSecret,
    }
    onInitConnectionCheck(requestParams)
  }, [form, onInitConnectionCheck])

  const onInputChange = useCallback(
    (event) => {
      onResetCheckConnectionState()
      form.handleChange(event)
    },
    [form, onResetCheckConnectionState]
  )

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
      <DialogTitle>{isEditMode ? 'Edit' : 'Add'} PowerBI provider</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 12,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Box component='form' autoComplete='off' sx={{ display: 'flex', flexDirection: 'column' }}>
          {!isEditMode && (
            <StyledTextField
              required
              variant='standard'
              name='name'
              value={form.values.name}
              helperText={form.touched.name && form.errors.name}
              disabled={isInputDisabled}
              onChange={onInputChange}
              onBlur={form.handleBlur}
              label='Provider name'
              fullWidth
              inputProps={{ maxLength: 100 }}
            />
          )}
          <StyledTextField
            required
            variant='standard'
            name='azureTenantId'
            value={form.values.azureTenantId}
            helperText={form.touched.azureTenantId && form.errors.azureTenantId}
            disabled={isInputDisabled}
            onChange={onInputChange}
            onBlur={form.handleBlur}
            label='Azure Tenant ID'
            fullWidth
          />
          <StyledTextField
            required
            variant='standard'
            name='azureClientId'
            value={form.values.azureClientId}
            helperText={form.touched.azureClientId && form.errors.azureClientId}
            disabled={isInputDisabled}
            onChange={onInputChange}
            onBlur={form.handleBlur}
            label='Azure Client ID'
            fullWidth
          />
          <StyledTextField
            required
            variant='standard'
            name='azureClientSecret'
            value={form.values.azureClientSecret}
            helperText={form.touched.azureClientSecret && form.errors.azureClientSecret}
            disabled={isInputDisabled}
            onChange={onInputChange}
            onBlur={form.handleBlur}
            label='Azure Client secret'
            fullWidth
          />
          {checkConnectionErrorObject !== null && (
            <PreTag>{JSON.stringify(checkConnectionErrorObject, null, 2)}</PreTag>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {checkConnectionFetchStatus === FetchStatus.Loaded && (
          <Chip label='Connection established' color='success' variant='filled' sx={{ mr: 1 }} />
        )}
        {checkConnectionFetchStatus === FetchStatus.Failed && (
          <Chip label='Failed to establish connection' color='error' variant='filled' sx={{ mr: 1 }} />
        )}
        <CheckConnectionButton
          disabled={!form.isValid || !form.dirty}
          fetchStatus={checkConnectionFetchStatus}
          onClick={onCheckConnection}
        />
        <Button
          variant='outlined'
          color='primary'
          startIcon={submitButtonStartIcon}
          disabled={
            checkConnectionFetchStatus !== FetchStatus.Loaded || fetchStatus === FetchStatus.Loading || !form.isValid
          }
          onClick={form.handleSubmit}
        >
          {isEditMode ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PowerBIProviderModal
