import { memo } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { Grow, Typography } from '@material-ui/core'

import { recordingSelectors } from '@tabeeb/modules/recording'

import useStyles from './styles'

const getRecordingDurationString = (duration) => {
  const recordingTime = moment.utc(duration)

  const hours = Math.floor(moment.duration(duration, 'milliseconds').asHours())
  if (hours > 23) {
    return `${hours}:${recordingTime.format('mm:ss')}`
  }

  if (hours > 0) {
    return `${recordingTime.format('HH:mm:ss')}`
  }

  return recordingTime.format('mm:ss')
}

const RecordingTimer = () => {
  const classes = useStyles()

  const time = useSelector(recordingSelectors.getElapsedTime)
  const isRecordingActive = useSelector(recordingSelectors.getIsRecordingActive)

  return (
    <Grow in={isRecordingActive} unmountOnExit>
      <div className={classes.container} title='Recording time'>
        <div className={classes.icon} />
        <Typography variant='body2' color='textSecondary' component='time'>
          {getRecordingDurationString(time)}
        </Typography>
      </div>
    </Grow>
  )
}

export default memo(RecordingTimer)
