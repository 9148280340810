import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { setAvailableColumns } from '../../actions'

import ColumnsPicker from '../ColumnsPicker'
import Preview from '../Preview'
import Toolbar from '../Toolbar'
import { ColumnPickerContainer, EditorContainer, TemplateContainer } from './styles'

const Editor = ({
  templates,
  template,
  columns,
  availableColumns,
  rows,
  previewReadProps,
  onTemplateSet,
  onTemplateSave,
  onTemplateAdd,
  isInEditMode,
  setIsInEditMode,
  onTemplateDelete,
  isLoading,
}) => {
  const dispatch = useDispatch()

  const [isInEditModeInternal, setIsInEditModeInternal] = useState(false)

  const isEdit = isInEditMode || isInEditModeInternal
  const setIsEdit = setIsInEditMode || setIsInEditModeInternal

  useEffect(() => {
    if (isEdit) {
      dispatch(setAvailableColumns(availableColumns))
    }
  }, [dispatch, availableColumns, isEdit])

  return (
    <EditorContainer>
      {isEdit && (
        <ColumnPickerContainer>
          <ColumnsPicker template={template} onTemplateSet={onTemplateSet} />
        </ColumnPickerContainer>
      )}

      <TemplateContainer>
        <Preview
          rows={rows}
          columns={columns}
          previewReadProps={previewReadProps}
          isInEditMode={isEdit}
          isLoading={isLoading}
        />
        <Toolbar
          template={template}
          templates={templates}
          onTemplateSave={onTemplateSave}
          onTemplateAdd={onTemplateAdd}
          onTemplateDelete={onTemplateDelete}
          isInEditMode={isEdit}
          setIsInEditMode={setIsEdit}
          onTemplateSet={onTemplateSet}
        />
      </TemplateContainer>
    </EditorContainer>
  )
}

Editor.propTypes = {
  onTemplateSave: PropTypes.func,
  onTemplateAdd: PropTypes.func,
  onTemplateSet: PropTypes.func,
  onTemplateDelete: PropTypes.func,
  previewReadProps: PropTypes.instanceOf(Object),
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          sorting: PropTypes.shape({
            direction: PropTypes.number,
            priority: PropTypes.number,
          }),
          filter: PropTypes.shape({ operation: PropTypes.number, value: PropTypes.string }),
          grouping: PropTypes.number,
        })
      ),
    })
  ),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  rows: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isInEditMode: PropTypes.bool,
  setIsInEditMode: PropTypes.func,
}

export default Editor
