import { MuiThemeProvider } from '@material-ui/core'

import { modernV4 } from '../themes'

export default (Component) => {
  function WrappedComponent(props) {
    return (
      <MuiThemeProvider theme={modernV4}>
        <Component {...props} />
      </MuiThemeProvider>
    )
  }

  return WrappedComponent
}
