import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { TextField } from '@mui/material'

const SearchField = ({ fullWidth, placeholder, value, onChange }) => {
  const onTextChange = useCallback(
    (event) => {
      onChange(event.target.value)
    },
    [onChange]
  )

  return (
    <TextField
      value={value}
      hiddenLabel
      fullWidth={fullWidth}
      size='small'
      variant='filled'
      placeholder={placeholder}
      onChange={onTextChange}
    />
  )
}

SearchField.defaultProps = {
  fullWidth: true,
  placeholder: 'Search...',
}

SearchField.propTypes = {
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default memo(SearchField)
