import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Popover, Divider, Typography, List, ListItem } from '@material-ui/core'
import { MicRounded, VolumeUp } from '@material-ui/icons'

import { setAudioInputDevice, setAudioOutputDevice } from '@tabeeb/modules/presentation/actions/devices'
import { getAudioInputDevices, getAudioOutputDevices } from '@tabeeb/modules/presentation/services/conferenceService'
import { getAudioInputDevice, getAudioOutputDevice } from '@tabeeb/modules/presentation/selectors'
import './styles.less'

const SoundSettings = ({
  anchorEl,
  isOpen,
  onClose,
  originPosition = {
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    transformOrigin: { vertical: 'center', horizontal: 'right' },
  },
}) => {
  const [audioInputDevices, loadAudioInputDevices] = useState([])
  const [audioOutputDevices, loadAudioOutputDevices] = useState([])

  const dispatch = useDispatch()

  const selectedAudioInputDevice = useSelector((state) => getAudioInputDevice(state))
  const selectedAudioOutputDevice = useSelector((state) => getAudioOutputDevice(state))
  const showInputDevices = audioInputDevices.length && audioInputDevices[0].deviceId
  const showOutputDevices = audioOutputDevices.length && audioOutputDevices[0].deviceId

  const handleAudioInputDeviceClick = (deviceId) => {
    if (deviceId === selectedAudioInputDevice) {
      return
    }

    dispatch(setAudioInputDevice(deviceId))
  }

  const handleAudioOutputDeviceClick = (deviceId) => {
    if (deviceId === selectedAudioOutputDevice) {
      return
    }

    dispatch(setAudioOutputDevice(deviceId))
  }

  const updateDeviceList = () => {
    getAudioInputDevices().then((devices) => {
      loadAudioInputDevices(devices)
    })
    getAudioOutputDevices().then((devices) => {
      loadAudioOutputDevices(devices)
    })
  }

  const onMediaDevicesChange = useCallback(() => {
    updateDeviceList()

    getAudioInputDevices().then((devices) => {
      if (selectedAudioInputDevice && !devices.find((device) => device.deviceId === selectedAudioInputDevice)) {
        dispatch(setAudioInputDevice(devices[0].deviceId))
      }
    })
    getAudioOutputDevices().then((devices) => {
      if (selectedAudioOutputDevice && !devices.find((device) => device.deviceId === selectedAudioOutputDevice)) {
        dispatch(setAudioOutputDevice(devices[0].deviceId))
      }
    })
  }, [selectedAudioInputDevice, selectedAudioOutputDevice])

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', onMediaDevicesChange)

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', onMediaDevicesChange)
    }
  }, [onMediaDevicesChange])

  useEffect(() => {
    if (!isOpen) {
      return
    }

    updateDeviceList()
  }, [isOpen])

  return (
    <Popover
      className='user-options-controls-popover'
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      {...originPosition}
    >
      <div className='section'>
        <div className='section-title'>
          <MicRounded className='title-icon' color='disabled' />
          <Typography className='title-text'>Microphones</Typography>
        </div>
        <List className='section-content'>
          {showInputDevices ? (
            audioInputDevices.map((device) => (
              <ListItem
                key={device.deviceId}
                button
                title={device.label}
                onClick={() => handleAudioInputDeviceClick(device.deviceId)}
                selected={device.deviceId === selectedAudioInputDevice}
              >
                <Typography noWrap>{device.label}</Typography>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Typography noWrap>Devices are not detected</Typography>
            </ListItem>
          )}
        </List>
      </div>
      <Divider />
      <div className='section'>
        <div className='section-title'>
          <VolumeUp className='title-icon' color='disabled' />
          <Typography className='title-text'>Speakers</Typography>
        </div>
        <List className='section-content'>
          {showOutputDevices ? (
            audioOutputDevices.map((device) => (
              <ListItem
                key={device.deviceId}
                button
                title={device.label}
                onClick={() => handleAudioOutputDeviceClick(device.deviceId)}
                selected={device.deviceId === selectedAudioOutputDevice}
              >
                <Typography noWrap>{device.label}</Typography>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Typography noWrap>Devices are not detected</Typography>
            </ListItem>
          )}
        </List>
      </div>
    </Popover>
  )
}

SoundSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
}

export default SoundSettings
