import { useState, useRef, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Provider, ReactReduxContext } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import { Stage } from 'react-konva'
import classNames from 'classnames'

import Annotations from '@tabeeb/modules/annotations'
import CalibrateMeasurementsAnnotationDialog from '@tabeeb/modules/annotations/components/CalibrateMeasurementsAnnotationDialog'
import { VideoPlayer } from '@tabeeb/modules/player'
import SpatialViewContainer from '../../containers/SpatialViewContainer'
import AnnotationPopoverContainer from '../../containers/AnnotationPopoverContainer'

import PdfViewer from '../PdfViewer'
import ImageViewer from '../ImageViewer'
import DrawingBoard from '../DrawingBoard'
import WebPageViewer from '../WebPageViewer'
import AutodeskViewer from '../AutodeskViewer'
import DocumentViewer from '../DocumentViewer'
import LoadingSpinner from '../LoadingSpinner'
import PageInfoOverlay from '../PageInfoOverlay'
import PageDiscussionButton from '../PageDiscussionButton'
import PageAnswersDiscussionButton from '../PageAnswersDiscussionButton'
import PointCloudSourcePagesNavigationButton from '../PointCloudSourcePagesNavigationButton'
import NoPreviewAvailable from '../NoPreviewAvailable'
import RefreshPageButton from '../RefreshPageButton'
import SnippingTool from '../SnippingTool'

import { calculateScale } from '../../services'

import './styles.less'

const PointCloudViewer = lazy(() => import('../PointCloudViewer'))

const ContentViewer = ({
  isLoading,
  isPdfPage,
  isWebPage,
  isVideoPage,
  isImagePage,
  isSpatialViewPage,
  isDocumentPage,
  isAutodeskPage,
  isDrawingBoardPage,
  isFilePage,
  isPointCloudPage,
  isDragAvailable,
  isSelectionEnabled,
  contentSize,
  containerSize,
  playerScale,
  contentRef,
  handleUpdateContainer,
}) => {
  const containerRef = useRef()

  const stageRef = useRef(null)
  const [iframeKey, setIframeKey] = useState(0)

  const scale = calculateScale(contentSize, containerSize)
  const scaledContentSize = {
    width: contentSize.width * scale * playerScale,
    height: contentSize.height * scale * playerScale,
  }

  const stageSize = {
    width: Math.min(scaledContentSize.width, containerSize.width) || 1,
    height: Math.min(scaledContentSize.height, containerSize.height) || 1,
  }

  const contentBoundFunc = (position) => ({
    x: Math.max(Math.min(position.x, 0), stageSize.width - scaledContentSize.width),
    y: Math.max(Math.min(position.y, 0), stageSize.height - scaledContentSize.height),
  })

  if (stageRef && stageRef.current) {
    stageRef.current.setPosition(contentBoundFunc(stageRef.current.position()))
  }

  return (
    <>
      <div className='page-info-controls'>
        {isPointCloudPage && <PointCloudSourcePagesNavigationButton />}
        {isWebPage && <RefreshPageButton setIframeKey={setIframeKey} />}
        <PageAnswersDiscussionButton />
        <PageDiscussionButton />
        <PageInfoOverlay />
        <CalibrateMeasurementsAnnotationDialog />
      </div>
      <div
        ref={containerRef}
        className={classNames('content-viewer-container', { 'annotation-selection-area': isSelectionEnabled })}
        style={{
          overflow: isPdfPage ? 'scroll' : null,
          justifyContent: isPdfPage ? 'flex-start' : null,
        }}
      >
        {isLoading && <LoadingSpinner />}
        {isPdfPage && <PdfViewer containerRef={containerRef} />}
        {isWebPage && <WebPageViewer iframeKey={iframeKey} />}
        {isVideoPage && <VideoPlayer />}
        {isDocumentPage && <DocumentViewer />}
        {isPointCloudPage && (
          <Suspense fallback={null}>
            <PointCloudViewer container={containerRef.current} {...containerSize} />
          </Suspense>
        )}
        {isAutodeskPage && <AutodeskViewer />}
        {isFilePage && <NoPreviewAvailable />}
        {(isImagePage || isDrawingBoardPage || isSpatialViewPage || isVideoPage) && (
          <>
            <div style={{ position: 'relative', margin: 'auto' }}>
              <ReactReduxContext.Consumer>
                {({ store }) => (
                  <Stage
                    ref={stageRef}
                    className='viewer-content'
                    style={{
                      visibility: isLoading ? 'hidden' : 'visible',
                    }}
                    draggable={isDragAvailable}
                    dragBoundFunc={contentBoundFunc}
                    scaleX={scale * playerScale}
                    scaleY={scale * playerScale}
                    {...stageSize}
                  >
                    <Provider store={store}>
                      {isImagePage && <ImageViewer />}
                      {isDrawingBoardPage && <DrawingBoard />}
                      {isSpatialViewPage && (
                        <SpatialViewContainer
                          zoomed={playerScale > 1}
                          contentRef={contentRef}
                          stageRef={stageRef}
                          contentSize={contentSize}
                        />
                      )}
                      {!isLoading && !isSpatialViewPage && <Annotations />}
                    </Provider>
                  </Stage>
                )}
              </ReactReduxContext.Consumer>
              {!isPdfPage && <SnippingTool width={stageSize.width} height={stageSize.height} />}
            </div>
            {stageRef && stageRef.current && <AnnotationPopoverContainer anchorReference={stageRef.current.content} />}
          </>
        )}
        <ReactResizeDetector handleHeight handleWidth onResize={handleUpdateContainer} />
      </div>
    </>
  )
}

ContentViewer.propTypes = {
  playerScale: PropTypes.number.isRequired,
  contentSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  containerSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  isSelectionEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAutodeskPage: PropTypes.bool.isRequired,
  isWebPage: PropTypes.bool.isRequired,
  isPdfPage: PropTypes.bool.isRequired,
  isVideoPage: PropTypes.bool.isRequired,
  isImagePage: PropTypes.bool.isRequired,
  isSpatialViewPage: PropTypes.bool.isRequired,
  isFilePage: PropTypes.bool.isRequired,
  isPointCloudPage: PropTypes.bool.isRequired,
  isDocumentPage: PropTypes.bool.isRequired,
  isDrawingBoardPage: PropTypes.bool.isRequired,
  isDragAvailable: PropTypes.bool.isRequired,
  contentRef: PropTypes.func.isRequired,
  handleUpdateContainer: PropTypes.func.isRequired,
}

export default ContentViewer
