import { Component } from 'react'
import PropTypes from 'prop-types'

import { CallMade } from '@material-ui/icons'
import { withStyles } from '@material-ui/core'

import styles from './styles'

class ResizeArrow extends Component {
  state = {
    startX: 0,
    startY: 0,
    startWidth: 0,
    startHeight: 0,
  }

  constructor(props) {
    super(props)

    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
  }

  onMouseMove(e) {
    const { onResize } = this.props
    const { startX, startY, startWidth, startHeight } = this.state

    onResize({ width: startWidth + e.pageX - startX, height: startHeight + e.pageY - startY })
  }

  onMouseUp() {
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  onMouseDown(e) {
    this.setState({ startX: e.pageX, startY: e.pageY, startWidth: this.props.width, startHeight: this.props.height })

    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)
  }

  render() {
    const { classes } = this.props

    return <CallMade className={classes.arrow} titleAccess='Resize' onMouseDown={this.onMouseDown} />
  }
}

ResizeArrow.defaultProps = {
  onResize: () => {},
  width: 0,
  height: 0,
}

ResizeArrow.propTypes = {
  classes: PropTypes.shape({
    arrow: PropTypes.string.isRequired,
  }).isRequired,
  onResize: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default withStyles(styles)(ResizeArrow)
