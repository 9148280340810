import { memo, useState } from 'react'
import PropTypes from 'prop-types'

import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

import './styles.less'

import { ConfirmationDialog } from '@tabeeb/modules/shared/dialogs'

const AvailableFormsItem = ({ attachFormToContent, detachFormFromContent, form, isDetachButtonDisabled }) => {
  const [isDetachFormDialogOpen, setIsDetachFormDialogOpen] = useState(false)

  const _handleFormDetach = () => {
    detachFormFromContent(form)
    setIsDetachFormDialogOpen(false)
  }

  const dialogProps = {
    onCloseDialog: () => setIsDetachFormDialogOpen(false),
    isOpen: isDetachFormDialogOpen,
    onSubmit: _handleFormDetach,
    title: 'Are you sure you want to detach this form?',
  }

  return (
    <div className='forms__availableToAttach_item'>
      <p className='forms__availableToAttach_item-name' title={form.Name}>
        {form.Name}
      </p>
      <Divider />
      {form.IconUrl && (
        <div className='forms__availableToAttach_item-img'>
          <img src={form.IconUrl} alt='snapshot' />
        </div>
      )}
      {!form.IconUrl && <div className='forms__availableToAttach_item-img' />}

      <div className='forms__availableToAttach_item-info'>
        <span>Controls: {form.ControlsNumber}</span>
        <span>Sessions: {form.SessionsNumber}</span>
      </div>

      <Button
        onClick={() => (form.attached ? setIsDetachFormDialogOpen(true) : attachFormToContent(form))}
        variant='contained'
        className={form.attached ? 'detach-form-btn' : 'attach-form-btn'}
        disabled={isDetachButtonDisabled}
      >
        {form.attached ? 'detach' : 'attach'}
      </Button>

      <ConfirmationDialog {...dialogProps} />
    </div>
  )
}

AvailableFormsItem.propTypes = {
  form: PropTypes.object.isRequired,
  attachFormToContent: PropTypes.func.isRequired,
  detachFormFromContent: PropTypes.func.isRequired,
  isDetachButtonDisabled: PropTypes.bool.isRequired,
}

export default memo(AvailableFormsItem)
