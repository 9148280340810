import { handleActions } from 'redux-actions'
import { closeAiReportDialog, selectAiObject } from '../actions'

const defaultState = {
  aiObjectId: 0,
}

export default handleActions(
  {
    [selectAiObject](state, action) {
      return action.payload
    },
    [closeAiReportDialog]() {
      return defaultState
    },
  },
  defaultState
)
