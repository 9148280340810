import { memo, useCallback } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import Routes from '@tabeeb/routes'

import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { KeyboardArrowLeftOutlined } from '@mui/icons-material'

import { ActionsContainer } from './styles'

/**
 * @typedef {Object} PageHeaderProps
 * @property {string | import('react').ReactNode} title - The title of the page header.
 * @property {import('react').ReactNode} [actions] - The actions to be displayed in the page header.
 */

/**
 * @param {PageHeaderProps} props
 */
const PageHeader = ({ title, actions }) => {
  const history = useHistory()

  const onBack = useCallback(() => {
    if (history.length <= 1) {
      history.push(Routes.home)

      return
    }

    history.goBack()
  }, [history])

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' mb={1} mt={1}>
      <Box display='flex' alignItems='center' minWidth={80}>
        <Tooltip title='Go back'>
          <IconButton sx={{ mr: 1 }} onClick={onBack}>
            <KeyboardArrowLeftOutlined />
          </IconButton>
        </Tooltip>
        {typeof title === 'string' ? (
          <Typography title={title} variant='h6' fontWeight={600} noWrap>
            {title}
          </Typography>
        ) : (
          title
        )}
      </Box>
      <ActionsContainer>{actions}</ActionsContainer>
    </Box>
  )
}

PageHeader.propTypes = {
  title: PropTypes.node.isRequired,
  actions: PropTypes.node,
}

export default memo(PageHeader)
