import { memo } from 'react'
import PropTypes from 'prop-types'

import { ConfirmationDialog } from '@tabeeb/shared/dialogs'
import { sessionTitleFormatter, trainingMaterialTitleFormatter } from '@tabeeb/shared/utils/text'

const TrainingMaterialDeleteConfirmationDialog = ({ open, onClose, onSubmit }) => {
  return (
    <ConfirmationDialog
      isOpen={open}
      onCloseDialog={onClose}
      onSubmit={onSubmit}
      title={trainingMaterialTitleFormatter.format('Are you sure you want to delete training material?')}
      dialogMessage={`Deleting this ${trainingMaterialTitleFormatter.format(
        'training material'
      )} will permanently remove it and detach it from all associated ${sessionTitleFormatter.format('sessions')}`}
      submitButtonColor='error'
      submitButtonText='Delete'
    />
  )
}

TrainingMaterialDeleteConfirmationDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
}

TrainingMaterialDeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default memo(TrainingMaterialDeleteConfirmationDialog)
