import { List, ListItemIcon, ListItemText, Skeleton } from '@mui/material'
import PropTypes from 'prop-types'

import { VersionsListItemButton } from './styles'

const VersionsList = ({
  disabled,
  loading,
  versions,
  selectedVersionId,
  onSelectContractVersion: onSelectVersion,
  VersionListItemComponent,
}) => {
  return (
    <List>
      {loading &&
        Array.from({ length: 6 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <VersionsListItemSkeleton key={index} />
        ))}
      {!loading &&
        versions.map((version) => (
          <VersionListItemComponent
            key={version.Id}
            disabled={disabled}
            version={version}
            selected={version.Id === selectedVersionId}
            onSelect={onSelectVersion}
          />
        ))}
    </List>
  )
}

VersionsList.defaultProps = {
  disabled: false,
  loading: false,
}

VersionsList.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  selectedVersionId: PropTypes.number,
  onSelectContractVersion: PropTypes.func.isRequired,
  VersionListItemComponent: PropTypes.elementType.isRequired,
}

const VersionsListItemSkeleton = () => {
  return (
    <VersionsListItemButton>
      <ListItemIcon>
        <Skeleton variant='circular' width={20} height={20} />
      </ListItemIcon>
      <ListItemText
        primary={<Skeleton variant='text' width={180} />}
        secondary={<Skeleton variant='text' width={80} />}
      />
    </VersionsListItemButton>
  )
}

export default VersionsList

export { VersionsListItemButton }
