import { createSelector } from 'reselect'
import { getCurrentUserId as accountGetCurrentUserId } from '@tabeeb/modules/account/selectors'
import { getPresenterId } from '@tabeeb/modules/shared/content/selectors'
import { isMobileOnly } from 'react-device-detect'
import { AUDIO_DEVICE, CAMERA_FACING_MODE, VIDEO_DEVICE } from '@tabeeb/modules/presentation/constants'

export const getIsCallStarted = (state) => state.presentation.connection.isCallStarted
export const getIsInPresentationMode = (state) => Boolean(state.presentation.conference.room)
export const getParticipantIdByUserId = (state, userId) => state.presentation.userToParticipantIdMap[userId]
export const getCurrentUserId = (state) => accountGetCurrentUserId(state)
export const getLargeVideoUser = (state) => state.presentation.tracks.largeVideoUser
export const getIsLargeVideoUser = (state) => state.presentation.tracks.largeVideoUser !== null
export const getGridViewSelectedUser = (state) => state.presentation.tracks.gridUser
export const getIsScreenSharing = (state) => state.presentation.screenSharing.isScreenSharing
export const getIsScreenSharingInProgress = (state) => state.presentation.screenSharing.isScreenSharingInProgress
export const getIsPriorityViewEnabled = (state) => state.presentation.tracks.isPriorityView

export const getPreviewTracks = (state) => state.presentation.tracks.previewTracks

export const getFacingMode = (state) => state.presentation.devices.facingMode
export const getAudioInputDevice = (state) => state.presentation.devices.audioInputId
export const getAudioOutputDevice = (state) => state.presentation.devices.audioOutputId
export const getVideoInputDevice = (state) => state.presentation.devices.videoInputId

export const getIsCallPreviewOpen = (state) => state.presentation.conference.isCallPreviewOpen

export const getSelectedUser = createSelector(
  [getLargeVideoUser, getGridViewSelectedUser],
  (largeVideoUser, gridUser) => largeVideoUser || gridUser
)

export const getUserTrack = (state, { Id, Type }) => {
  const userTracks =
    getCurrentUserId(state) === Id ? state.presentation.tracks.localTracks : state.presentation.tracks.remoteTracks[Id]

  if (!userTracks) {
    return undefined
  }

  return userTracks.find((track) => track.getType() === Type)
}

export const getIsUserAudioPlaying = (state, { Id }) => {
  const audioTrack = getUserTrack(state, { Id, Type: AUDIO_DEVICE })
  if (!audioTrack) {
    return false
  }

  return !audioTrack.isMuted()
}

export const makeGetIsUserVideoPlaying =
  () =>
  (state, { Id }) => {
    const videoTrack = getUserTrack(state, { Id, Type: VIDEO_DEVICE })
    if (!videoTrack) {
      return false
    }

    return !videoTrack.isMuted()
  }

export const getIsUserVideoPlaying = makeGetIsUserVideoPlaying()

export const getIsLargeVideoMirrored = createSelector(
  [getLargeVideoUser, getCurrentUserId, getIsScreenSharing, getIsScreenSharingInProgress, getFacingMode],
  (largeVideoUser, currentUserId, isScreenSharing, isScreenSharingInProgress, facingMode) => {
    const isScreenSharingNow = isScreenSharing && !isScreenSharingInProgress
    const isCurrentUserLarge = largeVideoUser !== null ? largeVideoUser.id === currentUserId : false

    const isUserFacingMode = facingMode === CAMERA_FACING_MODE.USER
    return !isScreenSharingNow && isCurrentUserLarge && isUserFacingMode
  }
)

export const makeGetIsUserPresenter = () =>
  createSelector([getPresenterId, (_, { userId }) => userId], (presenterId, userId) => {
    return presenterId === userId
  })

export const makeGetIsUserVideoOnMainScreen = () =>
  createSelector([getLargeVideoUser, (_, { userId }) => userId], (largeVideoUser, userId) => {
    return largeVideoUser !== null && largeVideoUser.id === userId
  })

export const makeGetIsGridUserSelected = () =>
  createSelector([getGridViewSelectedUser, (_, { userId }) => userId], (gridUser, userId) => {
    return !isMobileOnly && gridUser && gridUser.id === userId
  })

export const makeGetIsUserVideoMirrored = () =>
  createSelector(
    [getIsScreenSharing, getIsScreenSharingInProgress, getCurrentUserId, getFacingMode, (_, { userId }) => userId],
    (isScreenSharing, isScreenSharingInProgress, currentUserId, facingMode, userId) => {
      return (
        !(isScreenSharing && !isScreenSharingInProgress) &&
        userId === currentUserId &&
        facingMode === CAMERA_FACING_MODE.USER
      )
    }
  )
