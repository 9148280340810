import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { ConfirmationDialog } from '@tabeeb/shared/dialogs'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { leaveSession } from '@tabeeb/modules/sessionsPage/actions'

const LeaveSessionDialog = ({ open, session, onClose }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(() => {
    dispatch(leaveSession.request({ contentId: session.Id }))

    onClose()
  }, [dispatch, onClose, session.Id])

  return (
    <ConfirmationDialog
      isOpen={open}
      title={sessionTitleFormatter.format('Leave session')}
      dialogMessage={sessionTitleFormatter.format('Are you sure you want to leave this session?')}
      onCloseDialog={onClose}
      onSubmit={onSubmit}
    />
  )
}

LeaveSessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(LeaveSessionDialog)
