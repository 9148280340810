import { memo } from 'react'
import PropTypes from 'prop-types'

import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material'

const TenantInfo = ({ item, avatarWrapper: AvatarWrapper, avatarWrapperProps }) => {
  return (
    <ListItem component='div' title={item.Name} disableGutters dense>
      <ListItemAvatar>
        <AvatarWrapper {...avatarWrapperProps}>
          <Avatar alt={item.Name}>{item.Name[0]?.toUpperCase()}</Avatar>
        </AvatarWrapper>
      </ListItemAvatar>
      <ListItemText primary={item.Name} secondary={`Subdomain: ${item.Subdomain}`} />
    </ListItem>
  )
}

const TenantInfoSkeleton = () => {
  return (
    <ListItem component='div' disableGutters dense>
      <Skeleton variant='circular' width={40} height={40} />
      <Box ml={2}>
        <Skeleton variant='text' width={200} />
        <Skeleton variant='text' width={150} />
      </Box>
    </ListItem>
  )
}

TenantInfo.defaultProps = {
  avatarWrapper: ({ children }) => children,
  avatarWrapperProps: {},
}

TenantInfo.propTypes = {
  item: PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string.isRequired,
    Subdomain: PropTypes.string.isRequired,
  }).isRequired,
  avatarWrapper: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]),
  avatarWrapperProps: PropTypes.instanceOf(Object),
}

export default memo(TenantInfo)

export { TenantInfoSkeleton }
