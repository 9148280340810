import { memo } from 'react'
import PropTypes from 'prop-types'

import { Tooltip } from '@mui/material'
import { HelpOutlineOutlined } from '@mui/icons-material'

const Help = ({ text }) => {
  return (
    <Tooltip title={text}>
      <HelpOutlineOutlined sx={{ verticalAlign: 'middle', cursor: 'help' }} fontSize='small' color='info' />
    </Tooltip>
  )
}

Help.propTypes = {
  text: PropTypes.string.isRequired,
}

export default memo(Help)
