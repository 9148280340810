import { Button, styled } from '@mui/material'
import { FlexContainer } from '@tabeeb/modules/shared/uikit'

export const Container = styled(FlexContainer)(({ theme }) => ({
  justifyContent: 'space-between',
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(),
  height: 28,
  width: '49%',
}))
