import { combineReducers } from 'redux'
import conference from './conference'
import screenSharing from './screenSharing'
import tracks from './tracks'
import connection from './connection'
import jitsiTrack from './jitsiTrack'
import userToParticipantIdMap from './userToParticipantIdMap'
import devices from './devices'

export default combineReducers({
  connection,
  conference,
  tracks,
  screenSharing,
  jitsiTrack,
  userToParticipantIdMap,
  devices,
})
