import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { maxLength, requiredField } from '@tabeeb/modules/shared/utils/validationErrorMessages'

import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Span } from '@tabeeb/modules/shared/uikit/components'
import { StyledButton } from './styles'

const templateNameSchema = object().shape({
  name: string().max(30, maxLength(30)).required(requiredField),
})

const TemplateDialog = ({ isOpen, onCancel, onSubmit, templateNames, header, defaultName }) => {
  const formik = useFormik({
    initialValues: {
      name: defaultName,
    },
    validationSchema: templateNameSchema,
    onSubmit: (values) => {
      onSubmit(values.name)
    },
  })

  const isNameRepeated = templateNames.map((name) => name.toLowerCase()).includes(formik.values.name?.toLowerCase())

  const hasError = Boolean(isNameRepeated || formik.errors.name)
  const errorMessage = isNameRepeated ? 'Template name should be unique' : formik.errors.name

  useEffect(() => {
    formik.setFieldValue('name', defaultName)
  }, [defaultName])

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>
        <TextField
          name='name'
          size='small'
          label='Template Name'
          value={formik.values.name}
          onChange={formik.handleChange}
          fullWidth
          error={hasError}
          helperText={hasError ? errorMessage : ''}
        />
      </DialogContent>

      <DialogActions>
        <StyledButton
          onClick={() => {
            formik.validateForm()
            formik.handleSubmit()
          }}
          disabled={hasError}
        >
          <Span>SAVE</Span>
        </StyledButton>
        <StyledButton onClick={onCancel}>
          <Span>CANCEL</Span>
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

TemplateDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  templateNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  header: PropTypes.string.isRequired,
}

export default TemplateDialog
