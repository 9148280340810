import { handleActions } from 'redux-actions'

import * as conferenceActions from '../actions/conference'

const defaultState = {}

export default handleActions(
  {
    [conferenceActions.setParticipantIdForUser](state, action) {
      const { userId, participantId } = action.payload

      return { ...state, [userId]: participantId }
    },
  },
  defaultState
)
