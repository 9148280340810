import { createSelector } from 'reselect'
import { getSelectedUserId } from '@tabeeb/modules/recording/selectors'

import { getCurrentUserId } from '@tabeeb/modules/account/selectors'

export const getPresenterId = (state) => state.contentState.presenterId
export const getContentId = (state) => state.contentState.contentId
export const getDocumentId = (state) => state.contentState.documentId
export const getOwnerId = (state) => state.contentState.ownerId
export const getContentDescription = (state) => state.contentState.description
export const getContentTenantId = (state) => state.contentState.tenantId
export const getContentLastCallStartTime = (state) => state.contentState.lastCallStartTime
export const getContentState = (state) => state.contentState

export const getIsCurrentUserPresenter = (state) => getCurrentUserId(state) === getPresenterId(state)
export const getIsCurrentUserOwner = (state) => getCurrentUserId(state) === getOwnerId(state)
export const getIsCurrentUserBeingRecorded = (state) => getCurrentUserId(state) === getSelectedUserId(state)

export const makeGetIsUserOwner = () =>
  createSelector([getOwnerId, (_, { userId }) => userId], (ownerId, userId) => {
    return ownerId === userId
  })
