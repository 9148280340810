import { combineReducers } from 'redux'

import isOpen from './isOpen'
import aiObjects from './aiObjects'
import analysis from './analysis'
import selectedAiObject from './selectedAiObject'
import selectedTemplate from './selectedTemplate'
import areObjectsLoading from './areObjectsLoading'
import isAnalysisLoading from './isAnalysisLoading'
import publishTemplates from './publishTemplates'
import isRefreshNeeded from './isRefreshNeeded'
import isPublishSettingsDialogOpen from './isPublishSettingsDialogOpen'
import defaultPublishName from './defaultPublishName'
import isUniqueNameCheckInProgress from './isUniqueNameCheckInProgress'
import isPublishInProgress from './isPublishInProgress'

export default combineReducers({
  isOpen,
  aiObjects,
  analysis,
  selectedAiObject,
  selectedTemplate,
  isAnalysisLoading,
  areObjectsLoading,
  publishTemplates,
  isRefreshNeeded,
  isPublishSettingsDialogOpen,
  defaultPublishName,
  isUniqueNameCheckInProgress,
  isPublishInProgress,
})
