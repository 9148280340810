import { useCallback, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material'
import usePowerBIReportTenants from '@tabeeb/modules/powerBIReports/hooks/usePowerBIReportTenants'
import { FetchStatus } from '@tabeeb/enums'
import InfiniteScroll from 'react-infinite-scroller'
import { Add, MoreVert, RefreshOutlined } from '@mui/icons-material'
import { deletePowerBIReportTenant, openPowerBIReportTenantModal } from '@tabeeb/modules/powerBIReports/actions'
import TenantInfo from '@tabeeb/modules/shared/uikit/components/TenantInfo'
import { useDialogState, useSelectedItemMenu } from '@tabeeb/modules/shared/utils/hooks'
import { PowerBIReportTenantModal } from '../../Modals'
import PowerBIReportTenantMenu from './PowerBIReportTenantMenu'
import DeleteReportTenantConfirmationDialog from '../../Dialogs/DeleteReportTenantConfirmationDialog'

const PowerBIReportTenants = ({ report }) => {
  const dispatch = useDispatch()
  const containerRef = useRef()

  const { fetchStatus, loading, tenants, hasMore, onLoadMore, onReload } = usePowerBIReportTenants(report.Id)
  const [anchorEl, selectedReportTenant, open, onOpenMenu, onCloseMenu] = useSelectedItemMenu()
  const [deleteReportTenantDialogOpen, onDeleteReportTenantDialogOpen, onDeleteReportTenantDialogClose] =
    useDialogState()

  const onAdd = useCallback(() => {
    dispatch(openPowerBIReportTenantModal({ report }))
  }, [dispatch, report])

  const onEdit = useCallback(() => {
    dispatch(openPowerBIReportTenantModal({ report, reportTenant: selectedReportTenant }))
  }, [dispatch, report, selectedReportTenant])

  const onDelete = useCallback(() => {
    dispatch(deletePowerBIReportTenant.request(selectedReportTenant.Id))
    onDeleteReportTenantDialogClose()
  }, [dispatch, onDeleteReportTenantDialogClose, selectedReportTenant?.Id])

  const buttonsDisabled = useMemo(() => {
    return loading
  }, [loading])

  const refreshButtonIcon = useMemo(() => {
    return buttonsDisabled ? <CircularProgress size={18} /> : <RefreshOutlined color='primary' />
  }, [buttonsDisabled])

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid container item xs={12} spacing={1} justifyContent='flex-end'>
        <Grid item>
          <Button variant='outlined' disabled={buttonsDisabled} startIcon={refreshButtonIcon} onClick={onReload}>
            Reload
          </Button>
        </Grid>
        <Grid item>
          <Button variant='outlined' disabled={buttonsDisabled} startIcon={<Add color='primary' />} onClick={onAdd}>
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container ref={containerRef}>
        <InfiniteScroll
          useWindow={false}
          loadMore={onLoadMore}
          hasMore={hasMore}
          getScrollParent={() => containerRef.current}
          style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}
        >
          {fetchStatus === FetchStatus.Failed && (
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center' }}>Failed to load report tenants.</Typography>
            </Grid>
          )}
          {fetchStatus === FetchStatus.Loaded && tenants.length === 0 && (
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center' }}>Tenants list is empty.</Typography>
            </Grid>
          )}
          {tenants.map((tenant) => {
            return (
              <Grid key={tenant.Id} item xs={6} container alignItems='center'>
                <Grid container item xs={0.3} height='100%'>
                  <Divider orientation='vertical' flexItem />
                </Grid>
                <Grid item xs={10.7}>
                  <TenantInfo key={tenant.Id} item={{ Name: tenant.TenantName, Subdomain: tenant.TenantSubdomain }} />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={(e) => onOpenMenu({ anchor: e.target, item: tenant })}>
                    <MoreVert />
                  </IconButton>
                </Grid>
              </Grid>
            )
          })}
        </InfiniteScroll>
      </Grid>
      <PowerBIReportTenantModal />
      <PowerBIReportTenantMenu
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseMenu}
        onEdit={onEdit}
        onDelete={onDeleteReportTenantDialogOpen}
      />
      <DeleteReportTenantConfirmationDialog
        open={deleteReportTenantDialogOpen}
        onClose={onDeleteReportTenantDialogClose}
        onSubmit={onDelete}
      />
    </Grid>
  )
}

PowerBIReportTenants.propTypes = {
  report: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    SecurityModel: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default PowerBIReportTenants
