import Node from './CircularLinkedListNode'

export default class CircularLinkedList {
  constructor(...params) {
    this.head = null
    this.tail = null

    params.forEach((element) => {
      this.append(element)
    })
  }

  append(data) {
    const newNode = new Node(data)

    if (!this.head) {
      this.head = newNode
      this.tail = newNode
      newNode.next = this.head
    } else {
      this.tail.next = newNode
      newNode.next = this.head
      this.tail = newNode
    }
  }

  moveNext() {
    if (this.head) {
      this.head = this.head.next
      this.tail = this.tail.next
    }
  }

  setHeadByValue(value) {
    let currentNode = this.head
    let found = false

    if (currentNode) {
      while (currentNode.next !== this.head) {
        if (currentNode.data === value) {
          found = true
          break
        }
        currentNode = currentNode.next
      }
      if (currentNode.data === value) {
        found = true
      }
    }

    if (found) {
      this.head = currentNode
      this.tail = currentNode.next
    }
  }
}
