import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { ConfirmationDialog } from '@tabeeb/shared/dialogs'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { unpublishSession } from '@tabeeb/modules/sessionsPage/actions'

const UnpublishSessionDialog = ({ open, session, onClose }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(() => {
    dispatch(unpublishSession.request(session.Id))

    onClose()
  }, [dispatch, onClose, session?.Id])

  return (
    <ConfirmationDialog
      isOpen={open}
      title={sessionTitleFormatter.format('Un-publish session')}
      dialogMessage={sessionTitleFormatter.format('Would you like to unpublish this session for all users?')}
      onCloseDialog={onClose}
      onSubmit={onSubmit}
      cancelButtonText='No'
      submitButtonText='Yes'
    />
  )
}

UnpublishSessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IsPublished: PropTypes.bool.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(UnpublishSessionDialog)
