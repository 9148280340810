import storage from 'reduxjs-toolkit-persist/lib/storage'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { persistReducer, persistStore, createMigrate } from 'reduxjs-toolkit-persist'
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'

import ai from '@tabeeb/services/telemetryService'

import * as jitsiTrackActions from '@tabeeb/modules/presentation/actions/tracks'
import { injectStore as apiClientInjectStore } from '@tabeeb/services/API'
import { injectStore as oidcInjectStore } from '@tabeeb/services/oidcUserManager'

import migrations from './storage/migrations'

import createSagaInjector from './createSagaInjector'

import staticReducers from './reducers'
import rootSaga from './sagas'

export let applicationStore = null
export let applicationPersistor

export const history = createBrowserHistory()

const routeMiddleware = routerMiddleware(history)

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  migrate: createMigrate(migrations, { debug: false }),
  whitelist: ['sessionState', 'appConfigState', 'permissions', 'sncFilters'],
}

const createReducer = (asyncReducers) =>
  persistReducer(
    persistConfig,
    combineReducers({
      router: connectRouter(history),
      ...staticReducers,
      ...asyncReducers,
    })
  )

const composeEnhancers = composeWithDevToolsDevelopmentOnly({
  actionsDenylist: [...Object.values(jitsiTrackActions).map((action) => action.toString())],
})

export default (preloadedState) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, { sagaStack }) => {
      ai.appInsights?.trackException({ exception: error, properties: { featureId: 'redux-saga', stack: sagaStack } })
      if (process.env.DEBUG_LOGGING_ENABLED === 'true') {
        /* eslint-disable no-console */
        console.error(error)
        console.error(sagaStack)
      }
    },
  })

  const middlewares = [sagaMiddleware, thunkMiddleware, routeMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]

  const composedEnhancers = composeEnhancers(...enhancers)

  const store = createStore(createReducer(), preloadedState, composedEnhancers)

  store.asyncReducers = {}
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer
    store.replaceReducer(createReducer(store.asyncReducers))
  }
  store.injectReducers = (reducers) => {
    store.asyncReducers = { ...store.asyncReducers, ...reducers }
    store.replaceReducer(createReducer(store.asyncReducers))
  }

  store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga)

  const persistor = persistStore(store)

  apiClientInjectStore(store)
  oidcInjectStore(store)

  applicationStore = store
  applicationPersistor = persistor

  return { store, persistor }
}
