import _ from 'lodash'

import selector from '@tabeeb/modules/shared/utils/selector'
import ApiMethods from '@tabeeb/modules/shared/utils/API'
import { applicationStore } from '@tabeeb/state/createStore'
import ApiService from '../../../../services/API'

export function callAPIPromise(action, options = { callResultActions: true, abortControllerSignal: null }) {
  const state = applicationStore.getState()
  const apiHostName = selector.getApiUrl(state)
  const accessToken = selector.getAccessToken(state)
  const data = ApiMethods[_.camelCase(action.type)](action.payload)

  return new Promise((resolve, reject) => {
    ApiService({
      hostName: apiHostName,
      accessToken,
      data,
      signal: options?.abortControllerSignal,
    })
      .then((response) => {
        const successModel = {
          type: action.type.replace('_REQUEST', '_SUCCESS'),
          response,
          payload: action.payload,
        }

        if (options.callResultActions) {
          applicationStore.dispatch(successModel)
        }

        resolve(successModel)
      })
      .catch((e) => {
        const errorModel = {
          type: action.type.replace('_REQUEST', '_FAILED'),
          payload: action.payload,
          message: e.statusText,
          status: e.status,
          response: e.response,
        }

        if (options.callResultActions) {
          applicationStore.dispatch(errorModel)
        }

        reject(errorModel)
      })
  })
}

export { callAPIPromise as callApiAsync }
