import { memo } from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, ListItemText, Skeleton } from '@mui/material'

const Tenant = ({ item }) => {
  return (
    <ListItem component='div' title={item.Name} disableGutters dense>
      <ListItemText
        primary={item.Name}
        secondary={item.Subdomain}
        primaryTypographyProps={{ variant: 'body2', fontWeight: 500, noWrap: true }}
      />
    </ListItem>
  )
}

const TenantSkeleton = () => {
  return (
    <Box>
      <Skeleton variant='text' width={150} />
      <Skeleton variant='text' width={100} />
    </Box>
  )
}

Tenant.propTypes = {
  item: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Subdomain: PropTypes.string,
  }).isRequired,
}

export default memo(Tenant)

export { TenantSkeleton }
