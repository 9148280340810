import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'
import { signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'
import { runUtilityInfrastructureAnalysis } from '@tabeeb/modules/utilityInfrastructure/actions'
import { put, all, takeLatest } from 'redux-saga/effects'

function* onWorkflowFailed(action) {
  const [notification] = action.payload
  yield put(onAddErrorNotification({ message: notification.message }))
}

function* onRunUtilityInfrastructureAnalysisSuccess() {
  yield put(onAddSuccessNotification({ message: 'Utility poles are sent for analysis' }))
}

function* onRunUtilityInfrastructureAnalysisFailed() {
  yield put(onAddErrorNotification({ message: 'Failed to send utility poles for analysis' }))
}

function* workflowSaga() {
  yield all([
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onWorkflowFailed, onWorkflowFailed),
    takeLatest(runUtilityInfrastructureAnalysis.success, onRunUtilityInfrastructureAnalysisSuccess),
    takeLatest(runUtilityInfrastructureAnalysis.failed, onRunUtilityInfrastructureAnalysisFailed),
  ])
}

export default workflowSaga
