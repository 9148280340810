import PropTypes from 'prop-types'
import { Button as MuiButton, styled } from '@mui/material'
import { Span } from '@tabeeb/modules/shared/uikit/components'

const StyledButton = styled(MuiButton)(({ theme }) => {
  return {
    height: 38,
    minHeight: 38,
    maxHeight: 38,
    textTransform: 'none',
    width: 90,
  }
})

const Button = ({ onClick, disabled, label, ...props }) => {
  return (
    <StyledButton variant='outlined' onClick={onClick} disabled={disabled} {...props}>
      <Span>{label}</Span>
    </StyledButton>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  props: PropTypes.instanceOf(Object),
}

export default Button
