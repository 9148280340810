import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowDownward, ArrowUpward, FilterAlt, Functions } from '@mui/icons-material'
import { Box, Menu, MenuItem, Popover } from '@mui/material'

import DragNDropLists from '@tabeeb/modules/shared/uikit/components/DragNDropLists'
import CircularLinkedList from '@tabeeb/modules/shared/utils/helpers/CircularLinkedList'

import { getAvailableColumns } from '../../selectors'
import { setAvailableColumns } from '../../actions'
import ColumnList from '../ColumnList'
import Filter from '../Filter'
import Grouping from '../Grouping'
import { groupingOperators, popoverTypes, sortingDirections } from '../../constants'
import { MenuItemContent, StyledDivider } from './styles'

const getSortingIcon = (direction) => {
  if (direction === sortingDirections.asc) {
    return <ArrowUpward fontSize='small' />
  }
  if (direction === sortingDirections.desc) {
    return <ArrowDownward fontSize='small' />
  }

  return null
}

const sortingOrder = new CircularLinkedList(sortingDirections.unset, sortingDirections.asc, sortingDirections.desc)

const ColumnsPicker = ({ template, onTemplateSet }) => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverType, setPopoverType] = useState(null)

  const [anchorMenuEl, setAnchorMenuEl] = useState(null)
  const [anchorMenuColumn, setAnchorMenuColumn] = useState(null)

  const availableColumns = useSelector(getAvailableColumns)

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget)
    setPopoverType(type)
  }

  const updateSelectedColumn = (column) => {
    const updatedColumns = template.columns.map((c) => (c.field === column.field ? column : c))
    setAnchorMenuColumn(column)
    onTemplateSet({ ...template, columns: updatedColumns })
  }

  const handleMenuClick = (event, column) => {
    setAnchorMenuEl(event.currentTarget)
    setAnchorMenuColumn(column)
  }

  const handleClose = () => {
    setPopoverType(null)
    setAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorMenuEl(null)
    setAnchorMenuColumn(null)
  }

  const dropIds = {
    selected: 'selected',
    available: 'available',
  }

  const actions = {
    [dropIds.selected]: (columns) => onTemplateSet({ ...template, columns }),
    [dropIds.available]: (columns) => dispatch(setAvailableColumns(columns)),
  }

  const handleCollectionsChanged = (changedCollections) => {
    changedCollections.forEach((collection) => {
      const action = actions[collection.droppableId]
      action(collection.values)
    })
  }

  const handleChangeSorting = () => {
    const sortedColumns = template.columns.filter((c) => c.sorting?.direction)
    const currentPriority = anchorMenuColumn.sorting?.priority ?? sortedColumns.length

    sortingOrder.setHeadByValue(anchorMenuColumn.sorting?.direction ?? sortingDirections.unset)
    sortingOrder.moveNext()

    const direction = sortingOrder.head.data

    const sorting = direction
      ? {
          direction,
          priority: currentPriority,
        }
      : {}

    const updatedColumn = {
      ...anchorMenuColumn,
      sorting,
    }

    const newColumns = template.columns.map((column) => {
      if (column.field === anchorMenuColumn.field) {
        return updatedColumn
      }

      const priority = column.sorting?.priority

      if (!priority || priority < currentPriority) {
        return column
      }

      return {
        ...column,
        sorting: {
          ...column.sorting,
          priority: priority - 1,
        },
      }
    })

    setAnchorMenuColumn(updatedColumn)
    onTemplateSet({ ...template, columns: newColumns })
  }

  const collections = [
    {
      droppableId: dropIds.selected,
      header: 'Selected columns',
      values: template.columns,
      secondaryAction: (e, column) => handleMenuClick(e, column),
    },
    {
      droppableId: dropIds.available,
      header: 'Available columns',
      values: availableColumns,
    },
  ]

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <DragNDropLists
        divider={<StyledDivider />}
        onCollectionsChanged={handleCollectionsChanged}
        collections={collections}
        CollectionComponent={ColumnList}
      />

      <Menu
        anchorEl={anchorMenuEl}
        open={Boolean(anchorMenuEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={(e) => handleClick(e, popoverTypes.Filter)}>
          <MenuItemContent>
            <span>Filtering</span>
            {anchorMenuColumn?.filter?.operation ? <FilterAlt /> : null}
          </MenuItemContent>
        </MenuItem>
        <MenuItem onClick={(e) => handleClick(e, popoverTypes.Grouping)}>
          <MenuItemContent>
            <span>Grouping</span>
            {anchorMenuColumn?.grouping ? <Functions /> : null}
          </MenuItemContent>
        </MenuItem>
        <MenuItem onClick={handleChangeSorting}>
          <MenuItemContent>
            <span>Sorting</span>
            {getSortingIcon(anchorMenuColumn?.sorting?.direction)}
          </MenuItemContent>
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateSelectedColumn({
              ...anchorMenuColumn,
              filter: {},
              sorting: {},
              grouping: groupingOperators.unset,
            })
          }}
        >
          <MenuItemContent>
            <span>Clear All</span>
          </MenuItemContent>
        </MenuItem>
      </Menu>

      <Popover
        open={popoverTypes.Filter === popoverType}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Filter
          onFilterApply={(filterOperation, filterValue) => {
            updateSelectedColumn({ ...anchorMenuColumn, filter: { operation: filterOperation, value: filterValue } })
            handleClose()
          }}
          filter={anchorMenuColumn?.filter}
        />
      </Popover>

      <Popover
        open={popoverTypes.Grouping === popoverType}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Grouping
          operator={anchorMenuColumn?.grouping}
          onGroupingApply={(operator) => {
            updateSelectedColumn({ ...anchorMenuColumn, grouping: operator })
            handleClose()
          }}
        />
      </Popover>
    </Box>
  )
}

export default ColumnsPicker
