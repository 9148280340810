import PropTypes from 'prop-types'
import { FetchStatus } from '@tabeeb/enums'
import useIdentityServerUser from '@tabeeb/modules/account/hooks/useIdentityServerUser'
import UserInfo, { UserInfoSkeleton } from '../UserInfo'

const IdentityServerUserInfo = ({ userIdentityId }) => {
  const { fetchStatus, user } = useIdentityServerUser(userIdentityId, !!userIdentityId)

  if (fetchStatus === FetchStatus.Loaded) {
    return <UserInfo item={user} />
  }

  return <UserInfoSkeleton />
}

IdentityServerUserInfo.propTypes = {
  userIdentityId: PropTypes.string.isRequired,
}

export default IdentityServerUserInfo
