import { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Box, IconButton, Menu, Select, FormControl, InputLabel, MenuItem } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'

import { Span } from '@tabeeb/modules/shared/uikit/components'
import { ConfirmationDialog } from '@tabeeb/modules/shared/dialogs'

import TemplateDialog from '../TemplateDialog'
import Button from '../Button'
import { ButtonsContainer, ToolbarContainer } from './styles'

const Toolbar = ({
  template,
  templates,
  onTemplateSave,
  onTemplateAdd,
  isInEditMode,
  setIsInEditMode,
  onTemplateSet,
  onTemplateDelete,
}) => {
  const [initialTemplate, setInitialTemplate] = useState(template)
  const [saveAsDialogOpen, setSaveAsDialogOpen] = useState(false)
  const [changeTemplate, setChangeTemplate] = useState(null)
  const [menuAnchor, setMenuAnchor] = useState(null)

  const [exitOnSubmit, setExitOnSubmit] = useState(false)

  const [isNameEditing, setNameIsEditing] = useState(false)

  const [isDeleteTemplateConfirmationOpen, setIsDeleteTemplateConfirmationOpen] = useState(false)

  const handleChangeWithCheck = (event) => {
    const newTemplate = templates.find((t) => t.id === event.target.value) ?? { columns: null, id: 0 }
    const isEqual = _.isEqual(initialTemplate.columns, template.columns)

    if (isEqual || !isInEditMode) {
      // external
      onTemplateSet(newTemplate)
      return
    }

    setChangeTemplate(newTemplate)
  }

  const handleTemplateSave = () => {
    setInitialTemplate(template)
    // external
    onTemplateSave(template)
  }

  const handleTemplateSaveAs = (name) => {
    const newTemplate = { name, columns: template.columns }
    setSaveAsDialogOpen(false)

    // external
    onTemplateAdd(newTemplate)
  }

  useEffect(() => {
    if (initialTemplate.id !== template.id || !initialTemplate.columns) {
      setInitialTemplate(template)
    }
  }, [initialTemplate.id, template])

  return (
    <ToolbarContainer>
      <div style={{ display: 'flex', gap: 8 }}>
        <FormControl size='small'>
          <InputLabel id='templates-label'>Templates</InputLabel>
          <Select
            variant='outlined'
            style={{ width: 200, padding: 0 }}
            inputProps={{ IconComponent: () => null }}
            value={template.id}
            onChange={handleChangeWithCheck}
            labelId='templates-label'
            label='Templates'
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            }}
            endAdornment={
              isInEditMode &&
              Boolean(template.id) && (
                <Box mr={1} display='flex' alignItems='center'>
                  <IconButton
                    size='small'
                    className='button'
                    onClick={(e) => {
                      e.stopPropagation()
                      setNameIsEditing(true)
                    }}
                  >
                    <Edit sx={{ width: 16, height: 16 }} />
                  </IconButton>
                  <IconButton
                    size='small'
                    className='button'
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsDeleteTemplateConfirmationOpen(true)
                    }}
                  >
                    <Delete sx={{ width: 16, height: 16 }} />
                  </IconButton>
                </Box>
              )
            }
          >
            <MenuItem value={0}>
              <i>All columns</i>
            </MenuItem>
            {templates.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                <Span>{t.name}</Span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isInEditMode && <Button onClick={() => setIsInEditMode(true)} label='Edit' />}
      </div>

      {isInEditMode && (
        <ButtonsContainer>
          <Button
            onClick={() => {
              const isEqual = _.isEqual(initialTemplate.columns, template.columns)
              if (!isEqual) {
                setExitOnSubmit(true)
                setChangeTemplate(template)
              } else {
                setIsInEditMode(false)
              }
            }}
            label='Back'
          />
          <Button label='Save' onClick={(e) => setMenuAnchor(e.currentTarget)} />
        </ButtonsContainer>
      )}

      <TemplateDialog
        header='Create new template'
        isOpen={saveAsDialogOpen}
        defaultName='New Template'
        onCancel={() => setSaveAsDialogOpen(false)}
        onSubmit={handleTemplateSaveAs}
        templateNames={templates.map((t) => t.name)}
      />

      <TemplateDialog
        header='Update template name'
        isOpen={isNameEditing}
        defaultName={template.name}
        onCancel={() => setNameIsEditing(false)}
        onSubmit={(name) => {
          const newTemplate = { ...template, name }
          setNameIsEditing(false)

          // external
          onTemplateSave(newTemplate)
        }}
        templateNames={templates.map((t) => t.name)}
      />

      <ConfirmationDialog
        isOpen={Boolean(changeTemplate)}
        onCloseDialog={() => {
          setChangeTemplate(null)
        }}
        showCloseIcon
        onSubmit={() => {
          if (!template.id) {
            setChangeTemplate(null)
            setSaveAsDialogOpen(true)
            return
          }

          handleTemplateSave()
          setChangeTemplate(null)

          if (exitOnSubmit) {
            setExitOnSubmit(false)
            setIsInEditMode(false)
          }
        }}
        onReject={() => {
          const templateToSet = exitOnSubmit ? initialTemplate : changeTemplate
          setChangeTemplate(null)
          if (exitOnSubmit) {
            setExitOnSubmit(false)
            setIsInEditMode(false)
          }
          onTemplateSet({ ...templateToSet })
        }}
        title='The template has been changed. Do you want to save the changes?'
        submitButtonText='Save'
        cancelButtonText="Don't save"
      />

      <ConfirmationDialog
        isOpen={isDeleteTemplateConfirmationOpen}
        onCloseDialog={() => setIsDeleteTemplateConfirmationOpen(false)}
        onSubmit={() => {
          if (onTemplateDelete) {
            onTemplateDelete(template)
          }
          setIsDeleteTemplateConfirmationOpen(false)
        }}
        onReject={() => {
          setIsDeleteTemplateConfirmationOpen(false)
        }}
        title={`Are you sure you want to delete template "${template.name}"? It cannot be restored`}
        submitButtonText='Yes'
        cancelButtonText='Cancel'
      />

      <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)}>
        <MenuItem
          onClick={() => {
            handleTemplateSave()
            setMenuAnchor(null)
          }}
          disabled={!template.id}
        >
          <Span>Save</Span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSaveAsDialogOpen(true)
            setMenuAnchor(null)
          }}
        >
          <Span>Save As</Span>
        </MenuItem>
      </Menu>
    </ToolbarContainer>
  )
}

Toolbar.propTypes = {
  setIsInEditMode: PropTypes.func.isRequired,
  onTemplateSave: PropTypes.func,
  onTemplateAdd: PropTypes.func,
  onTemplateSet: PropTypes.func,
  onTemplateDelete: PropTypes.func,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          sorting: PropTypes.shape({
            direction: PropTypes.number,
            priority: PropTypes.number,
          }),
          filter: PropTypes.shape({ operation: PropTypes.number, value: PropTypes.string }),
          grouping: PropTypes.number,
        })
      ),
    })
  ),
  columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isInEditMode: PropTypes.bool.isRequired,
}

export default Toolbar
