import { useState, memo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@material-ui/core'

import { completeReview } from '@tabeeb/modules/contentReviews/actions'
import { ConfirmationDialog } from '@tabeeb/modules/shared/dialogs'
import { getFormButtonState } from '@tabeeb/modules/forms/selectors'
import { getMergeReviewWithCurrentForm } from '@tabeeb/modules/contentReviews/selectors'
import { ContentSharingType } from '@tabeeb/enums'
import { FormButtons } from '../../services/enums'

const CompleteReviewButton = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const dispatch = useDispatch()

  const { isDisabled } = useSelector((state) => getFormButtonState(state, { button: FormButtons.CompleteReview }))
  const currentFormReview = useSelector(getMergeReviewWithCurrentForm)

  const isMergeForReview = currentFormReview?.Type === ContentSharingType.MergeForReview

  const handleCompleteReview = (isCopyRequired) => {
    dispatch(completeReview(isCopyRequired))
    setIsDialogOpen(false)
  }

  const _handleClick = () => {
    setIsDialogOpen(true)
  }

  const dialogProps = {
    onCloseDialog: () => setIsDialogOpen(false),
    onSubmit: () => handleCompleteReview(true),
    onReject: () => handleCompleteReview(false),
    isOpen: isDialogOpen,
    title: 'Complete Review',
    dialogMessage:
      'Once complete this review, you will no longer be able to send ' +
      'feedback. Do you want a copy of this session to be created and added to your sessions ' +
      'page?',
    showCloseIcon: true,
    submitButtonText: 'Copy',
    cancelButtonText: "Don't copy",
  }

  const mergeReviewDialogProps = {
    onCloseDialog: () => setIsDialogOpen(false),
    onSubmit: () => handleCompleteReview(false),
    onReject: () => setIsDialogOpen(false),
    isOpen: isDialogOpen,
    title: 'Complete Review',
    dialogMessage:
      'Once this review is complete, you will no longer be able to send feedback. Please confirm to complete this review',
    showCloseIcon: true,
    submitButtonText: 'Complete',
    cancelButtonText: 'Close',
  }

  return (
    <>
      <Button onClick={_handleClick} variant='contained' color='primary' className='form-button' disabled={isDisabled}>
        complete review
      </Button>
      <ConfirmationDialog {...(isMergeForReview ? mergeReviewDialogProps : dialogProps)} />
    </>
  )
}

export default memo(CompleteReviewButton)
