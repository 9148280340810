import { InputAdornment, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'

const EndAdornmentRemove = ({ onClick }) => {
  const handleMouseDown = (event) => {
    event.preventDefault()
  }

  return (
    <InputAdornment position='end'>
      <IconButton onClick={onClick} onMouseDown={handleMouseDown} edge='end'>
        <CloseIcon />
      </IconButton>
    </InputAdornment>
  )
}

EndAdornmentRemove.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default EndAdornmentRemove
