import PropTypes from 'prop-types'
import { Assessment, Delete, Edit, ListAlt, Link } from '@material-ui/icons'
import { Box, IconButton, Typography, withStyles } from '@material-ui/core'

import AudioPlayer from '@tabeeb/modules/shared/audioPlayer'
import UserAvatar from '@tabeeb/modules/shared/userAvatar'
import { TextWithLinks } from '@tabeeb/modules/shared/textWithLinks'

import styles from './styles'
import './rhap.less'

function convertDateToString(dateTime) {
  const date = new Date(dateTime)
  const now = new Date()
  if (now.toDateString() === date.toDateString()) {
    return `${date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    })}, today`
  }
  return date.toLocaleDateString()
}

const Message = ({
  classes,
  text,
  url,
  user,
  createdOn,
  canEditObject,
  canEditProperties,
  canSeeReport,
  handleDelete,
  handleEditing,
  handleEditAIObjectProperties,
  handleOpenAIObjectReport,
  handleEditConnections,
}) => {
  return (
    <Box className={classes.container}>
      <UserAvatar className={classes.avatar} user={user} />
      <div className={classes.content}>
        <Typography variant='body2' className={classes.name}>
          {user.displayName}
        </Typography>
        {text ? (
          <TextWithLinks>
            <Typography className={classes.text} variant='body2'>
              {text}
            </Typography>
          </TextWithLinks>
        ) : (
          <AudioPlayer url={url} />
        )}
        <Typography className={classes.time}>Posted on {convertDateToString(createdOn)}</Typography>
      </div>
      {canSeeReport && (
        <IconButton title='Show report' size='small' onClick={handleOpenAIObjectReport}>
          <Assessment fontSize='small' />
        </IconButton>
      )}
      {canEditProperties && (
        <IconButton title='Edit properties' size='small' onClick={handleEditAIObjectProperties}>
          <ListAlt fontSize='small' />
        </IconButton>
      )}
      {canEditProperties && (
        <IconButton title='Edit connections' size='small' onClick={handleEditConnections}>
          <Link fontSize='small' />
        </IconButton>
      )}
      {canEditObject && (
        <IconButton title='Edit AI object' size='small' onClick={handleEditing}>
          <Edit fontSize='small' />
        </IconButton>
      )}
      <IconButton title='Delete message' size='small' onClick={handleDelete}>
        <Delete fontSize='small' />
      </IconButton>
    </Box>
  )
}

Message.propTypes = {
  classes: PropTypes.shape({
    name: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.string.isRequired,
  createdOn: PropTypes.string,
  canEditObject: PropTypes.bool.isRequired,
  canEditProperties: PropTypes.bool.isRequired,
  canSeeReport: PropTypes.bool.isRequired,
  url: PropTypes.string,
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEditing: PropTypes.func.isRequired,
  handleEditAIObjectProperties: PropTypes.func.isRequired,
  handleOpenAIObjectReport: PropTypes.func.isRequired,
  handleEditConnections: PropTypes.func.isRequired,
}

export default withStyles(styles)(Message)
