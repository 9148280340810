import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button, DialogContent, DialogActions, Tooltip } from '@material-ui/core'
import { ConfirmationDialog } from 'modules/shared/dialogs'
import isEqual from 'react-fast-compare'
import * as remindersActions from '../../actions'
import * as remindersSelectors from '../../selectors'

import ReminderUsers from '../ReminderUsers'
import ReminderDate from '../ReminderDate'
import ReminderRepeat from '../ReminderRepeat'

import { getReminderErrors } from '../../services/reminderErrors'

import './styles.less'

const MainSettings = ({
  currentReminder,
  initialReminderState,
  onCloseDialog,
  actions: { createReminder, updateReminderRequest, setExistingReminder, setNewReminder },
  isEditMode = false,
}) => {
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false)
  const isEqualSettings = isEqual(initialReminderState, currentReminder)

  const _setReminder = (currentReminder) => {
    if (isEditMode) setExistingReminder(currentReminder)
    else setNewReminder(currentReminder)
  }

  if (!currentReminder) return null

  const errors = getReminderErrors(currentReminder)
  const props = { currentReminder, setReminderSettings: _setReminder, errors }

  const _handleSaveBtnClick = () => {
    if (isEditMode) updateReminderRequest(currentReminder)
    else createReminder(currentReminder)

    onCloseDialog()
  }

  const _handleCloseBtnClick = () => {
    if (isEqualSettings) onCloseDialog()
    else setIsDiscardDialogOpen(true)
  }

  const _handleDiscardChanges = () => {
    setIsDiscardDialogOpen(false)
    onCloseDialog()
  }

  const discardDialogProps = {
    onCloseDialog: () => setIsDiscardDialogOpen(false),
    onSubmit: _handleDiscardChanges,
    isOpen: isDiscardDialogOpen,
    title: 'Discard unsaved changes?',
    submitButtonText: 'Discard',
  }

  return (
    <>
      <DialogContent className='reminder-dialog-content'>
        <div className='reminder-main-settings'>
          <ReminderUsers {...props} />
          <ReminderDate {...props} />
          <ReminderRepeat {...props} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={_handleCloseBtnClick} color='default' variant='outlined'>
          {isEditMode ? 'Back' : 'Close'}
        </Button>

        <Tooltip
          placement='top'
          arrow
          title={
            errors.length > 0 ? (
              <>
                {errors.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </>
            ) : (
              ''
            )
          }
        >
          <span>
            <Button
              onClick={_handleSaveBtnClick}
              color='primary'
              disabled={errors.length > 0 || isEqualSettings}
              variant='contained'
            >
              Save
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
      <ConfirmationDialog {...discardDialogProps} />
    </>
  )
}

MainSettings.propTypes = {
  currentReminder: PropTypes.object,
  initialReminderState: PropTypes.object.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  actions: PropTypes.shape({
    createReminder: PropTypes.func.isRequired,
    updateReminderRequest: PropTypes.func.isRequired,
    setExistingReminder: PropTypes.func.isRequired,
    setNewReminder: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state, { isEditMode, defaultReminder }) => {
  const {
    reminders: { newReminder, existingReminder },
  } = state

  return {
    currentReminder: isEditMode ? existingReminder : newReminder,
    initialReminderState: isEditMode
      ? remindersSelectors.getReminderById(state, { Id: existingReminder.Id })
      : defaultReminder,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(remindersActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSettings)
