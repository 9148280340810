export default (theme) => {
  return {
    container: {
      position: 'absolute',
      background: theme.palette.action.hover,
      cursor: 'move',
      top: 0,
      right: 0,
      left: 0,

      transition: 'visibility 0.2s, opacity 0.2s linear',
      visibility: 'none',
      opacity: 0,
      padding: theme.spacing(),

      '&:hover': {
        visibility: 'visible',
        opacity: 1,
      },
    },
    bar: {
      width: '100%',
      background: theme.palette.getContrastText(theme.palette.action.hover),
      height: 2,
    },
  }
}
