import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from '../actions'

import ContentViewer from '../components/ContentViewer'
import { gallerySelectors } from '../../gallery'
import {
  isVideoPage,
  isPdfPage,
  isImagePage,
  isSpatialViewPage,
  isDrawingBoardPage,
  isDocumentPage,
  isAutodeskPage,
  isWebPage,
  isFilePage,
  isPointCloudPage,
} from '../../../services/pageService'
import * as annotationsActions from '../../annotations/actions'

class ContentViewerContainer extends Component {
  componentWillUnmount() {
    const {
      actions: { resetContentViewerState },
      annotationsActions: { resetAnnotationsState },
    } = this.props

    resetContentViewerState()
    resetAnnotationsState()
  }

  contentRef = (el) => {
    const { contentSize } = this.props
    if (!contentSize.height) {
      this.updateContent(el.height, el.width)
    }
  }

  _handleUpdateContainer = (width, height) => {
    const {
      containerSize,
      actions: { setContainerSize },
    } = this.props

    if (height !== containerSize.height || width !== containerSize.width) {
      setContainerSize({ width, height })
    }
  }

  updateContent = (height, width) => {
    const {
      contentSize,
      actions: { setContentSize },
    } = this.props

    if (height !== contentSize.height || width !== contentSize.width) {
      setContentSize({ width, height })
    }
  }

  render() {
    const {
      contentSize,
      containerSize,
      playerScale,
      isSelectionEnabled,
      isLoading,
      isWebPage,
      isPdfPage,
      isVideoPage,
      isImagePage,
      isSpatialViewPage,
      isDocumentPage,
      isAutodeskPage,
      isDrawingBoardPage,
      isDragAvailable,
      isFilePage,
      isPointCloudPage,
    } = this.props

    const props = {
      contentSize,
      containerSize,
      playerScale,
      isLoading,
      isWebPage,
      isPdfPage,
      isVideoPage,
      isImagePage,
      isSpatialViewPage,
      isDocumentPage,
      isAutodeskPage,
      isDrawingBoardPage,
      isFilePage,
      isPointCloudPage,
      isDragAvailable,
      isSelectionEnabled,
      contentRef: this.contentRef,
      handleUpdateContainer: this._handleUpdateContainer,
    }

    return <ContentViewer {...props} />
  }
}

ContentViewerContainer.propTypes = {
  playerScale: PropTypes.number.isRequired,
  contentSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  containerSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  actions: PropTypes.shape({
    setContentSize: PropTypes.func.isRequired,
    setContainerSize: PropTypes.func.isRequired,
    resetContentViewerState: PropTypes.func.isRequired,
  }),
  annotationsActions: PropTypes.shape({
    resetAnnotationsState: PropTypes.func.isRequired,
  }),
  isSelectionEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isWebPage: PropTypes.bool.isRequired,
  isPdfPage: PropTypes.bool.isRequired,
  isVideoPage: PropTypes.bool.isRequired,
  isImagePage: PropTypes.bool.isRequired,
  isSpatialViewPage: PropTypes.bool.isRequired,
  isDocumentPage: PropTypes.bool.isRequired,
  isDrawingBoardPage: PropTypes.bool.isRequired,
  isFilePage: PropTypes.bool.isRequired,
  isDragAvailable: PropTypes.bool.isRequired,
  isPointCloudPage: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const {
    contentViewer: { isLoading, contentSize, containerSize },
    playerToolbar: { scale: playerScale, drawing },
  } = state

  const selectedGalleryItem = gallerySelectors.getSelectedGalleryItem(state)
  const contentType = selectedGalleryItem ? selectedGalleryItem.contentType : undefined

  return {
    playerScale: playerScale / 100,
    contentSize,
    containerSize,
    isWebPage: isWebPage(contentType),
    isPdfPage: isPdfPage(contentType),
    isVideoPage: isVideoPage(contentType),
    isImagePage: isImagePage(contentType),
    isSpatialViewPage: isSpatialViewPage(contentType),
    isDocumentPage: isDocumentPage(contentType),
    isAutodeskPage: isAutodeskPage(contentType),
    isDrawingBoardPage: isDrawingBoardPage(contentType),
    isFilePage: isFilePage(contentType),
    isPointCloudPage: isPointCloudPage(contentType),
    isLoading,
    isDragAvailable: !drawing.drawingState.isDrawing && !drawing.drawingState.isSelecting,
    isSelectionEnabled: drawing.drawingState.isSelecting,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    annotationsActions: bindActionCreators(annotationsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentViewerContainer)
