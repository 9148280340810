import { ConfirmationDialog } from '@tabeeb/modules/shared/dialogs'
import { useDispatch, useSelector } from 'react-redux'
import { fallbackUser } from '@tabeeb/shared/users/common'
import * as presentationSelectors from '@tabeeb/modules/presentation/selectors'
import { getRecordingTypeDialogResult } from '@tabeeb/modules/whiteboard/selectors'
import { getUserById } from '../../../../users/selectors'
import { getIsSwitchRecordedUserConfirmationDialogOpen, getSelectedUserId } from '../../selectors'
import * as actions from '../../actions'

const SwitchRecordedUserConfirmationDialog = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(getIsSwitchRecordedUserConfirmationDialogOpen)

  const recordingType = useSelector(getRecordingTypeDialogResult)
  const recordedUserId = useSelector(getSelectedUserId)
  const recordedUser = useSelector((state) => getUserById(state, { Id: recordedUserId }) || fallbackUser)
  const selectedUser = useSelector(presentationSelectors.getSelectedUser)
  const recordingModel = useSelector((state) => ({
    contentId: state.contentState.contentId,
    serverName: state.recording.serverName,
    recordId: state.recording.recordId,
    selectedUserId: state.recording.selectedUserId,
  }))

  const handleContinueClick = () => {
    dispatch(actions.onRecordingContinuing(recordingModel))
    dispatch(actions.closeSwitchRecordedUserConfirmationDialog())
  }

  const handleStartNewClick = () => {
    dispatch(actions.onRecordingStopAndStart({ selectedUserId: selectedUser.id, recordingType }))
    dispatch(actions.closeSwitchRecordedUserConfirmationDialog())
  }

  const handleCloseClick = () => {
    dispatch(actions.closeSwitchRecordedUserConfirmationDialog())
  }

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      showCloseIcon
      onSubmit={handleContinueClick}
      onReject={handleStartNewClick}
      onCloseDialog={handleCloseClick}
      title='Switch recorded user'
      submitButtonText='Continue'
      cancelButtonText='Start new'
      dialogMessage={`You have been recording ${recordedUser?.displayName}. Do you want to continue recording ${recordedUser?.displayName} or start a new recoding of ${selectedUser?.displayName} instead?`}
    />
  )
}

export default SwitchRecordedUserConfirmationDialog
