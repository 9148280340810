import { combineActions, handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = {
  ftpHost: '',
  ftpUser: '',
  ftpPassword: '',
}

export default handleActions(
  {
    [combineActions(actions.getFtpServerSettingsSuccess, actions.updateFtpServerSettingsSuccess)](state, action) {
      const resp = action.response.data
      return { ftpHost: resp.FtpHost, ftpUser: resp.FtpUserName, ftpPassword: resp.FtpPassword }
    },
  },
  defaultState
)
