import { put, takeLatest, all } from 'redux-saga/effects'

import * as notificationActions from '../../notification/actions'
import * as tenantConfigActions from '../actions'

function* onGetFtpServerSettingsFailed({ response }) {
  if (response.status === 404) {
    return // do not show an error if settings are not configured
  }
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to load the FTP settings' }))
}

function* getFtpServerSettings() {
  yield all([takeLatest(tenantConfigActions.getFtpServerSettingsFailed, onGetFtpServerSettingsFailed)])
}

export default getFtpServerSettings
