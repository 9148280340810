import PropTypes from 'prop-types'

import Linkify from 'react-linkify'

const linkDecorator = (decoratedHref, decoratedText, key) => (
  <a target='_blank' href={decoratedHref} key={key} rel='noreferrer'>
    {decoratedText}
  </a>
)

const TextWithLinks = ({ children }) => {
  return <Linkify componentDecorator={linkDecorator}>{children}</Linkify>
}

TextWithLinks.propTypes = {
  children: PropTypes.any,
}

export default TextWithLinks
