import { styled } from '@mui/material'

export const EditorContainer = styled('div')(({ theme }) => {
  return {
    height: '100%',
    display: 'flex',
    gap: theme.spacing(2),

    '& ::-webkit-scrollbar': {
      width: 5,
      height: 5,
    },
  }
})

export const TemplateContainer = styled('div')(({ theme }) => {
  return {
    display: 'grid',
    gridTemplateRows: '1fr 80px',
    height: '100%',
    width: '100%',
  }
})

export const ColumnPickerContainer = styled('div')(({ theme }) => {
  return {
    width: 220,
    border: '1px solid rgb(224, 224, 224)',
  }
})
