import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPowerBIReportModalUserState } from '@tabeeb/modules/powerBIReports/selectors'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import { closePowerBIReportUserModal, createPowerBIReportUser } from '@tabeeb/modules/powerBIReports/actions'
import { Add, Close } from '@mui/icons-material'
import { FetchStatus } from '@tabeeb/enums'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { GUIDRegexp } from '@tabeeb/modules/shared/constants'
import { ReportUserAccessType, ReportUserAccessTypeNames } from '@tabeeb/modules/powerBIReports/enums'
import UserSearch from '@tabeeb/modules/shared/UserSearch'
import { useDebouncedValue } from '@tabeeb/modules/shared/utils/hooks'
import { UserInfo } from '@tabeeb/uikit'
import UserTenantsSelect from '@tabeeb/modules/shared/uikit/components/UserTenantsSelect'
import { useUsers } from '../../../../../users/hooks'
import ReportUserAccessTypeSelect from './ReportUserAccessTypeSelect'

const PowerBIReportUserModal = () => {
  const dispatch = useDispatch()

  const [usersSearch, setUsersSearch] = useState('')
  const debouncedUsersSearch = useDebouncedValue(usersSearch, 300)

  const { loading: usersLoading, users } = useUsers({ search: debouncedUsersSearch })
  const { open, report, fetchStatus } = useSelector(getPowerBIReportModalUserState)

  const loading = useMemo(() => {
    return fetchStatus === FetchStatus.Loading
  }, [fetchStatus])

  const onSubmit = useCallback(
    (values) => {
      const requestPayload = {
        reportId: report.Id,
        securityModels: [report.SecurityModel.Id],
        userIdentityId: values.userIdentityId,
        accessType: values.accessType,
        tenantId: values.tenantId,
      }
      dispatch(createPowerBIReportUser.request(requestPayload))
    },
    [dispatch, report.Id, report.SecurityModel.Id]
  )

  const form = useFormik({
    initialValues: {
      userIdentityId: '',
      tenantId: null,
      accessType: ReportUserAccessType.Identity,
    },
    validationSchema: Yup.object({
      userIdentityId: Yup.string().required().matches(GUIDRegexp, 'Must be a valid GUID.'),
      accessType: Yup.number().oneOf([ReportUserAccessType.Identity, ReportUserAccessType.Profile]),
      tenantId: Yup.number()
        .nullable(true)
        .when('accessType', {
          is: (accessType) => accessType === ReportUserAccessType.Profile,
          then: (schema) => schema.min(1, 'Tenant is required'),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
    onSubmit,
    enableReinitialize: true,
  })

  const handleClose = useCallback(
    (_, reason) => {
      if (reason === 'backdropClick') {
        return
      }
      form.resetForm()
      dispatch(closePowerBIReportUserModal())
    },
    [dispatch, form]
  )

  useEffect(() => {
    if (fetchStatus === FetchStatus.Loaded) {
      handleClose()
    }
  }, [fetchStatus, handleClose])

  const onAccessTypeChange = useCallback(
    (_event, value) => {
      form.setValues((values) => ({ ...values, accessType: value.accessType }))
    },
    [form]
  )

  const onUserChange = useCallback(
    (_event, value) => {
      form.setValues((values) => ({ ...values, userIdentityId: value?.IdentityGuid }))
    },
    [form]
  )

  const onTenantChange = useCallback(
    (_event, value) => {
      form.setValues((values) => ({ ...values, tenantId: value.tenantId }))
    },
    [form]
  )

  const handleUsersSeachChange = useCallback((_event, value) => {
    setUsersSearch(value)
  }, [])

  const submitButtonStartIcon = useMemo(() => {
    if (loading) {
      return <CircularProgress size={18} />
    }
    return loading ? <CircularProgress size={18} /> : <Add color='primary' />
  }, [loading])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle>Add PowerBI report user</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 12,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Grid container component='form' autoComplete='off' flexDirection='column' spacing={1}>
          <Grid item xs={12}>
            <UserSearch
              options={users}
              loading={usersLoading}
              InputProps={{
                variant: 'standard',
                label: 'Enter name or email',
                placeholder: 'Enter name or email...',
                required: true,
              }}
              AutocompleteProps={{
                multiple: false,
                onInputChange: handleUsersSeachChange,
                onChange: onUserChange,
                getOptionLabel: (option) => option.Email,
                renderOption: (option) => <UserInfo key={option.Id} item={option} />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ReportUserAccessTypeSelect
              defaultValue={{
                label: ReportUserAccessTypeNames[form.values.accessType],
                accessType: form.values.accessType,
              }}
              onChange={onAccessTypeChange}
            />
          </Grid>
          {form.values.accessType === ReportUserAccessType.Profile && (
            <Grid item xs={12}>
              <UserTenantsSelect
                userId={form.values.userIdentityId}
                disabled={!form.values.userIdentityId}
                onChange={onTenantChange}
                helperText={form.errors.tenantId}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          color='primary'
          startIcon={submitButtonStartIcon}
          disabled={!form.isValid || !form.dirty || loading}
          onClick={form.handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PowerBIReportUserModal
