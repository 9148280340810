import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { ConfirmationDialog } from '@tabeeb/shared/dialogs'
import { deleteFolder } from '@tabeeb/modules/sessionsPage/actions'

const DeleteFolderDialog = ({ open, folder, onClose }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(() => {
    dispatch(deleteFolder.request(folder.Id))

    onClose()
  }, [dispatch, folder.Id, onClose])

  return (
    <ConfirmationDialog
      isOpen={open}
      title='Delete folder'
      dialogMessage='Are you sure you want to delete this folder?'
      onCloseDialog={onClose}
      onSubmit={onSubmit}
      cancelButtonText='No'
      submitButtonText='Yes'
    />
  )
}

DeleteFolderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  folder: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(DeleteFolderDialog)
