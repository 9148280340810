export default (theme) => {
  return {
    help: {
      display: 'inline',
      position: 'relative',
      top: '1px',
      left: '6px',
      color: '#85c8eb',
      cursor: 'help',
    },
  }
}
