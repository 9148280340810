import { Draggable, Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { useEffect, useRef } from 'react'

const useDraggableInPortal = () => {
  const self = useRef({}).current

  useEffect(() => {
    const div = document.createElement('div')
    div.style.position = 'absolute'
    div.style.pointerEvents = 'none'
    div.style.top = '0'
    div.style.width = '100%'
    div.style.height = '100%'
    self.elt = div
    document.body.appendChild(div)
    return () => {
      document.body.removeChild(div)
    }
  }, [self])

  return (render) =>
    (provided, ...args) => {
      const element = render(provided, ...args)
      if (provided.draggableProps?.style?.position === 'fixed') {
        return createPortal(element, self.elt)
      }
      return element
    }
}

const DragNDropList = ({ values, droppableId, renderValue, valueIdGetter, noItemsLabel, ListComponent }) => {
  const renderDraggable = useDraggableInPortal()

  return (
    <ListComponent>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} style={{ height: '100%' }}>
            {values?.length
              ? values.map((value, index) => {
                  return (
                    <Draggable key={valueIdGetter(value)} draggableId={valueIdGetter(value)} index={index}>
                      {renderDraggable((draggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          {renderValue(value, draggableProvided)}
                        </div>
                      ))}
                    </Draggable>
                  )
                })
              : noItemsLabel}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </ListComponent>
  )
}

DragNDropList.propTypes = {
  droppableId: PropTypes.string.isRequired,
  noItemsLabel: PropTypes.element.isRequired,
  ListComponent: PropTypes.element.isRequired,
  renderValue: PropTypes.func.isRequired,
  valueIdGetter: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Array).isRequired,
}

export default DragNDropList
