import PropTypes from 'prop-types'

import { Close } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'

import { TextWithLinks } from '@tabeeb/modules/shared/textWithLinks'

const ConfirmationDialog = ({
  onCloseDialog,
  onSubmit,
  isOpen,
  title,
  dialogMessage,
  cancelButtonText,
  submitButtonText,
  submitButtonColor,
  showSubmitButton,
  showCancelButton,
  showCloseIcon,
  onReject,
}) => {
  const handleDialogClick = (e) => e.stopPropagation()

  return (
    <Dialog open={isOpen} maxWidth='sm' fullWidth onClose={onCloseDialog} onClick={handleDialogClick}>
      <DialogTitle sx={{ paddingRight: showCloseIcon ? (theme) => theme.spacing(7) : undefined }}>{title}</DialogTitle>

      {showCloseIcon && (
        <IconButton
          sx={{ position: 'absolute', top: (theme) => theme.spacing(1.5), right: (theme) => theme.spacing(2.5) }}
          onClick={onCloseDialog}
        >
          <Close />
        </IconButton>
      )}

      <DialogContent>
        {dialogMessage && (
          <TextWithLinks>
            <DialogContentText>{dialogMessage}</DialogContentText>
          </TextWithLinks>
        )}
      </DialogContent>
      <DialogActions>
        {showCancelButton && <Button onClick={onReject || onCloseDialog}>{cancelButtonText}</Button>}
        {showSubmitButton && (
          <Button onClick={onSubmit} color={submitButtonColor} variant='contained'>
            {submitButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dialogMessage: PropTypes.string,
  title: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string,
  submitButtonColor: PropTypes.oneOf(['error', 'primary', 'secondary', 'success', 'warning', 'info']),
  cancelButtonText: PropTypes.string,
  showSubmitButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  onReject: PropTypes.func,
}

ConfirmationDialog.defaultProps = {
  dialogMessage: '',
  submitButtonText: 'Ok',
  submitButtonColor: 'primary',
  cancelButtonText: 'Cancel',
  showSubmitButton: true,
  showCancelButton: true,
  showCloseIcon: false,
}

export default ConfirmationDialog
