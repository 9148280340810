import { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { ContentState } from '@tabeeb/enums'
import { ConfirmationDialog } from '@tabeeb/shared/dialogs'
import { sessionTitleFormatter } from '@tabeeb/modules/shared/utils/text'

import { activateSession } from '@tabeeb/modules/sessionsPage/actions'
import { getIsContentOwner, getIsNeedAdminPermission } from '../../../selectors'

const ActivateSessionDialog = ({ open, session, onClose }) => {
  const dispatch = useDispatch()

  const isContentOwner = useSelector((state) => getIsContentOwner(state, session))
  const isNeedAdminPermission = useSelector((state) => getIsNeedAdminPermission(state, { session }))

  const onSubmit = useCallback(() => {
    dispatch(activateSession.request({ contentId: session.Id, needPermission: isNeedAdminPermission }))

    onClose()
  }, [dispatch, isNeedAdminPermission, onClose, session?.Id])

  const isPending = session.ContentStatus === ContentState.Pending

  const message = useMemo(() => {
    const pendingDialogMessage = `Request to activate has been already sent. Please wait for Admin's resolution`

    const ownerDialogMessage = isNeedAdminPermission
      ? `The time has expired. Would you like to reactivate? You need permission from the admin to activate a session. 
      Please click Continue to proceed, or Cancel`
      : `The time has expired. Would you like to reactivate?`

    const dialogMessage = isPending
      ? pendingDialogMessage
      : isContentOwner
      ? ownerDialogMessage
      : `${session.Description} is currently locked. If you need access, ask ${
          session.Owner ? session.Owner.Name : 'Session owner'
        } to activate it.`

    return dialogMessage
  }, [isContentOwner, isNeedAdminPermission, isPending, session.Description, session.Owner])

  return (
    <ConfirmationDialog
      isOpen={open}
      title={sessionTitleFormatter.format('Session is expired')}
      onSubmit={onSubmit}
      onCloseDialog={onClose}
      dialogMessage={sessionTitleFormatter.format(message)}
      submitButtonText='Continue'
      showSubmitButton={isContentOwner && !isPending}
      cancelButtonText={isContentOwner && !isPending ? 'Cancel' : 'Close'}
    />
  )
}

ActivateSessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Description: PropTypes.string.isRequired,
    ContentStatus: PropTypes.oneOf(Object.values(ContentState)).isRequired,
    Owner: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(ActivateSessionDialog)
