import { memo, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Editor } from '@tinymce/tinymce-react'

import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import './styles.less'

/** @typedef {{
    value: String,
    uploadsHandler: ({ file: File, onProgress: Function }) => Promise<String>,
    onChange: (content: String) => void,
 }} RichTextEditorProps */

/**
 * @param {RichTextEditorProps} props
 */
const RichTextEditor = ({ disabled, value, uploadsHandler, onChange }) => {
  const dispatch = useDispatch()
  const editorRef = useRef()

  const mediaUploadHandler = useCallback(
    (onSuccess) => {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*, video/*')

      input.onchange = async (event) => {
        try {
          const file = event.target.files[0]

          const url = await uploadsHandler({ file })

          onSuccess(url, { text: file.name })
        } catch {
          dispatch(onAddErrorNotification({ message: 'Failed to upload media' }))
        }
      }
      input.click()
    },
    [dispatch, uploadsHandler]
  )

  return (
    <Editor
      ref={editorRef}
      disabled={disabled}
      value={value}
      apiKey={process.env.TINY_MCE_API_KEY}
      init={{
        file_picker_callback: mediaUploadHandler,
        width: '100%',
        height: '100%',
        menubar: 'edit view insert format table',
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'code',
          'insertdatetime',
          'media',
          'table',
          'code',
          'wordcount',
        ],
        toolbar:
          // eslint-disable-next-line no-multi-str
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat',
      }}
      onEditorChange={onChange}
    />
  )
}

RichTextEditor.defaultProps = {
  disabled: false,
  value: '',
  uploadsHandler: undefined,
  onChange: () => {},
}

RichTextEditor.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  uploadsHandler: PropTypes.func,
  onChange: PropTypes.func,
}

export default memo(RichTextEditor)
