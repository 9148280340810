import PropTypes from 'prop-types'
import classNames from 'classnames'

import HelpText from '../HelpText'
import { configFieldHelpText } from '../../services/enums'
import './styles.less'

const ConfigSetting = ({ name, label, children }) => {
  const helpText = configFieldHelpText[name]

  return (
    <div className='tenant-settings-control'>
      <div className='tenant-settings-label'>
        <label className={classNames({ 'with-help-text': helpText })}>{label}</label>
        <HelpText helpText={helpText} />
      </div>
      <div className='tenant-settings-input'>{children}</div>
    </div>
  )
}

ConfigSetting.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default ConfigSetting
