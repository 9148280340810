import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button } from '@material-ui/core'
import { getConfigForUpdateSubdomain } from '../../tenantConfigPage/selectors'
import * as tenantFormsActions from '../actions'
import TenantFormItem from '../components/TenantFormItem'
import AttachTenantFormsDialogContainer from './AttachTenantFormsDialogContainer'
import ConfigTextField from '../../tenantConfigPage/components/ConfigTextField'
import ConfigSetting from '../../tenantConfigPage/components/ConfigSetting'

class TenantFormsContainer extends Component {
  componentDidMount() {
    const {
      actions: { getTenantFormsRequest },
      subdomain,
    } = this.props

    getTenantFormsRequest(subdomain)
  }

  handleDetachFormFromTenant = (tenantFormId) => {
    this.props.actions.detachFormFromTenantRequest(tenantFormId)
  }

  handleAddFormButtonClick = () => {
    this.props.actions.openAvailableFormsForTenantDialog()
  }

  render() {
    return (
      <>
        <ConfigSetting label='Session Naming Convention Form'>
          {this.props.tenantForms.length > 0 ? (
            this.props.tenantForms.map((tenantForm) => (
              <TenantFormItem
                key={tenantForm.Id}
                form={tenantForm}
                detachFormFromTenant={this.handleDetachFormFromTenant}
              />
            ))
          ) : (
            <div>
              <ConfigTextField disabled value='No forms added' />
              <Button type='button' variant='contained' color='primary' onClick={this.handleAddFormButtonClick}>
                Add Form
              </Button>
            </div>
          )}
        </ConfigSetting>
        <AttachTenantFormsDialogContainer />
      </>
    )
  }
}

TenantFormsContainer.propTypes = {
  tenantForms: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    detachFormFromTenantRequest: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => ({
  tenantForms: state.tenantForms.tenantForms,
  subdomain: getConfigForUpdateSubdomain(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(tenantFormsActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TenantFormsContainer)
