import { Skeleton, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'

export const TextPropertyTitle = ({ title }) => {
  return (
    <Typography variant='body2' color='text.secondary' fontWeight={500} noWrap>
      {title}
    </Typography>
  )
}

TextPropertyTitle.propTypes = {
  title: PropTypes.string.isRequired,
}
export const TextPropertyField = ({ label, primaryText }) => (
  <>
    <TextPropertyTitle title={label} />
    <Tooltip title={primaryText}>
      <Typography variant='body1' fontWeight={500} mt={0.5} noWrap>
        {primaryText}
      </Typography>
    </Tooltip>
  </>
)
TextPropertyField.propTypes = {
  label: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
}

export const SkeletonTextPropertyField = ({ label }) => (
  <>
    <TextPropertyTitle title={label} />
    <Skeleton variant='body1' />
  </>
)
SkeletonTextPropertyField.propTypes = {
  label: PropTypes.string.isRequired,
}
