import { Button, Typography, styled } from '@material-ui/core'
import { LinearProgress, styled as muiStyled } from '@mui/material'

export const FlexContainer = styled('div')(() => ({
  display: 'flex',
}))

export const ColumnFlexContainer = styled(FlexContainer)(() => ({
  flexDirection: 'column',
}))

export const DefaultButton = styled(Button)(({ theme }) => ({
  height: '40px',
  minWidth: '40px',
  width: '40px',
  borderRadius: theme.shape.borderRadius,
}))

export const OutlinedButton = styled(Button)(({ theme }) => ({
  height: '40px',
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.h6,
  border: `1px solid ${theme.palette.primary.main}`,

  '&:hover': {
    border: `1px solid ${theme.palette.accent.pressed}`,
    color: theme.palette.accent.pressed,
    backgroundColor: theme.palette.secondary.light,
  },
}))

export const FilledButton = styled(Button)(({ theme }) => ({
  height: '40px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.light,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.h6,

  '&:hover': {
    backgroundColor: theme.palette.accent.pressed,
  },
}))

export const DefaultTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
}))

export const Bold = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}))

export const BottomLinearProgress = muiStyled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
}))
