import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ContentState } from '@tabeeb/enums'
import { ConfirmationDialog } from '@tabeeb/shared/dialogs'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { getIsNeedAdminPermission } from '@tabeeb/modules/sessionsPage/selectors'
import { activateSession, restoreSession } from '@tabeeb/modules/sessionsPage/actions'

const RestoreSessionDialog = ({ open, session, onClose }) => {
  const dispatch = useDispatch()

  const isNeedAdminPermission = useSelector((state) => getIsNeedAdminPermission(state, { session }))

  const onSubmit = useCallback(() => {
    dispatch(restoreSession.request({ contentId: session.Id }))

    if (session.ContentStatus === ContentState.Rejected) {
      dispatch(activateSession.request({ contentId: session.Id, needPermission: isNeedAdminPermission }))
    }

    onClose()
  }, [dispatch, isNeedAdminPermission, onClose, session?.ContentStatus, session?.Id])

  return (
    <ConfirmationDialog
      isOpen={open}
      title={sessionTitleFormatter.format('Restore session')}
      dialogMessage={sessionTitleFormatter.format('Are you sure you want to restore this session?')}
      onCloseDialog={onClose}
      onSubmit={onSubmit}
      cancelButtonText='No'
      submitButtonText='Yes'
    />
  )
}

RestoreSessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Description: PropTypes.string.isRequired,
    ContentStatus: PropTypes.oneOf(Object.values(ContentState)).isRequired,
    Owner: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(RestoreSessionDialog)
