import { handleActions } from 'redux-actions'
import { billingSettingsActions } from '@tabeeb/modules/billingSettingsPage'
import * as sessionActions from '../actions/sessionActions'

const defaultState = {
  sessionApprovalSettings: {},
  billingSettings: {
    IsBillingEnabled: false,
  },
}

export default handleActions(
  {
    [billingSettingsActions.getBillingSettingsSuccess](state, action) {
      return { ...state, billingSettings: action.response.data }
    },
    [billingSettingsActions.getSessionApprovalSettingsSuccess](state, action) {
      return { ...state, sessionApprovalSettings: action.response.data }
    },
    [sessionActions.resetSessionState](state, action) {
      return defaultState
    },
  },
  defaultState
)
