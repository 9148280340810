import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import routes from '@tabeeb/routes'
import { push } from 'connected-react-router'
import * as rawContentActions from '@tabeeb/shared/content/actions'
import * as rawActions from '../../sessionsPage/actions'

import Logo from '../components/Logo'

class LogoContainer extends Component {
  handleClick = () => {
    const {
      location,
      switchToHomePage,
      contentActions: { leaveContent },
    } = this.props

    if (location.startsWith(routes.session)) {
      leaveContent()
    } else if (!location.startsWith(routes.home)) {
      switchToHomePage()
    }
  }

  render() {
    const { url } = this.props

    const props = {
      url,
      onClick: this.handleClick,
    }

    return <Logo {...props} />
  }
}

LogoContainer.propTypes = {
  url: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  switchToHomePage: PropTypes.func.isRequired,
  contentActions: PropTypes.shape({
    leaveContent: PropTypes.func.isRequired,
  }).isRequired,
}

function mapStateToProps(state) {
  return {
    url: state.appConfigState.logoUrl,
    location: state.router.location.pathname,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    contentActions: bindActionCreators(rawContentActions, dispatch),
    actions: bindActionCreators(rawActions, dispatch),
    switchToHomePage: () => dispatch(push(routes.home)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoContainer)
