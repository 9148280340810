import Lightbox from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import Download from 'yet-another-react-lightbox/plugins/download'
import 'yet-another-react-lightbox/styles.css'
import PropTypes from 'prop-types'
import { CloudDownload, ZoomIn, ZoomOut, Close, NavigateNext, NavigateBefore } from '@mui/icons-material'
import { useRef } from 'react'
import { IconButton } from '@mui/material'

import { useWindowSize } from '@tabeeb/shared/utils/hooks'

const defaultPlugins = { zoom: true, download: true }

const ImageViewer = ({ open, onClose, slides, plugins, customSlideRenderProps }) => {
  const ref = useRef()

  const pluginsToApply = { ...defaultPlugins, ...plugins }
  const enabledPlugins = [...(pluginsToApply.zoom ? [Zoom] : []), ...(pluginsToApply.download ? [Download] : [])]

  // custom rendering area
  const { customSlideRender, nextPageClick, previousPageClick, nextPageClickAvailable, previousPageClickAvailable } =
    customSlideRenderProps

  const { height } = useWindowSize()

  return (
    <Lightbox
      open={open}
      close={onClose}
      slides={slides}
      plugins={enabledPlugins}
      zoom={{ maxZoomPixelRatio: 10 }}
      carousel={{ finite: true }}
      controller={{ closeOnBackdropClick: true, ref }}
      download={{
        download: ({ slide, saveAs }) => {
          const fileName = new URL(slide.download.url).pathname.split('/').pop()
          saveAs(slide.download.url, fileName)
        },
      }}
      render={{
        iconDownload: () => <CloudDownload />,
        iconZoomIn: () => <ZoomIn />,
        iconZoomOut: () => <ZoomOut />,
        iconClose: () => <Close />,
        buttonPrev: () => {
          if (slides.length === 1 && !customSlideRenderProps.customSlideRender) {
            return null
          }

          if (!customSlideRenderProps.customSlideRender) {
            return (
              <IconButton
                onClick={() => ref.current?.prev()}
                sx={{ position: 'absolute', left: 0, py: `${height / 2 - 100}px`, pr: '50px' }}
                size='large'
              >
                <NavigateBefore sx={{ color: 'white' }} />
              </IconButton>
            )
          }

          // custom rendering area
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
                previousPageClick()
              }}
              sx={{
                position: 'absolute',
                left: 0,
                py: `${height / 2 - 100}px`,
                pr: '50px',
                pointerEvents: 'auto !important',
              }}
              size='large'
              disabled={!previousPageClickAvailable}
            >
              <NavigateBefore sx={{ color: 'white' }} />
            </IconButton>
          )
        },
        buttonNext: () => {
          if (slides.length === 1 && !customSlideRenderProps.customSlideRender) {
            return null
          }

          if (!customSlideRenderProps.customSlideRender) {
            return (
              <IconButton
                onClick={() => ref.current?.next()}
                sx={{
                  position: 'absolute',
                  right: 0,
                  py: `${height / 2 - 100}px`,
                  pl: '50px',
                }}
                size='large'
              >
                <NavigateNext sx={{ color: 'white' }} />
              </IconButton>
            )
          }

          // custom rendering area
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
                nextPageClick()
              }}
              sx={{
                position: 'absolute',
                right: 0,
                py: `${height / 2 - 100}px`,
                pl: '50px',
                pointerEvents: 'auto !important',
              }}
              size='large'
              disabled={!nextPageClickAvailable}
            >
              <NavigateNext sx={{ color: 'white' }} />
            </IconButton>
          )
        },
        ...(Boolean(customSlideRenderProps.customSlideRender) && { ...customSlideRender }),
      }}
    />
  )
}

ImageViewer.defaultProps = {
  customSlideRenderProps: {},
}

ImageViewer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
    })
  ),
  plugins: PropTypes.shape({
    zoom: PropTypes.bool,
    download: PropTypes.bool,
  }),
  customSlideRenderProps: PropTypes.shape({
    customSlideRender: PropTypes.shape({
      slide: PropTypes.func,
    }),
    nextPageClick: PropTypes.func,
    previousPageClick: PropTypes.func,
    nextPageClickAvailable: PropTypes.bool,
    previousPageClickAvailable: PropTypes.bool,
  }),
}

export default ImageViewer
