import { styled } from '@mui/material'

export const ToolbarContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    border: '1px solid #cccccc',
  }
})

export const ButtonsContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    gap: 16,
  }
})
