import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Chip, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import IdentityServerUserInfo from '@tabeeb/modules/shared/uikit/components/IdentityServerUserInfo'
import { getPowerBIReportProviderState } from '@tabeeb/modules/powerBIReportProviders/selectors'
import { disableReportProvider, enableReportProvider } from '@tabeeb/modules/powerBIReportProviders/actions'
import { FetchStatus } from '@tabeeb/enums'

const PowerBIProviderProperties = ({ form, provider }) => {
  const dispatch = useDispatch()

  const { providerStatusUpdateFetchStatus } = useSelector(getPowerBIReportProviderState)

  const onDisable = useCallback(() => {
    dispatch(disableReportProvider.request(provider.Id))
  }, [dispatch, provider.Id])

  const onEnable = useCallback(() => {
    dispatch(enableReportProvider.request(provider.Id))
  }, [dispatch, provider.Id])

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid container spacing={1} item xs={12} alignItems='center'>
        <Grid item xs={2}>
          <Typography fontWeight={500}>Provider name</Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            variant='outlined'
            size='small'
            name='name'
            value={form.values.name}
            helperText={form.errors.name}
            onChange={form.handleChange}
            inputProps={{ maxLength: 100 }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} item xs={12} alignItems='center'>
        <Grid item xs={2}>
          <Typography fontWeight={500}>Provider owner</Typography>
        </Grid>
        <Grid item xs={10}>
          <IdentityServerUserInfo userIdentityId={form.values.ownerId} />
        </Grid>
      </Grid>
      <Grid container spacing={1} item xs={12} alignItems='center'>
        <Grid item xs={2}>
          <Typography fontWeight={500}>Provider status</Typography>
        </Grid>
        <Grid item>
          {provider.IsAvailable ? (
            <Chip label='Enabled' color='success' variant='outlined' />
          ) : (
            <Chip label='Disabled' color='error' variant='outlined' />
          )}
        </Grid>
        <Grid item>
          <Button
            disabled={providerStatusUpdateFetchStatus === FetchStatus.Loading}
            variant='outlined'
            color='primary'
            startIcon={providerStatusUpdateFetchStatus === FetchStatus.Loading ? <CircularProgress size={18} /> : null}
            onClick={provider.IsAvailable ? onDisable : onEnable}
          >
            {provider.IsAvailable ? 'Disable' : 'Enable'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

PowerBIProviderProperties.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      name: PropTypes.string,
      ownerId: PropTypes.string,
    }),
    errors: PropTypes.shape({
      name: PropTypes.string,
    }),
    handleChange: PropTypes.func,
  }),
  provider: PropTypes.shape({
    Id: PropTypes.number,
    IsAvailable: PropTypes.bool,
  }),
}

export default PowerBIProviderProperties
