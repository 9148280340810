import { handleActions } from 'redux-actions'
import { setSelectedColumns, resetState } from '../actions'

const defaultState = []

export default handleActions(
  {
    [setSelectedColumns](state, action) {
      return action.payload
    },
    [resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
