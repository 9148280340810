import StartCallButtonContainer from '../../containers/StartCallButtonContainer'
import SessionSettingsButton from '../SessionSettingsButton'

import './styles.less'

const ControlsPannel = () => {
  return (
    <div className='top-nav-bar-controls'>
      <SessionSettingsButton />
      <StartCallButtonContainer />
    </div>
  )
}

export default ControlsPannel
