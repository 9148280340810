import { useFormik } from 'formik'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { object, string } from 'yup'

import { maxLength, requiredField } from '@tabeeb/modules/shared/utils/validationErrorMessages'

import {
  filterOperators,
  filterOperatorsLabels,
  filterOperatorsOrdered,
  filterOperatorsWithoutValues,
} from '../../constants'
import Button from '../Button'

const filterSchema = object().shape({
  filterValue: string().when('filterOperation', {
    is: (val) => val > 0 && !filterOperatorsWithoutValues.includes(val),
    then: string().max(16, maxLength(16)).required(requiredField),
  }),
})

const Filter = ({ onFilterApply, filter }) => {
  const formik = useFormik({
    initialValues: {
      filterOperation: filter?.operation || filterOperators.unset,
      filterValue: filter?.value || '',
    },
    validationSchema: filterSchema,
    onSubmit: (values) => onFilterApply(values.filterOperation, values.filterValue),
  })

  return (
    <div style={{ display: 'flex', gap: 8, padding: 8, alignItems: 'center' }}>
      <FormControl variant='outlined' size='small'>
        <InputLabel id='function-label'>Operator</InputLabel>
        <Select
          name='filterOperation'
          value={formik.values.filterOperation}
          onChange={(e) => {
            if (!e.target.value) {
              formik.setFieldValue('filterValue', '')
            }

            formik.handleChange(e)
          }}
          variant='outlined'
          style={{ width: 160 }}
          labelId='function-label'
          label='Function'
        >
          {filterOperatorsOrdered.map((operator) => (
            <MenuItem key={operator} value={operator}>
              {filterOperatorsLabels[operator]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        style={{ width: 230 }}
        name='filterValue'
        value={formik.values.filterValue}
        disabled={
          !formik.values.filterOperation || filterOperatorsWithoutValues.includes(formik.values.filterOperation)
        }
        onChange={formik.handleChange}
        error={formik.errors.filterValue && formik.touched.filterValue}
        label={formik.errors.filterValue && formik.touched.filterValue ? formik.errors.filterValue : 'Value'}
        variant='outlined'
        size='small'
      />
      <Button label='Apply' onClick={formik.handleSubmit} />
    </div>
  )
}

Filter.propTypes = {
  onFilterApply: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    operation: PropTypes.number,
    value: PropTypes.string,
  }),
}

export default Filter
