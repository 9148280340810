import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Popover, Typography, List, ListItem } from '@material-ui/core'
import { CameraAlt } from '@material-ui/icons'
import classNames from 'classnames'

import {
  setVideoInputDevice,
  loadCameraPreviews,
  unloadCameraPreviews,
} from '@tabeeb/modules/presentation/actions/devices'
import { getVideoInputDevices } from '@tabeeb/modules/presentation/services/conferenceService'
import { isLocalRecordingActive } from '@tabeeb/modules/recording/services/localRecordingService'
import { openSwitchRecordingSourceDialog } from '@tabeeb/modules/whiteboard/actions'
import { getIsCurrentUserBeingRecorded } from '@tabeeb/shared/content/selectors'
import { getVideoInputDevice } from '@tabeeb/modules/presentation/selectors'

import './styles.less'

const VideoSettings = ({
  anchorEl,
  isOpen,
  onClose,
  showVideoPreview = true,
  originPosition = {
    anchorOrigin: { vertical: 'top', horizontal: 'right' },
    transformOrigin: { vertical: 'center', horizontal: 'right' },
  },
}) => {
  const [videoInputDevices, loadVideoInputDevices] = useState([])

  const dispatch = useDispatch()

  const selectedVideoInputDevice = useSelector((state) => getVideoInputDevice(state))
  const showDevices = videoInputDevices.length && videoInputDevices[0].deviceId
  const isCurrentUserBeingRecorded = useSelector((state) => getIsCurrentUserBeingRecorded(state))

  const handleVideoInputDeviceClick = (deviceId) => {
    if (isLocalRecordingActive() && isCurrentUserBeingRecorded) {
      dispatch(openSwitchRecordingSourceDialog(deviceId))
      return
    }
    if (deviceId !== selectedVideoInputDevice) {
      dispatch(setVideoInputDevice({ deviceId }))
    }

    onClose()
  }

  const updateDeviceIfUnplugged = () => {
    getVideoInputDevices().then((devices) => {
      if (selectedVideoInputDevice && !devices.find((device) => device.deviceId === selectedVideoInputDevice)) {
        dispatch(setVideoInputDevice({ deviceId: devices[0].deviceId }))
      }
    })
  }

  const updateDeviceList = () => {
    getVideoInputDevices().then((devices) => {
      loadVideoInputDevices(devices)
    })
    dispatch(unloadCameraPreviews())
    if (isOpen) {
      dispatch(loadCameraPreviews())
    }
  }

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', updateDeviceIfUnplugged)

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', updateDeviceIfUnplugged)
    }
  }, [selectedVideoInputDevice])

  useEffect(() => {
    updateDeviceList()
    navigator.mediaDevices.addEventListener('devicechange', updateDeviceList)

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', updateDeviceList)
    }
  }, [isOpen])

  return (
    <Popover
      className='user-options-controls-popover'
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      {...originPosition}
    >
      <div className='section'>
        <div className='section-title'>
          <CameraAlt className='title-icon' color='disabled' />
          <Typography className='title-text'>Cameras</Typography>
        </div>
        <List className='section-content'>
          {showDevices ? (
            videoInputDevices.map((device) => (
              <ListItem
                key={device.deviceId}
                button
                title={device.label}
                onClick={() => handleVideoInputDeviceClick(device.deviceId)}
                selected={device.deviceId === selectedVideoInputDevice}
              >
                {showVideoPreview ? (
                  <div className='camera-preview'>
                    <video
                      className={classNames('tabeebUserVideo', 'video')}
                      data-id={device.deviceId}
                      autoPlay='autoPlay'
                      onContextMenu={() => false}
                    />
                    <Typography className='preview-label' noWrap>
                      {device.label}
                    </Typography>
                  </div>
                ) : (
                  <Typography noWrap>{device.label}</Typography>
                )}
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Typography noWrap>Devices are not detected</Typography>
            </ListItem>
          )}
        </List>
      </div>
    </Popover>
  )
}

VideoSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  showVideoPreview: PropTypes.bool,
  originPosition: PropTypes.object,
}

export default VideoSettings
