import { handleActions } from 'redux-actions'
import * as rawActions from '../actions'
import { DEFAULT_SESSIONS_TITLE } from '@tabeeb/shared/constants'

const defaultState = {
  bannerUrl: '',
  logoUrl: '',
  introImageUrl: '',
  introImageTextColor: '',
  name: '',
  subdomain: 'www',
  supportsPrograms: false,
  isGalleryExpansionEnabled: false,
  showStructureModel: false,
  showTowerSideProfile: false,
  show2dLineDrawing: false,
  isDetectObjects: false,
  showBentleyAnalyticsReport: false,
  sessionsNavbarTitle: DEFAULT_SESSIONS_TITLE,
}

export default handleActions(
  {
    [rawActions.onSetAppConfigState](state, action) {
      return { ...state, ...action.payload }
    },
    [rawActions.getConfigSuccess](state, action) {
      const config = action.response.data

      return { ...defaultState, ...config }
    },
  },
  defaultState
)
