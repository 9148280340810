import { put, takeLatest, all } from 'redux-saga/effects'

import * as notificationActions from '../../notification/actions'
import * as tenantConfigActions from '../actions'

function* onUpdateFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'There was an error updating the FTP settings' }))
}

function* onRegenerateFtpPasswordSuccess() {
  yield put(notificationActions.onAddSuccessNotification({ message: 'New FTP password has been sent' }))
}

function* onRegenerateFtpPasswordFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'There was an error updating the FTP password' }))
}

function* updateFtpServerSettings() {
  yield all([
    takeLatest(tenantConfigActions.updateFtpServerSettingsFailed, onUpdateFailed),
    takeLatest(tenantConfigActions.regenerateFtpPassword.success, onRegenerateFtpPasswordSuccess),
    takeLatest(tenantConfigActions.regenerateFtpPassword.failed, onRegenerateFtpPasswordFailed),
  ])
}

export default updateFtpServerSettings
