import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { DialogContent, DialogActions, Button } from '@material-ui/core'

import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { ConfirmationDialog } from '@tabeeb/modules/shared/dialogs'

import { FetchStatus } from '@tabeeb/enums'

import MainSettings from '../MainSettings'
import ExistingReminder from '../ExistingReminder'

import { setExistingReminder, deleteReminderRequest } from '../../actions'
import { selectUsersList } from '../../selectors'

import './styles.less'

const ExistingReminders = ({ reminders, onCloseDialog }) => {
  const dispatch = useDispatch()

  const { fetchingStatus: usersFetchingStatus, users } = useSelector(selectUsersList)

  const isUsersLoading = usersFetchingStatus === FetchStatus.Loading || usersFetchingStatus === FetchStatus.Idle

  const [reminderToDelete, setReminderToDelete] = useState(null)
  const [isReminderSettingsOpen, setIsReminderSettingsOpen] = useState(false)

  const _handleClick = useCallback(
    (reminder) => {
      dispatch(setExistingReminder(reminder))
      setIsReminderSettingsOpen(true)
    },
    [dispatch]
  )

  const _handleDeleteBtnClick = useCallback((e, reminder) => {
    e.stopPropagation()
    setReminderToDelete(reminder.Id)
  }, [])

  const _handleDeleteReminder = useCallback(() => {
    dispatch(deleteReminderRequest(reminderToDelete))
    setReminderToDelete(null)
  }, [dispatch, reminderToDelete])

  const dialogProps = {
    onCloseDialog: () => setReminderToDelete(null),
    onSubmit: _handleDeleteReminder,
    isOpen: reminderToDelete !== null,
    title: 'Are you sure you want to delete this reminder?',
  }

  return isReminderSettingsOpen ? (
    <MainSettings onCloseDialog={() => setIsReminderSettingsOpen(false)} isEditMode />
  ) : (
    <>
      <DialogContent className='reminder-dialog-content'>
        <div className='existing-reminders-list'>
          {isUsersLoading ? (
            <CircularProgressBar />
          ) : (
            reminders.map((reminder) => {
              const selectedUsers = users
                .filter((u) => reminder.Users.includes(u.id))
                .map((u) => u.displayName)
                .join(', ')

              const props = {
                selectedUsers,
                reminder,
                handleClick: _handleClick,
                handleDeleteBtnClick: _handleDeleteBtnClick,
              }

              return <ExistingReminder {...props} />
            })
          )}
          <ConfirmationDialog {...dialogProps} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} color='default' variant='outlined'>
          Close
        </Button>
      </DialogActions>
    </>
  )
}

ExistingReminders.propTypes = {
  reminders: PropTypes.array.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
}

export default ExistingReminders
