import { useCallback, useMemo, useState } from 'react'

import { useApiRequest, useDebouncedValue } from '@tabeeb/shared/utils/hooks'
import { getAllCertificateTypes, getApprovedCertificateTypeIdsByUserGuid } from '@tabeeb/modules/certificates/actions'
import { useMutation } from '@tabeeb/modules/certificates/hooks'
import { CertificateScopeType } from '@tabeeb/enums'
import {
  createUserQualification,
  getUserQualifications,
  restoreUserQualification,
  revokeUserQualification,
} from '../actions'
import { getUsersByIdentityGuids } from '../../../users/actions'

function useUserQualificationsGrid(getUserQualificationsParams, selectedUserGuid) {
  const {
    response,
    loading: getUserQualificationsLoading,
    onMutated,
    abortRequest,
  } = useApiRequest({
    defaultResponse: { UserQualifications: [], TotalCount: 0 },
    request: getUserQualifications.request,
    payload: getUserQualificationsParams,
  })

  const getUsersPayload = useMemo(() => {
    const guids = []
    response.UserQualifications.filter((_) => Boolean(_.AssignedToUserId) && Boolean(_.AssignerId)).forEach((_) => {
      guids.push(_.AssignedToUserId)
      guids.push(_.AssignerId)
    })

    const uniqueGuids = [...new Set(guids)]

    return { identityGuids: uniqueGuids }
  }, [response.UserQualifications])

  const { loading: loadingGetUsers, response: responseGetUsers } = useApiRequest({
    defaultResponse: [],
    request: getUsersByIdentityGuids.request,
    payload: getUsersPayload,
    enabled: getUsersPayload.identityGuids.length > 0,
  })

  const [certificateTypesSearch, setCertificateTypesSearch] = useState('')
  const debouncedCertificateTypesSearch = useDebouncedValue(certificateTypesSearch, 500)
  const getAllCertificateTypesPayload = useMemo(
    () => ({
      certificateScopeTypes: [CertificateScopeType.ProfileCertificate, CertificateScopeType.UserCertificate],
      search: debouncedCertificateTypesSearch,
    }),
    [debouncedCertificateTypesSearch]
  )
  const {
    response: getAllCertificateTypesResponse,
    loading: getAllCertificateTypesLoading,
    abortRequest: abortAllCertificateTypesRequest,
  } = useApiRequest({
    defaultResponse: [],
    request: getAllCertificateTypes.request,
    payload: getAllCertificateTypesPayload,
  })

  const getAllCertificateTypeIdsByUserGuidPayload = useMemo(() => ({ userGuid: selectedUserGuid }), [selectedUserGuid])
  const {
    response: getApprovedCertificateTypeIdsByUserGuidResponse,
    loading: getAllCertificateTypeIdsByUserGuidLoading,
  } = useApiRequest({
    defaultResponse: [],
    request: getApprovedCertificateTypeIdsByUserGuid.request,
    payload: getAllCertificateTypeIdsByUserGuidPayload,
    enabled: Boolean(selectedUserGuid),
  })

  const createMutation = useMutation({
    mutationRequest: createUserQualification.request,
  })

  const revokeMutation = useMutation({
    mutationRequest: revokeUserQualification.request,
  })

  const restoreMutation = useMutation({
    mutationRequest: restoreUserQualification.request,
  })

  const abortAll = useCallback(() => {
    abortRequest()
    abortAllCertificateTypesRequest()
  }, [abortRequest, abortAllCertificateTypesRequest])

  const loading = useMemo(
    () => createMutation.loading || getUserQualificationsLoading || revokeMutation.loading || restoreMutation.loading,
    [createMutation.loading, getUserQualificationsLoading, revokeMutation.loading, restoreMutation.loading]
  )

  return {
    loading,
    abortAll,
    getUserQualificationsResponse: response,
    getUserQualificationsLoading,
    onGetUserQualificationsMutated: onMutated,
    // create
    createUserQualificationMutate: createMutation.mutate,
    // revoke
    revokeUserQualificationMutate: revokeMutation.mutate,
    // restore
    restoreUserQualificationMutate: restoreMutation.mutate,
    // get all types
    getAllCertificateTypesResponse,
    getAllCertificateTypesLoading,
    // get approved certificateTypeIds of selected user
    getApprovedCertificateTypeIdsByUserGuidResponse,
    getAllCertificateTypeIdsByUserGuidLoading,
    setCertificateTypesSearch,
    // get users
    loadingGetUsers,
    responseGetUsers,
  }
}

export default useUserQualificationsGrid
