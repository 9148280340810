import { memo } from 'react'
import PropTypes from 'prop-types'

import { MoreVertOutlined } from '@mui/icons-material'
import { Box, Checkbox, Divider, IconButton, Tooltip, Typography } from '@mui/material'

import SessionParticipantsCountBadge from './SessionParticipantsCountBadge'
import SessionPagesCountBadge from './SessionPagesCountBadge'

import { Container, PreviewFooter, Footer, Preview } from './styles'
import { SessionLockedStatusIcon, SessionPublishedStatusIcon } from '../../StatusIcons'

const defaultThumbnailUrl = new URL(
  '@tabeeb/assets/images/session-thumbnail-placeholder.jpg',
  import.meta.url
).toString()

const SessionTile = ({ session, draggable, selectable, selected, onDragStart, onClick, onMenu, onSelect }) => {
  const active = session.IsActive
  const thumbnail = session.ThumbnailUrl || defaultThumbnailUrl
  const isDefaultThumbnail = !session.ThumbnailUrl

  const onOpenMenuClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    onMenu(e, session)
  }

  const onSelectClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    onSelect(!selected, session)
  }

  return (
    <Container
      variant='outlined'
      data-selected={selected}
      draggable={draggable}
      onClick={() => onClick(session)}
      onDragStart={(e) => onDragStart(e, session)}
    >
      {selectable && (
        <Tooltip title={selected ? 'Unselect' : 'Select'}>
          <Checkbox
            sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
            color='primary'
            checked={selected}
            onClick={onSelectClick}
          />
        </Tooltip>
      )}
      <Preview data-active={active} data-default-preview={isDefaultThumbnail} image={thumbnail}>
        <PreviewFooter>
          {!!session.Slides && <SessionPagesCountBadge pages={session.Slides} />}
          {!!session.UsersCount && <SessionParticipantsCountBadge participants={session.UsersCount} />}
        </PreviewFooter>
      </Preview>
      <Divider />
      <Footer>
        <Box display='flex' alignItems='center' flexGrow={1} minWidth={0}>
          <Box minWidth={0}>
            <Box display='flex' alignItems='center'>
              {!active && <SessionLockedStatusIcon />}
              {session.IsPublished && <SessionPublishedStatusIcon />}
              <Typography variant='body2' title={session.Description} fontWeight={600} noWrap>
                {session.Description}
              </Typography>
            </Box>
            {session.Owner && (
              <Typography variant='body2' title={session.Owner.Name} noWrap>
                {session.Owner.Name}
              </Typography>
            )}
          </Box>
        </Box>

        {onMenu && (
          <IconButton size='small' onClick={onOpenMenuClick} color='inherit'>
            <MoreVertOutlined />
          </IconButton>
        )}
      </Footer>
    </Container>
  )
}

SessionTile.defaultProps = {
  selected: false,
  draggable: false,
}

SessionTile.propTypes = {
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Description: PropTypes.string.isRequired,
    Owner: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }).isRequired,
    Slides: PropTypes.number.isRequired,
    UsersCount: PropTypes.number.isRequired,
    IsActive: PropTypes.bool.isRequired,
    IsPublished: PropTypes.bool.isRequired,
    ThumbnailUrl: PropTypes.string,
  }).isRequired,
  draggable: PropTypes.bool,
  selected: PropTypes.bool,
  selectable: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDragStart: PropTypes.func,
  onMenu: PropTypes.func,
  onSelect: PropTypes.func,
}

export default memo(SessionTile)
