import { memo } from 'react'
import PropTypes from 'prop-types'

import { ConfirmationDialog } from '@tabeeb/modules/shared/dialogs'

const AnnouncementDeleteConfirmationDialog = ({ open, onClose, onSubmit }) => {
  return (
    <ConfirmationDialog
      isOpen={open}
      onCloseDialog={onClose}
      onSubmit={onSubmit}
      title='The announcement will be deleted'
      submitButtonColor='error'
      submitButtonText='Delete'
    />
  )
}

AnnouncementDeleteConfirmationDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
}

AnnouncementDeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default memo(AnnouncementDeleteConfirmationDialog)
