import { styled } from '@mui/material'

const StyledSpan = styled('span')(({ theme }) => {
  return {
    cursor: 'text',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    ...theme.typography.body1,
    fontSize: '14px',
    color: '#0E1621',
  }
})

const Span = ({ children, ...props }) => {
  return <StyledSpan {...props}>{children}</StyledSpan>
}

export default Span
