import { handleActions } from 'redux-actions'

import * as screenSharingActions from '../actions/screenSharing'

const initialState = {
  isScreenSharing: false,
  isScreenSharingInProgress: false,
  screenSharingError: '',
  systemAudioTrack: null,
}

export default handleActions(
  {
    [screenSharingActions.toggleScreenSharing](state, action) {
      return { ...state, isScreenSharing: !state.isScreenSharing }
    },
    [screenSharingActions.setScreenSharingError](state, action) {
      return { ...state, screenSharingError: action.payload }
    },
    [screenSharingActions.setScreenSharingState](state, action) {
      return { ...state, isScreenSharingInProgress: action.payload }
    },
    [screenSharingActions.setSystemAudioTrack](state, action) {
      return { ...state, systemAudioTrack: action.payload }
    },
    [screenSharingActions.resetScreenSharingState](state, action) {
      return initialState
    },
  },
  initialState
)
