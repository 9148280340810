import { handleActions } from 'redux-actions'
import {
  closeAiReportDialog,
  createPublishTemplate,
  deletePublishTemplate,
  getPublishTemplates,
  updatePublishTemplate,
} from '../actions'
import { apiColumnMap } from '../services/apiColumnMap'

const defaultState = []

export default handleActions(
  {
    [getPublishTemplates.success](state, action) {
      const camelCaseModels = action.response.data.map((t) => ({
        name: t.Name,
        columns: t.Columns.map((c) => apiColumnMap(c)),
        id: t.Id,
      }))

      return camelCaseModels
    },
    [createPublishTemplate.success](state, action) {
      const newTemplate = action.response.data

      return [
        ...state,
        {
          name: newTemplate.Name,
          columns: newTemplate.Columns.map((c) => apiColumnMap(c)),
          id: newTemplate.Id,
        },
      ]
    },
    [updatePublishTemplate.success](state, action) {
      const updatedTemplate = action.response.data

      return state.map((template) =>
        template.id === updatedTemplate.Id
          ? {
              name: updatedTemplate.Name,
              columns: updatedTemplate.Columns.map((c) => apiColumnMap(c)),
              id: updatedTemplate.Id,
            }
          : template
      )
    },
    [deletePublishTemplate.success](state, action) {
      return state.filter((t) => t.id !== action.payload.id)
    },
    [closeAiReportDialog](state, action) {
      return defaultState
    },
  },
  defaultState
)
