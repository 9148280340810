import { styled } from '@mui/material'

export const ActionsContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',

    '& > a, > button, > div, > span': {
      marginLeft: theme.spacing(1),
    },
  }
})
