import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = null

export default handleActions(
  {
    [actions.getTenantsListSuccess](state, { response }) {
      return response.data.currentTenant
    },
  },
  defaultState
)
