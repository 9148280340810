import { Menu, MenuItem, styled } from '@mui/material'

export const DialogContentContainer = styled('div')(({ theme }) => {
  return {
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }
})

export const LinksMenu = styled(Menu)(({ theme }) => {
  return {
    maxHeight: 400,
    '& ::-webkit-scrollbar': {
      width: 5,
      height: 5,
    },
  }
})

export const LinksMenuItem = styled(MenuItem)(({ theme }) => {
  return {
    width: 180,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
  }
})
