import { handleActions } from 'redux-actions'
import * as tenantConfigActions from '../actions'

const defaultState = {
  bannerUrl: '',
  logoUrl: '',
  introImageUrl: '',
  introImageTextColor: '',
  name: '',
  subdomain: '',
  supportsPrograms: false,
  apiUrl: '',
}

export default handleActions(
  {
    [tenantConfigActions.setConfigForUpdate](state, action) {
      return { ...defaultState, ...action.payload }
    },
    [tenantConfigActions.getConfigForUpdateSuccess](state, action) {
      const config = action.response.data
      return { ...defaultState, ...config }
    },
  },
  defaultState
)
