import { memo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useDialogState } from '@tabeeb/shared/utils/hooks'
import {
  ContentSharingDialogType,
  LinkedContentControl,
  LinkedContentStatus,
} from '@tabeeb/modules/contentSharings/constants/enums'
import { deleteContentsLink, openSelectedPagesForMerging, syncContent } from '@tabeeb/modules/contentSharings/actions'
import { switchPanel } from '@tabeeb/modules/sessionTabs/actions'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import { ConfirmationDialog } from '@tabeeb/modules/shared/dialogs'
import { StyledButton } from './styles'

const LinkedContentButton = ({ button, content }) => {
  const dispatch = useDispatch()
  const [isOpen, onOpen, onClose] = useDialogState()
  const isMergingInProgress = content.Status === LinkedContentStatus.MergingInProgress

  const _handleClick = () => {
    if (isMergingInProgress) {
      return
    }

    if (button === LinkedContentControl.Merge) {
      const data = {
        targetContents: [{ Id: content.ContentId }],
        type: ContentSharingDialogType.SyncContentData,
      }

      dispatch(syncContent(data))
      dispatch(openSelectedPagesForMerging())
      dispatch(switchPanel(TabPanel.LinkedContentsSelectedPages, TabPanelLocation.Bottom))
      return
    }

    if (button === LinkedContentControl.Unlink) {
      onOpen()
    }
  }

  const _handleDeleteContentsLink = () => {
    dispatch(deleteContentsLink.request(content.Id))
    onClose()
  }

  const dialogProps = {
    onCloseDialog: onClose,
    onSubmit: _handleDeleteContentsLink,
    isOpen,
    cancelButtonText: 'No',
    submitButtonText: 'Yes',
    title: 'Are you sure you want to delete this link?',
  }

  return (
    <>
      {button === LinkedContentControl.Merge && (
        <StyledButton
          onClick={_handleClick}
          size='small'
          variant='contained'
          color='primary'
          disabled={content.Status !== LinkedContentStatus.Active}
        >
          {button}
        </StyledButton>
      )}
      {button === LinkedContentControl.Unlink && (
        <StyledButton
          onClick={_handleClick}
          size='small'
          variant='outlined'
          disabled={content.Status === LinkedContentStatus.MergingInProgress}
        >
          {button}
        </StyledButton>
      )}
      <ConfirmationDialog {...dialogProps} />
    </>
  )
}

LinkedContentButton.propTypes = {
  content: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Status: PropTypes.number.isRequired,
    ContentId: PropTypes.number.isRequired,
  }).isRequired,
  button: PropTypes.string.isRequired,
}

export default memo(LinkedContentButton)
