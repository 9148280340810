const tenantSettingTextFieldStyles = {
  root: {
    width: '313px',
    height: '40px',
    background: '#ffffff 0% 0% no-repeat padding-box',
    opacity: '1',

    '& .MuiOutlinedInput-root': {
      width: '313px',
      height: '40px',
      background: '#ffffff 0% 0% no-repeat padding-box',
      opacity: 1,
      fontSize: '22px',
      lineHeight: '27px',
      fontWeight: 'normal',

      '& fieldset': {
        border: '1px solid #e4e4e4',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: '#c4c4c4',
      },
      '&$focused fieldset': {
        borderColor: '#c4c4c4',
        borderWidth: '1px',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #e4e4e4',
        borderRadius: '8px',
      },

      '& .MuiOutlinedInput-input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
        '-moz-appearance': 'textfield',
      },
    },
  },
}

export default tenantSettingTextFieldStyles
