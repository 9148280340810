import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { ConfirmationDialog } from '@tabeeb/shared/dialogs'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { copyContent } from '@tabeeb/modules/contentSharings/actions'

const CopySessionDialog = ({ open, session, onClose }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(() => {
    dispatch(copyContent.request({ contentSharingId: session.PublishedSessionContentSharingId }))

    onClose()
  }, [dispatch, onClose, session?.PublishedSessionContentSharingId])

  return (
    <ConfirmationDialog
      isOpen={open}
      title={sessionTitleFormatter.format('Copy session')}
      dialogMessage={sessionTitleFormatter.format('Would you like to copy this session?')}
      onCloseDialog={onClose}
      onSubmit={onSubmit}
    />
  )
}

CopySessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    PublishedSessionContentSharingId: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(CopySessionDialog)
