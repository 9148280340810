import { Tab, Tabs as MuiTabs, styled } from '@mui/material'

export const StyledTabs = styled(MuiTabs)(({ theme }) => {
  return {
    width: '100%',
    overflowX: 'auto ',
    backgroundColor: theme.palette.primary.light,
    '& > .MuiTabScrollButton-root': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  }
})

export const StyledTab = styled(Tab)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.light,

    color: theme.palette.common.white,
    fontWeight: 600,

    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  }
})
