import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { getSelectedAiObjectId } from '../../selectors'
import { selectAiObject } from '../../actions'

import { StyledTab, StyledTabs } from './styles'

const Tabs = ({ tabs }) => {
  const dispatch = useDispatch()

  const selectedAiObjectId = useSelector(getSelectedAiObjectId)

  const handleTabChange = (_, newTabId) => {
    if (newTabId === selectedAiObjectId) {
      return
    }
    dispatch(selectAiObject({ aiObjectId: newTabId }))
  }

  return (
    <StyledTabs value={selectedAiObjectId} onChange={handleTabChange} variant='scrollable' scrollButtons='auto'>
      {tabs.map((tab) => (
        <StyledTab label={tab.Name} value={tab.Id} key={tab.Id} title={tab.Name} wrapped />
      ))}
    </StyledTabs>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Id: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default Tabs
