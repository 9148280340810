import { put, takeLatest, select, all } from 'redux-saga/effects'
import { onAddInfoNotification, onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import * as aiActions from '../actions'
import * as tenantConfigActions from '../../tenantConfigPage/actions'
import { removeTemporaryFields } from '../../tenantConfigPage/services/configHelpers'

function* onDeleteModelSuccess() {
  yield put(onAddInfoNotification({ message: 'Model was successfully deleted' }))
}

function* onDeleteModelFailed() {
  yield put(onAddErrorNotification({ message: 'Failed to delete a model' }))
}

function* onDeletePredictionSetSuccess() {
  yield onDeleteModelSuccess()

  const predictionSets = yield select((state) => state.artificialIntelligence.predictionSets)
  const appConfigState = yield select((state) => state.appConfigState)

  if (predictionSets.length <= 0) {
    const newConfig = { ...appConfigState, isDetectObjects: false }
    removeTemporaryFields(newConfig)
    yield put(tenantConfigActions.updateConfigRequest({ subdomain: newConfig.subdomain, config: newConfig }))
  }
}

function* deleteModel() {
  yield all([
    takeLatest(
      [aiActions.deleteNlpModel.failed, aiActions.deleteVqaModel.failed, aiActions.deletePredictionSet.failed],
      onDeleteModelFailed
    ),
    takeLatest([aiActions.deleteNlpModel.success, aiActions.deleteVqaModel.success], onDeleteModelSuccess),
    takeLatest(aiActions.deletePredictionSet.success, onDeletePredictionSetSuccess),
  ])
}

export default deleteModel
