import PropTypes from 'prop-types'
import { FormControl, FormHelperText, Autocomplete, Typography, TextField, MenuItem } from '@mui/material'
import { get } from 'lodash'

const FormikAutocomplete = ({
  field,
  form,
  options = [],
  label,
  name,
  variant,
  size,
  disabled,
  required,
  loading,
  disableClearable,
  placeholder,
  isOptionEqualToValue,
  getOptionLabel,
  onChange,
  getOptionKey = (option) => option.value,
  ...props
}) => {
  const { errors, touched } = form
  const error = get(touched, field.name) && get(errors, field.name)

  const handleChange = (event, selectedOption, reason) => {
    if (onChange) {
      onChange(event, selectedOption, reason)
    }
    form.setFieldValue(field.name, reason === 'clear' ? null : selectedOption.value)
  }

  const customFilterOptions = (optionsToFilter, state) => {
    if (!state.inputValue) {
      return optionsToFilter
    }

    const pattern = state.inputValue.replace(/ /g, '.*')
    const regex = new RegExp(pattern, 'i')

    return optionsToFilter.filter((option) => regex.test(option.name))
  }

  return (
    <FormControl fullWidth error={!!error} disabled={disabled} required={required} size={size}>
      <Autocomplete
        {...field}
        {...props}
        disableClearable={disableClearable}
        filterOptions={customFilterOptions}
        onChange={handleChange}
        fullWidth
        label={label}
        name={name}
        disabled={disabled}
        required={required}
        size={size}
        variant={variant}
        loading={loading}
        options={options}
        getOptionLabel={
          getOptionLabel ||
          ((option) => {
            return options.find((optn) => optn.value === option)?.name || ''
          })
        }
        renderTags={() => []}
        isOptionEqualToValue={isOptionEqualToValue || ((option, value) => option.value === value)}
        renderOption={(renderProps, option) => (
          <Typography {...renderProps} noWrap title={option.name} key={getOptionKey(option)}>
            {option.name}
          </Typography>
        )}
        renderInput={(params) => (
          <TextField {...params} variant={variant} label={label} placeholder={placeholder} error={!!error} />
        )}
      >
        {options.map((option) => (
          <MenuItem key={getOptionKey(option)} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Autocomplete>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

FormikAutocomplete.defaultProps = {
  disableClearable: false,
  disabled: false,
  required: false,
  loading: false,
  placeholder: '',
  size: 'medium',
  variant: 'outlined',
}

FormikAutocomplete.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  disableClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  isOptionEqualToValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  getOptionKey: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      icon: PropTypes.node,
    }).isRequired
  ).isRequired,
  name: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  size: PropTypes.oneOf(['small', 'medium']),
}

export default FormikAutocomplete
