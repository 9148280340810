import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import DragBar from './components/DragBar'
import ResizeArrow from './components/ResizeArrow'

const Resizable = ({ children, width, height, throttle, onDrag, onResize }) => {
  const handleDrag = useMemo(() => _.throttle(onDrag, throttle), [onDrag, throttle])
  const handleResize = useMemo(() => _.throttle(onResize, throttle), [onResize, throttle])

  return (
    <>
      {children}
      <ResizeArrow width={width} height={height} onResize={handleResize} />
      <DragBar onDrag={handleDrag} />
    </>
  )
}

Resizable.defaultProps = {
  throttle: 10,
}

Resizable.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element.isRequired), PropTypes.element.isRequired])
    .isRequired,
  throttle: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onDrag: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
}

export default memo(Resizable)
