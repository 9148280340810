import { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

import styles from './styles'

class DragBar extends Component {
  state = {
    startX: 0,
    startY: 0,
  }

  constructor(props) {
    super(props)

    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
  }

  onMouseMove(e) {
    const { onDrag } = this.props
    const { startX, startY } = this.state

    onDrag({ x: e.pageX - startX, y: e.pageY - startY })
  }

  onMouseUp() {
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  onMouseDown(e) {
    const rect = e.target.getBoundingClientRect()

    this.setState({ startX: e.pageX - rect.x, startY: e.pageY - rect.y })

    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)
  }

  render() {
    const { classes } = this.props

    return (
      <div title='Move' className={classes.container} onMouseDown={this.onMouseDown}>
        <div className={classes.bar} />
      </div>
    )
  }
}

DragBar.defaultProps = {
  onDrag: () => {},
}

DragBar.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    bar: PropTypes.string.isRequired,
  }).isRequired,
  onDrag: PropTypes.func.isRequired,
}

export default withStyles(styles)(DragBar)
