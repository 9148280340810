import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { push } from 'connected-react-router'

import PropTypes from 'prop-types'
import { TableRow, TableCell, Button, Chip } from '@mui/material'
import { Edit } from '@mui/icons-material'
import moment from 'moment'

import Routes from '@tabeeb/routes'
import IdentityServerUserInfo from '@tabeeb/modules/shared/uikit/components/IdentityServerUserInfo'
import { getCurrentUserGuid } from '@tabeeb/modules/account/selectors'
import { getIsUserPowerBIAdmin } from '@tabeeb/modules/reports/selectors'

const PowerBIReportProvidersTableRow = ({ provider }) => {
  const dispatch = useDispatch()

  const currentUserId = useSelector(getCurrentUserGuid)
  const isUserPowerBIAdmin = useSelector(getIsUserPowerBIAdmin)

  const showEditButton = useMemo(() => {
    return currentUserId === provider.OwnerId || isUserPowerBIAdmin
  }, [currentUserId, isUserPowerBIAdmin, provider.OwnerId])

  const onEdit = useCallback(() => {
    dispatch(push(generatePath(Routes.powerBIReportProvider, { id: provider.Id })))
  }, [dispatch, provider.Id])

  return (
    <TableRow>
      <TableCell align='left'>{provider.Name}</TableCell>
      <TableCell align='left'>
        <IdentityServerUserInfo userIdentityId={provider.OwnerId} />
      </TableCell>
      <TableCell align='left'>{moment(provider.CreatedOn).format('LLL')}</TableCell>
      <TableCell align='left'>
        {provider.IsAvailable ? (
          <Chip label='Available' color='success' variant='outlined' />
        ) : (
          <Chip label='Unavailable' color='error' variant='outlined' />
        )}
      </TableCell>
      <TableCell align='left'>
        {showEditButton && (
          <Button variant='outlined' color='primary' onClick={onEdit} startIcon={<Edit />}>
            Edit
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}

PowerBIReportProvidersTableRow.propTypes = {
  provider: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    OwnerId: PropTypes.string.isRequired,
    IsAvailable: PropTypes.bool.isRequired,
    CreatedOn: PropTypes.string.isRequired,
  }),
}

export default PowerBIReportProvidersTableRow
