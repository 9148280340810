import { memo } from 'react'
import PropTypes from 'prop-types'

import { MoreVertOutlined } from '@mui/icons-material'
import { Box, Checkbox, IconButton, TableCell, TableRow, Typography } from '@mui/material'

import { ReviewStatus, ReviewStatusDisplayName, SessionStatus, SessionStatusDisplayName } from '@tabeeb/enums'
import { DateInfo } from '@tabeeb/uikit'
import { SessionLockedStatusIcon, SessionPublishedStatusIcon } from '../../StatusIcons'

const SessionsTableCell = ({ align, children, disablePadding, width }) => {
  return (
    <TableCell align={align} padding={disablePadding ? 'none' : 'normal'} sx={{ color: 'inherit' }} width={width}>
      {children}
    </TableCell>
  )
}

SessionsTableCell.defaultProps = {
  align: 'left',
  disablePadding: false,
  width: 150,
}

SessionsTableCell.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  disablePadding: PropTypes.bool,
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
}

const SessionsTableTextCell = ({ align, children, width }) => {
  return (
    <SessionsTableCell align={align} width={width}>
      <Typography variant='body2' maxWidth={width} noWrap>
        {children}
      </Typography>
    </SessionsTableCell>
  )
}

SessionsTableTextCell.defaultProps = {
  align: 'left',
  width: 150,
}

SessionsTableTextCell.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
}

const SessionsTableRow = ({ draggable, session, selectable, selected, onClick, onDragStart, onSelect, onMenu }) => {
  const onSelectClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    onSelect(!selected, session)
  }

  const onOpenMenuClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    onMenu(e, session)
  }

  return (
    <TableRow selected={selected} hover draggable={draggable} onDragStart={(e) => onDragStart(e, session)}>
      {selectable && (
        <SessionsTableCell align='left' disablePadding width={30}>
          <Checkbox color='primary' checked={selected} onClick={onSelectClick} />
        </SessionsTableCell>
      )}

      {/* Title */}
      <SessionsTableCell width={150}>
        <Box display='flex' alignItems='center' maxWidth={150}>
          {!session.IsActive && <SessionLockedStatusIcon />}
          {session.IsPublished && <SessionPublishedStatusIcon />}
          <Typography
            sx={{ cursor: 'pointer', width: 'min-content', '&:hover': { textDecoration: 'underline' } }}
            variant='body2'
            fontWeight={500}
            onClick={() => onClick(session)}
            title={session.Description}
            noWrap
          >
            {session.Description}
          </Typography>
        </Box>
      </SessionsTableCell>

      {/* Owner */}
      <SessionsTableCell width={150}>
        <Box display='flex' flexDirection='column' minWidth={0} maxWidth={120}>
          {session.Owner && (
            <Typography variant='body2' title={session.Owner?.Name} fontWeight={500} noWrap>
              {session.Owner.Name}
            </Typography>
          )}
          <Typography variant='body2' title={session.Owner?.Email} noWrap>
            {session.Owner?.Email || '-'}
          </Typography>
        </Box>
      </SessionsTableCell>

      {/* Generic ID */}
      <SessionsTableTextCell width={90}>{session.OwnerGenericId || '-'}</SessionsTableTextCell>

      {/* Created on */}
      <SessionsTableCell width={125}>
        <DateInfo date={session.CreatedOn} />
      </SessionsTableCell>

      {/* Last activity on */}
      {session.LastActivity ? (
        <SessionsTableCell width={125}>
          <DateInfo date={session.LastActivity} />
        </SessionsTableCell>
      ) : (
        <SessionsTableTextCell>-</SessionsTableTextCell>
      )}

      {/* Session status */}
      <SessionsTableTextCell>{SessionStatusDisplayName[session.SessionStatus] || '-'}</SessionsTableTextCell>

      {/* Review status */}
      <SessionsTableTextCell>{ReviewStatusDisplayName[session.ReviewStatus] || '-'}</SessionsTableTextCell>

      {/* Pages */}
      <SessionsTableTextCell>{session.Slides}</SessionsTableTextCell>

      {/* Menu button */}
      {onMenu && (
        <SessionsTableCell align='center' disablePadding width={40}>
          <IconButton size='small' color='inherit' onClick={onOpenMenuClick}>
            <MoreVertOutlined />
          </IconButton>
        </SessionsTableCell>
      )}
    </TableRow>
  )
}

SessionsTableRow.defaultProps = {
  draggable: false,
  selected: false,
}

SessionsTableRow.propTypes = {
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    LastActivity: PropTypes.string,
    Description: PropTypes.string.isRequired,
    Owner: PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Email: PropTypes.string.isRequired,
    }).isRequired,
    OwnerGenericId: PropTypes.string,
    ReviewStatus: PropTypes.oneOf(Object.values(ReviewStatus)),
    Slides: PropTypes.number.isRequired,
    SessionStatus: PropTypes.oneOf(Object.values(SessionStatus)),
    UsersCount: PropTypes.number.isRequired,
    IsActive: PropTypes.bool.isRequired,
    IsPublished: PropTypes.bool.isRequired,
    ThumbnailUrl: PropTypes.string,
  }).isRequired,
  draggable: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onMenu: PropTypes.func,
}

export default memo(SessionsTableRow)
