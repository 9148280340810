import uploadRequests from '@tabeeb/modules/shared/utils/API/uploadRequests'
import ApiService from '@tabeeb/services/API'
import selector from '@tabeeb/modules/shared/utils/selector'
import { applicationStore } from '@tabeeb/state/createStore'

async function _getBlobClient({ fileName, uploadContentType, contentId }) {
  const state = applicationStore.getState()
  const apiHostName = selector.getApiUrl(state)
  const accessToken = selector.getAccessToken(state)
  const sasResponse = await ApiService({
    hostName: apiHostName,
    data: uploadRequests.createSassContainer({ contentId, uploadContentType, fileName }),
    accessToken,
  })
  const {
    data: { blobUri, sas, container, fileName: name },
  } = sasResponse

  const BlobServiceClient = await import('@azure/storage-blob').then((i) => i.BlobServiceClient)

  const blobServiceClient = new BlobServiceClient(
    `${blobUri.substring(0, blobUri.length - 1)}${sas}`,
    {},
    { retryOptions: { maxTries: 10, maxRetryDelayInMs: 300 * 1000 } }
  )
  const containerClient = blobServiceClient.getContainerClient(container)
  const blockBlobClient = containerClient.getBlockBlobClient(name)
  return { sas: sasResponse.data, blockBlobClient }
}

export async function uploadFile({ file, contentId, uploadContentType, abortController, onProgress }) {
  const { blockBlobClient, sas } = await _getBlobClient({
    fileName: file.name,
    uploadContentType,
    contentId,
  })
  await blockBlobClient.uploadData(file, {
    abortSignal: abortController?.signal,
    onProgress,
  })
  return { url: blockBlobClient.url, sas }
}
