import { memo } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Fade, Tooltip, Typography, withStyles } from '@material-ui/core'

import { recordingSelectors } from '@tabeeb/modules/recording'

import styles from './styles'

const getRecordingDurationString = (duration) => {
  const recordingTime = moment.utc(duration)

  const hours = Math.floor(moment.duration(duration, 'milliseconds').asHours())
  if (hours > 23) {
    return `${hours}:${recordingTime.format('mm:ss')}`
  }

  if (hours > 0) {
    return `${recordingTime.format('HH:mm:ss')}`
  }

  return recordingTime.format('mm:ss')
}

const RecordingTimer = ({ classes }) => {
  const time = useSelector(recordingSelectors.getElapsedTime)
  const isRecordingActive = useSelector(recordingSelectors.getIsRecordingActive)

  return (
    <Fade in={isRecordingActive} unmountOnExit>
      <Tooltip title='Recording time' arrow placement='top'>
        <div className={classes.container}>
          <div className={classes.icon} />
          <Typography variant='caption' component='time'>
            {getRecordingDurationString(time)}
          </Typography>
        </div>
      </Tooltip>
    </Fade>
  )
}

RecordingTimer.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(withStyles(styles)(RecordingTimer))
