import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material'
import { Close, Done } from '@mui/icons-material'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { getIsSheetNameUnique } from '@tabeeb/modules/contentMetadata/actions'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { getIsSheetNameUniqueSelector } from '@tabeeb/modules/contentMetadata/selectors'

import { closeAiReportPublishSettingsDialog, getDefaultPublishName, publishAiReport } from '../../actions'
import {
  getDefaultPublishNameSelector,
  getIsUniqueNameCheckInProgress,
  getIsPublishSettingsDialogOpen,
  getSelectedAiObjectId,
  getSelectedTemplate,
  getIsPublishInProgress,
} from '../../selectors'

const maxLength = 100

const PublishSettingsDialog = () => {
  const dispatch = useDispatch()

  const [sheetName, setSheetName] = useState('')
  const [rewrite, setRewrite] = useState(false)

  const isOpen = useSelector(getIsPublishSettingsDialogOpen)
  const selectedTemplate = useSelector(getSelectedTemplate)
  const aiObjectId = useSelector(getSelectedAiObjectId)
  const defaultPublishName = useSelector(getDefaultPublishNameSelector)
  const contentId = useSelector(getContentId)
  const isLoading = useSelector(getIsUniqueNameCheckInProgress)
  const isPublishInProgress = useSelector(getIsPublishInProgress)

  const isNameUnique = useSelector(getIsSheetNameUniqueSelector)
  const isMaxLengthExceeded = sheetName.length > maxLength

  const onClose = () => {
    dispatch(closeAiReportPublishSettingsDialog())
  }

  const debouncedSheetsRequest = useRef(
    debounce(({ name }) => {
      dispatch(getIsSheetNameUnique.cancel())
      dispatch(getIsSheetNameUnique.request({ contentId, name }))
    }, 500)
  ).current

  useEffect(() => {
    if (isOpen) {
      dispatch(getDefaultPublishName.request({ aiObjectId }))
    } else {
      setRewrite(false)
    }
  }, [aiObjectId, dispatch, isOpen])

  useEffect(() => {
    setSheetName(defaultPublishName)
  }, [defaultPublishName])

  useEffect(() => {
    if (sheetName && !isMaxLengthExceeded) {
      debouncedSheetsRequest({ name: sheetName })
    }
  }, [contentId, debouncedSheetsRequest, dispatch, isMaxLengthExceeded, sheetName])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Publish settings</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            width: 'fit-content',
          }}
        >
          {isPublishInProgress && <CircularProgressBar />}
          <Typography>Provide the name of the sheet being generated, leave the default value if applicable.</Typography>
          <FormControl>
            <TextField
              style={{ width: 230 }}
              name='sheetName'
              value={sheetName}
              onChange={(e) => setSheetName(e.target.value)}
              label='Sheet name'
              variant='outlined'
              size='small'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {isLoading ? (
                      <CircularProgress color='info' size={20} thickness={3} />
                    ) : !isNameUnique && !rewrite ? (
                      <Close />
                    ) : (
                      <Done />
                    )}
                  </InputAdornment>
                ),
              }}
              helperText={isMaxLengthExceeded && `Max length is ${maxLength}`}
              error={isMaxLengthExceeded}
            />
          </FormControl>

          {!isLoading && !isNameUnique && !isMaxLengthExceeded && (
            <>
              {!rewrite ? (
                <Typography color='error'>
                  The name is already in use. If you&apos;d like to rewrite the sheet, please check the box.
                </Typography>
              ) : null}

              <FormControlLabel
                label='Rewrite the sheet'
                control={<Checkbox checked={rewrite} onChange={(e) => setRewrite(e.target.checked)} />}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            dispatch(
              publishAiReport.request({
                contentId,
                aiObjectId,
                templateId: selectedTemplate.id,
                rewrite,
                name: sheetName,
              })
            )
          }}
          disabled={isLoading || (!isNameUnique && !rewrite) || isPublishInProgress || isMaxLengthExceeded}
        >
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PublishSettingsDialog
