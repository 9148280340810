import { Divider, styled } from '@mui/material'

export const StyledDivider = styled(Divider)(({ theme }) => {
  return {
    margin: theme.spacing(0, 2),
  }
})

export const MenuItemContent = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    width: 110,
    alignItems: 'center',
  }
})
