import { combineActions, handleActions } from 'redux-actions'

import { getIsSheetNameUnique } from '@tabeeb/modules/contentMetadata/actions'
import { getDefaultPublishName } from '../actions'

const defaultState = false

export default handleActions(
  {
    [combineActions(getIsSheetNameUnique.request, getDefaultPublishName.request)]() {
      return true
    },
    [combineActions(getIsSheetNameUnique.success, getIsSheetNameUnique.failed, getIsSheetNameUnique.cancel)]() {
      return false
    },
  },
  defaultState
)
