import { useCallback, useState } from 'react'

import { FetchStatus } from '@tabeeb/enums'
import { callApiAsync } from '@tabeeb/shared/utils/requests'

const responseDefaultState = {}
function useMutation({ mutationRequest }) {
  const [status, setStatus] = useState(FetchStatus.Idle)
  const [response, setResponse] = useState(responseDefaultState)

  const reset = useCallback(() => {
    setResponse(responseDefaultState)
  }, [])

  const mutate = useCallback(
    async (payload, { onSuccess, onError }) => {
      try {
        setStatus(FetchStatus.Loading)

        const {
          response: { data },
        } = await callApiAsync(mutationRequest(payload))
        setStatus(FetchStatus.Loaded)
        setResponse(data)
        onSuccess()
      } catch (e) {
        setStatus(FetchStatus.Failed)

        if (onError) {
          onError(e)
        }
      }
    },
    [mutationRequest]
  )

  return {
    idle: status === FetchStatus.Idle,
    loading: status === FetchStatus.Loading,
    loaded: status === FetchStatus.Loaded,
    failed: status === FetchStatus.Failed,
    status,
    response,
    mutate,
    reset,
  }
}

export default useMutation
