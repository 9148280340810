import PropTypes from 'prop-types'

import { Tooltip } from '@mui/material'

const BaseStatusIcon = ({ title, icon: Icon }) => {
  return (
    <Tooltip title={title}>
      <Icon sx={{ mr: 0.75, fontSize: '14px' }} />
    </Tooltip>
  )
}

BaseStatusIcon.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
}

export default BaseStatusIcon
