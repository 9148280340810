import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as notificationActions from '../../notification/actions'
import * as tenantConfigActions from '../actions'

import * as appConfigStateActions from '../../appConfigState/actions'

function* onUpdateConfigSuccess({ response }) {
  const updatedConfig = response.data
  const appConfigState = yield select((state) => state.appConfigState)

  if (appConfigState.subdomain === updatedConfig.subdomain) {
    yield put(appConfigStateActions.onSetAppConfigState({ ...appConfigState, ...updatedConfig }))
  }
  yield put(tenantConfigActions.setConfigForUpdate(updatedConfig))
  yield put(notificationActions.onAddInfoNotification({ message: 'Config updated' }))
}

function* onUpdateConfigFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'There was an error updating the config' }))
}

function* handleUpdateConfig({ payload: { config, initialConfig } }) {
  yield put(tenantConfigActions.updateConfigRequest({ subdomain: config.subdomain, config }))
}

function* updateConfig() {
  yield all([
    takeLatest(tenantConfigActions.updateConfigSuccess, onUpdateConfigSuccess),
    takeLatest(tenantConfigActions.updateConfigFailed, onUpdateConfigFailed),
    takeLatest(tenantConfigActions.handleUpdateConfig, handleUpdateConfig),
  ])
}

export default updateConfig
