import { memo } from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material'

import UserAvatar from '@tabeeb/modules/shared/userAvatar'

const UserInfo = ({ item, avatarWrapper: AvatarWrapper, avatarWrapperProps }) => {
  return (
    <ListItem component='div' title={item.Name} disableGutters dense>
      <ListItemAvatar>
        <AvatarWrapper {...avatarWrapperProps}>
          <UserAvatar user={item} />
        </AvatarWrapper>
      </ListItemAvatar>
      <ListItemText primary={item.Name} secondary={item.IsAccountDeleted ? null : item.Email} />
    </ListItem>
  )
}

const UserInfoSkeleton = () => {
  return (
    <ListItem component='div' disableGutters dense>
      <Skeleton variant='circular' width={40} height={40} />
      <Box ml={2}>
        <Skeleton variant='text' width={200} />
        <Skeleton variant='text' width={150} />
      </Box>
    </ListItem>
  )
}

UserInfo.defaultProps = {
  avatarWrapper: ({ children }) => children,
  avatarWrapperProps: {},
}

UserInfo.propTypes = {
  item: PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string.isRequired,
    Email: PropTypes.string.isRequired,
    IsAccountDeleted: PropTypes.bool,
    AvatarUrl: PropTypes.string,
  }).isRequired,
  avatarWrapper: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]),
  avatarWrapperProps: PropTypes.instanceOf(Object),
}

export default memo(UserInfo)

export { UserInfoSkeleton }
