import { Card, CardContent, CardMedia, styled } from '@mui/material'
import { FlexContainer } from '@tabeeb/modules/shared/uikit'

export const Container = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  background: theme.palette.background.session,
  color: theme.palette.text.session,
  position: 'relative',

  '&:hover': {
    boxShadow: theme.shadows[4],
  },

  '&[data-selected="true"]': {
    outline: '2px solid #1471EB',
  },
}))

export const Preview = styled(CardMedia)(({ theme }) => ({
  height: 150,
  position: 'relative',
  backgroundSize: 'cover',

  '&[data-active="false"]': {
    opacity: theme.palette.action.disabledOpacity,
  },
}))

export const Footer = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingInline: theme.spacing(1.5),
  paddingBlock: theme.spacing(1.25),
  paddingBottom: '8px !important',
}))

export const PreviewFooter = styled(FlexContainer)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  width: '100%',
  padding: theme.spacing(1),
}))
