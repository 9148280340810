import { createAction } from 'redux-actions'

export const createApiActions = (name) => {
  const request = createAction(`${name}_REQUEST`)
  const success = createAction(`${name}_SUCCESS`)
  const failed = createAction(`${name}_FAILED`)
  const cancel = createAction(`${name}_CANCEL`)
  const resetState = createAction(`RESET_STATE_${name}`)

  return {
    type: name,
    request,
    success,
    failed,
    cancel,
    resetState,
  }
}
