import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, Skeleton } from '@mui/material'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { endOfToday, startOfTomorrow } from 'date-fns'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useCallback, useEffect, useMemo, useState } from 'react'

import FormikTextField from '@tabeeb/shared/forms/formik/formikTextField'
import {
  invalidFieldWithName,
  maxLength,
  noWhitespaces,
  requiredField,
} from '@tabeeb/shared/utils/validationErrorMessages'
import { CertificateStatusType, PeriodUnitType } from '@tabeeb/enums'
import { FormikCheckbox } from '@tabeeb/shared/forms'
import { useApiRequest, useDialogState } from '@tabeeb/shared/utils/hooks'
import { readUploadedFileAsDataUrl } from '@tabeeb/modules/fileUploads/services'
import { DragAndDropFiles, PdfViewer } from '@tabeeb/uikit'
import usePdfViewer from '@tabeeb/shared/uikit/hooks/usePdfViewer'
import { mimeTypes } from '@tabeeb/shared/uikit/constants'

import AutocompleteCertificateType from '../AutocompleteCertificateType'
import CertificatesImageViewer from '../CertificatesImageViewer'
import { getCertificateById } from '../../actions'
import AddEditCertificateFormSkeleton from '../AddEditCertificateFormSkeleton'
import LoadingImage from '../LoadingImage'
import AutocompleteLocations from '../AutocompleteLocations'

const validationSchema = Yup.object().shape({
  SerialNumber: Yup.string().strict().trim(noWhitespaces).max(256, maxLength(256)),
  Authority: Yup.string().strict().trim(noWhitespaces).max(256, maxLength(256)),
  ValidFrom: Yup.date()
    .min(new Date(Date.UTC(1900, 0, 0)), invalidFieldWithName('Issue date'))
    .typeError(invalidFieldWithName('Valid from'))
    .max(endOfToday(), invalidFieldWithName('Valid from'))
    .required(requiredField),
  ExpirationDate: Yup.date()
    .min(startOfTomorrow(), invalidFieldWithName('Expiration date'))
    .typeError(invalidFieldWithName('Valid till'))
    .required(requiredField),
  Type: Yup.object()
    .shape({
      Id: Yup.number().required(requiredField),
      CategoryId: Yup.number().required(requiredField),
      ScopeId: Yup.number().required(requiredField),
      Name: Yup.string().required(requiredField),
    })
    .typeError('Invalid type')
    .required(requiredField),
  Photo: Yup.mixed()
    .nullable()
    .when('PictureUrl', {
      is: (pictureUrl) => !pictureUrl?.length,
      then: Yup.mixed().required(requiredField),
    }),
})

const initialValues = {
  SerialNumber: '',
  Authority: '',
  ValidFrom: '',
  CategoryId: '',
  ScopeId: '',
  Type: null,
  ExpirationDate: '',
  Photo: null,
  Locations: [],
}

const AddEditCertificateDialog = ({
  open,
  onClose,
  selectedCertificateId,
  onSubmit,
  onExited,
  getAllCertificateTypesResponse,
  getAllTypesLoading,
  setCertificateTypesSearch,
  getLocationsResponse,
  getLocationsLoading,
}) => {
  const editMode = Boolean(selectedCertificateId)

  const [selectedCertificate, setSelectedCertificate] = useState(null)
  const payload = useMemo(() => ({ id: selectedCertificateId }), [selectedCertificateId])
  const {
    response: selectedCertificateResponse,
    loading: getCertificateByIdLoading,
    abortRequest,
  } = useApiRequest({
    defaultResponse: {},
    request: getCertificateById.request,
    payload,
    enabled: open && editMode,
  })

  useEffect(() => {
    setSelectedCertificate(selectedCertificateResponse)
  }, [selectedCertificateResponse])
  useEffect(() => {
    return () => {
      abortRequest()
      setSelectedCertificate(null)
    }
  }, [abortRequest])

  const [openImageViewer, onOpenImageViewer, onCloseImageViewer] = useDialogState()
  const imagePreviewDefaultState = useMemo(() => ({ src: null, mimeType: null }), [])
  const [imagePreview, setImagePreview] = useState(imagePreviewDefaultState)

  const handleCloseDialog = useCallback(() => {
    onClose()
  }, [onClose])

  const [numberOfPdfPages, setNumberOfPdfPages, pageNumber, nextPageClick, previousPageClick, setInitialPage] =
    usePdfViewer()

  const pdfViewerProps = useMemo(
    () => ({
      numberOfPdfPages,
      setNumberOfPdfPages,
      nextPageClick,
      previousPageClick,
      pageNumber,
      setInitialPage,
    }),
    [nextPageClick, numberOfPdfPages, pageNumber, previousPageClick, setInitialPage, setNumberOfPdfPages]
  )

  const editCertificateLoading = useMemo(
    () => Boolean(selectedCertificateId) && (getCertificateByIdLoading || !selectedCertificate?.Id),
    [getCertificateByIdLoading, selectedCertificate?.Id, selectedCertificateId]
  )

  const [{ height: pdfHeight, width: pdfWidth }, setCurrentPdfDimensions] = useState({ height: 0, width: 0 })
  const pdfDimensionProps = useMemo(() => {
    const isVerticalPdf = pdfHeight > pdfWidth
    const alignmentProp = isVerticalPdf ? { pageHeight: 400 } : { pageWidth: 560 }
    const pdfPageParentDivSx = {
      border: 1,
      maxWidth: '562px',
      height: '402px',
      ...(!isVerticalPdf && { width: '562px' }),
    }

    return {
      pdfPageParentDivSx,
      ...alignmentProp,
    }
  }, [pdfHeight, pdfWidth])

  const calculateValidToDate = (validFrom, defaultValidityPeriod, defaultValidityPeriodUnitId) => {
    if (!validFrom) {
      return validFrom
    }

    const result = new Date(validFrom)
    switch (defaultValidityPeriodUnitId) {
      case PeriodUnitType.Day:
        result.setDate(result.getDate() + defaultValidityPeriod)
        break
      case PeriodUnitType.Month:
        result.setMonth(result.getMonth() + defaultValidityPeriod)
        break
      case PeriodUnitType.Year:
        result.setFullYear(result.getFullYear() + defaultValidityPeriod)
        break
      default:
        break
    }

    return result
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      TransitionProps={{
        onExited: () => {
          onExited()
          setImagePreview(imagePreviewDefaultState)
          setInitialPage()
          onCloseImageViewer()
        },
      }}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>{editMode ? 'Edit certificate' : 'Create certificate'}</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={
          editMode && !editCertificateLoading
            ? {
                ...selectedCertificate,
                Type: {
                  Id: selectedCertificate.TypeId,
                  Name: selectedCertificate.TypeName,
                  CategoryId: selectedCertificate.CategoryId,
                  ScopeId: selectedCertificate.ScopeId,
                },
              }
            : initialValues
        }
        onSubmit={(values) => {
          onSubmit({
            values: {
              ...values,
              TypeId: values.Type.Id,
              Type: null,
              ...(values.Locations.length > 0 && { LocationIds: values.Locations.map((l) => l.Id) }),
            },
            editMode,
          })
          setImagePreview(imagePreviewDefaultState)
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          isValid,
          dirty,
          errors,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          setTouched,
          setValues,
        }) => (
          <Form autoComplete='off'>
            <DialogContent>
              <Grid container direction='row' spacing={2}>
                {editMode && editCertificateLoading ? (
                  <AddEditCertificateFormSkeleton />
                ) : (
                  <>
                    {(imagePreview.src != null || editMode) && (
                      <Grid item xs={12}>
                        {imagePreview.mimeType === mimeTypes.pdf ||
                        (editMode && selectedCertificate?.PictureUrl.includes('.pdf')) ? (
                          <PdfViewer
                            pdfFileSource={imagePreview.src || selectedCertificate.PictureUrl}
                            numberOfPdfPages={numberOfPdfPages}
                            setNumberOfPdfPages={setNumberOfPdfPages}
                            nextPageClick={nextPageClick}
                            previousPageClick={previousPageClick}
                            pageNumber={pageNumber}
                            setInitialPage={setInitialPage}
                            onRootElementClick={onOpenImageViewer}
                            setCurrentPdfDimensions={setCurrentPdfDimensions}
                            {...pdfDimensionProps}
                          />
                        ) : (
                          <Box onClick={onOpenImageViewer} sx={{ cursor: 'pointer' }}>
                            <LoadingImage imgSrc={imagePreview.src || selectedCertificate.PictureUrl} />
                          </Box>
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Field name='SerialNumber' label='Serial number' component={FormikTextField} />
                    </Grid>
                    <Grid item xs={12}>
                      <Field name='Authority' label='Authority' component={FormikTextField} />
                    </Grid>
                    <Grid item xs={12}>
                      <AutocompleteCertificateType
                        getAllTypesResponse={getAllCertificateTypesResponse}
                        onChange={(_, newValue, reason) => {
                          setFieldTouched('Type')
                          switch (reason) {
                            case 'selectOption': {
                              const newValues = {
                                Type: newValue,
                                ScopeId: newValue.ScopeId,
                                CategoryId: newValue.CategoryId,
                              }

                              if (
                                values.ValidFrom &&
                                newValue.DefaultValidityPeriodUnitId &&
                                newValue.DefaultValidityPeriod
                              ) {
                                const validTo = calculateValidToDate(
                                  values.ValidFrom,
                                  newValue.DefaultValidityPeriod,
                                  newValue.DefaultValidityPeriodUnitId
                                )

                                newValues.ExpirationDate = validTo
                                setFieldTouched('ExpirationDate')
                              }

                              setValues({ ...values, ...newValues }, true)
                              break
                            }

                            case 'removeOption':
                            case 'clear':
                            default:
                              setFieldValue('Type', null)
                              setFieldValue('CategoryId', '')
                              setFieldValue('ScopeId', '')
                              break
                          }
                        }}
                        onInputChange={setCertificateTypesSearch}
                        scope={Number(values.ScopeId)}
                        category={Number(values.CategoryId)}
                        value={values.Type}
                        getAllTypesLoading={getAllTypesLoading}
                        autocompleteProps={{
                          name: 'Type',
                          required: true,
                          onBlur: () => setFieldTouched('Type'),
                        }}
                        inputProps={{
                          label: 'Type',
                          variant: 'outlined',
                          error: Boolean(touched.Type) && Boolean(errors.Type),
                          helperText: Boolean(touched.Type) && errors.Type,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        id='AddEditCertificateDialog valid from picker'
                        autoOk
                        inputVariant='outlined'
                        format='MM/dd/yyyy'
                        name='ValidFrom'
                        label='Valid from'
                        value={(values.ValidFrom && new Date(values.ValidFrom)) || null}
                        onChange={(date) => {
                          const newValues = { ValidFrom: date }

                          if (
                            values.Type &&
                            values.Type.DefaultValidityPeriodUnitId &&
                            values.Type.DefaultValidityPeriod
                          ) {
                            const validTo = calculateValidToDate(
                              date,
                              values.Type.DefaultValidityPeriod,
                              values.Type.DefaultValidityPeriodUnitId
                            )
                            newValues.ExpirationDate = validTo
                            setFieldTouched('ExpirationDate')
                          }

                          setFieldTouched('ValidFrom')
                          setValues({ ...values, ...newValues }, true)
                        }}
                        onBlur={(e) => setFieldTouched(e.target.name)}
                        error={Boolean(touched.ValidFrom) && Boolean(errors.ValidFrom)}
                        helperText={Boolean(touched.ValidFrom) && errors.ValidFrom}
                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                        title='The required date format is MM/DD/YYYY'
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ pr: '0px !important' }}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        id='AddEditCertificateDialog expiration date picker'
                        autoOk
                        inputVariant='outlined'
                        format='MM/dd/yyyy'
                        name='ExpirationDate'
                        label='Valid till'
                        value={(values.ExpirationDate && new Date(values.ExpirationDate)) || null}
                        onChange={(date) => {
                          setFieldTouched('ExpirationDate')
                          setFieldValue('ExpirationDate', date)
                        }}
                        onBlur={(e) => setFieldTouched(e.target.name)}
                        error={Boolean(touched.ExpirationDate) && Boolean(errors.ExpirationDate)}
                        helperText={Boolean(touched.ExpirationDate) && errors.ExpirationDate}
                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                        title='The required date format is MM/DD/YYYY'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AutocompleteLocations
                        getLocationsResponse={getLocationsResponse}
                        loading={getLocationsLoading}
                        inputProps={{
                          label: 'Locations',
                          variant: 'outlined',
                          error: Boolean(touched.Locations) && Boolean(errors.Locations),
                          helperText: Boolean(touched.Locations) && errors.Locations,
                        }}
                        autocompleteProps={{
                          name: 'Locations',
                          required: false,
                          multiple: true,
                          disableCloseOnSelect: true,
                          onBlur: () => setFieldTouched('Locations'),
                          limitTags: 5,
                        }}
                        value={values.Locations}
                        onChange={(_, newValues, reason) => {
                          setFieldTouched('Locations')
                          switch (reason) {
                            case 'selectOption':
                            case 'removeOption':
                              setFieldValue('Locations', newValues)
                              break
                            case 'clear':
                            default:
                              setFieldValue('Locations', [])
                              break
                          }
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  {editCertificateLoading ? (
                    <Skeleton variant='rounded' height={150} />
                  ) : (
                    <DragAndDropFiles
                      file={values.Photo}
                      onDropAccepted={async (file) => {
                        setFieldValue('Photo', file[0])
                        setFieldTouched('Photo')
                        const result = await readUploadedFileAsDataUrl(file[0])
                        setImagePreview({ src: result, mimeType: file[0].type })
                      }}
                      onClearState={() => {
                        setFieldValue('Photo', null)
                        setImagePreview(imagePreviewDefaultState)
                      }}
                      placeholder='Please select a certificate photo to upload'
                      fileUploadButtonText='Upload photo'
                      acceptMimeFormats={{
                        [mimeTypes.jpeg]: ['.jpeg', '.jpg'],
                        [mimeTypes.png]: [],
                        [mimeTypes.bmp]: [],
                        [mimeTypes.pdf]: ['.pdf'],
                      }}
                      acceptedFormatDisplayStrings={['png', 'jpg', 'jpeg', 'bmp', 'pdf']}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant='outlined' disableElevation onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button variant='outlined' disableElevation color='primary' type='submit' disabled={!(isValid && dirty)}>
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
      {!(editMode && editCertificateLoading) && (
        <CertificatesImageViewer
          open={openImageViewer}
          onClose={onCloseImageViewer}
          slides={[
            {
              src: `${imagePreview?.src || selectedCertificate?.PictureUrl}`,
              mimeType: `${
                editMode
                  ? selectedCertificate?.PictureUrl.includes('.pdf')
                    ? mimeTypes.pdf
                    : ''
                  : imagePreview.mimeType
              }`,
              download: { url: selectedCertificate?.PictureUrl },
            },
          ]}
          plugins={{ download: editMode }}
          pdfViewerProps={pdfViewerProps}
        />
      )}
    </Dialog>
  )
}

AddEditCertificateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedCertificateId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  getAllCertificateTypesResponse: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number,
      CategoryId: PropTypes.number,
      ScopeId: PropTypes.number,
    })
  ),
  getAllTypesLoading: PropTypes.bool.isRequired,
  setCertificateTypesSearch: PropTypes.func.isRequired,
  getLocationsResponse: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      CountryId: PropTypes.number.isRequired,
      Country: PropTypes.shape({
        Id: PropTypes.number.isRequired,
        Name: PropTypes.string.isRequired,
      }),
    })
  ),
  getLocationsLoading: PropTypes.bool.isRequired,
}

export default AddEditCertificateDialog
