import { Paper, styled, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import VersionsList from '../VersionsList'

const VersionsListSection = ({
  disabled,
  loading,
  versions,
  selectedVersionId,
  onSelectVersion,
  VersionListItemComponent,
}) => {
  return (
    <SectionContainer variant='outlined'>
      <Typography p={2} pb={1} variant='h6' fontWeight={700} noWrap>
        History
      </Typography>
      <SectionContent>
        <VersionsList
          loading={loading}
          disabled={disabled}
          versions={versions}
          selectedVersionId={selectedVersionId}
          onSelectContractVersion={onSelectVersion}
          VersionListItemComponent={VersionListItemComponent}
        />
      </SectionContent>
    </SectionContainer>
  )
}

VersionsListSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    })
  ).isRequired,
  selectedVersionId: PropTypes.number,
  onSelectVersion: PropTypes.func.isRequired,
  VersionListItemComponent: PropTypes.elementType.isRequired,
}

const SectionContainer = styled(Paper)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
}))

const SectionContent = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  paddingInline: theme.spacing(1),
}))

export default VersionsListSection

export { SectionContainer, SectionContent }
