import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDropzone } from 'react-dropzone'

import './styles.less'

import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import ContentTrashBin from '@tabeeb/modules/contentTrashBin'
import Navbar from '@tabeeb/modules/sessionTabs'
import AIReportButton from '@tabeeb/modules/aiReport/components/AIReportButton'
import MetadataButton from '@tabeeb/modules/contentMetadata/components/MetadataButton'
import { getIsExportImagesRecipientsDialogOpen, getIsExportPdfRecipientsDialogOpen } from '../../selectors'

import FileUploads from '../../../fileUploads'

import DeleteDialogContainer from '../../containers/DeleteDialogContainer'
import GalleryListContainer from '../../containers/GalleryListContainer'
import ControlsPanel from '../ControlsPanel'
import PdfSearchResultsContainer from '../../containers/PdfSearchResultsContainer'
import SearchBoxContainer from '../../containers/SearchBoxContainer'
import ExportImagesDialogContainer from '../../containers/ExportImagesDialogContainer'
import ExportAsPdfOptionsDialogContainer from '../../containers/ExportAsPdfOptionsDialogContainer'
import ExportCustomVideoOptionsDialogContainer from '../../containers/ExportCustomVideoOptionsDialogContainer'
import GalleryNavbarContainer from '../../containers/GalleryNavbarContainer'
import DeleteSessionFolderDialogContainer from '../../containers/DeleteSessionFolderDialogContainer'
import ExportGalleryOptionsDialogContainer from '../../containers/ExportGalleryOptionsDialogContainer'
import ExportPdfRecipientsContainer from '../../containers/ExportPdfRecipientsContainer'
import ExportImagesRecipientsContainer from '../../containers/ExportImagesRecipientsContainer'
import ExpandButtonContainer from '../../containers/ExpandButtonContainer'
import CreateFolderButton from '../CreateFolderButton'
import ShareSessionOptionsButton from '../ShareSessionOptionsButton'
import SpatialModelAllPagesButton from '../SpatialModelAllPagesButton'
import CustomVideoOptionsButton from '../CustomVideoOptionsButton'

const GalleryToolbar = ({
  currentPanel,
  showPdfSearchResults,
  listRef,
  setSelectedItemRef,
  isExpanded,
  isDragAvailable,
  handleDropFilesToGallery,
}) => {
  const isExportPdfRecipientsDialogOpen = useSelector(getIsExportPdfRecipientsDialogOpen)
  const isExportImagesRecipientsDialogOpen = useSelector(getIsExportImagesRecipientsDialogOpen)

  const galleryListProps = { listRef, setSelectedItemRef, isDragAvailable }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleDropFilesToGallery, noClick: true })

  return (
    <section
      className={classNames('gallery-toolbar', {
        expanded: (currentPanel === TabPanel.Gallery || currentPanel === TabPanel.FileUploads) && isExpanded,
      })}
    >
      <ExpandButtonContainer />
      <div className={classNames('gallery', { hide: currentPanel !== TabPanel.FileUploads }, { expanded: isExpanded })}>
        <FileUploads />
      </div>
      <div className={classNames('gallery', { hide: currentPanel !== TabPanel.TrashBin }, { expanded: isExpanded })}>
        <ContentTrashBin />
      </div>
      <div
        {...getRootProps()}
        className={classNames('gallery', { hide: currentPanel !== TabPanel.Gallery }, { expanded: isExpanded })}
      >
        {isDragAvailable && isDragActive && (
          <div className={classNames('drag-and-drop-container', { visible: isDragActive })}>
            <input {...getInputProps()} />
            <p className='drag-and-drop-label'>Drop the files here</p>
          </div>
        )}
        <GalleryNavbarContainer />
        <MetadataButton />
        <AIReportButton />
        <div className='gallery-export-options-buttons'>
          <CustomVideoOptionsButton />
          <SpatialModelAllPagesButton />
          <ShareSessionOptionsButton />
        </div>
        <CreateFolderButton />
        <section className='container-scrollable gallery-scrollable' ref={listRef}>
          <SearchBoxContainer />
          <DeleteDialogContainer />
          <DeleteSessionFolderDialogContainer />
          <ExportImagesDialogContainer />
          <ExportAsPdfOptionsDialogContainer />
          <ExportCustomVideoOptionsDialogContainer />
          <ExportGalleryOptionsDialogContainer />
          {!showPdfSearchResults && <GalleryListContainer {...galleryListProps} />}
          {showPdfSearchResults && <PdfSearchResultsContainer />}
          {isExportPdfRecipientsDialogOpen && <ExportPdfRecipientsContainer />}
          {isExportImagesRecipientsDialogOpen && <ExportImagesRecipientsContainer />}
        </section>
        <ControlsPanel />
      </div>
      <Navbar location={TabPanelLocation.Right} />
    </section>
  )
}

GalleryToolbar.propTypes = {
  currentPanel: PropTypes.string,
  showPdfSearchResults: PropTypes.bool,
  listRef: PropTypes.object.isRequired,
  setSelectedItemRef: PropTypes.func.isRequired,
  handleDropFilesToGallery: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isDragAvailable: PropTypes.bool.isRequired,
}

export default GalleryToolbar
