import sheetType from '@tabeeb/modules/contentMetadata/services/sheetType'

const contentMetadataRequests = {
  getContentMetadataTabsRequest: ({ contentId }) => ({
    url: `spreadsheet-service/api/spreadsheet`,
    method: 'get',
    params: { EntityId: contentId, Type: sheetType.Content },
  }),
  getContentMetadataRequest: ({ contentId, tabId, skip = 0, take = 100 }) => ({
    url: `spreadsheet-service/api/contentMetadata/${contentId}/${tabId}`,
    method: 'get',
    params: {
      skip,
      take,
    },
  }),
  getContentMetadataRowAmountRequest: ({ contentId, tabId }) => ({
    url: `spreadsheet-service/api/contentMetadata/${contentId}/${tabId}/rowAmount`,
    method: 'get',
  }),
  addRowRequest: ({ item, tabId }) => ({
    url: `spreadsheet-service/api/contentMetadata/${tabId}/rows`,
    method: 'post',
    data: { Metadata: JSON.stringify([item.values]) },
  }),
  updateRowRequest: ({ item, tabId }) => ({
    url: `spreadsheet-service/api/contentMetadata/${tabId}`,
    method: 'put',
    data: { Metadata: JSON.stringify([{ rowId: item.rowId, newValues: item.values }]) },
  }),
  deleteRowRequest: ({ itemId, tabId }) => ({
    url: `spreadsheet-service/api/contentMetadata/${tabId}/deleteRows`,
    method: 'post',
    data: { RowIds: [itemId] },
  }),
  publishContentMetadataRequest: (tabId) => ({
    url: `spreadsheet-service/api/contentMetadata/publish/${tabId}`,
    method: 'post',
  }),
  renameContentMetadataRequest: ({ Id, Name }) => ({
    url: `spreadsheet-service/api/contentMetadata/${Id}/rename`,
    method: 'put',
    data: { Name },
  }),
  sendForTableRecognitionRequest: (data) => ({
    url: `spreadsheet-service/api/spreadsheet/recognize`,
    method: 'post',
    data,
  }),
  exportContentMetadataRequest: (data) => ({
    url: `spreadsheet-service/api/spreadsheet/export`,
    method: 'post',
    data,
  }),
  getIsSheetNameUniqueRequest: ({ contentId, name }) => ({
    url: `spreadsheet-service/api/spreadsheet/isUnique`,
    method: 'get',
    params: { EntityId: contentId, Type: 1, Name: name },
  }),
  updateSheetRequest: ({ sheetId, updateSheetModel }) => ({
    url: `spreadsheet-service/api/spreadsheet/${sheetId}`,
    method: 'put',
    data: updateSheetModel,
  }),
  importSheetsRequest: (data) => ({
    url: `spreadsheet-service/api/spreadsheet/import`,
    method: 'post',
    data,
  }),
}

export default contentMetadataRequests
