export const getIsAIReportDialogOpen = (state) => state.AIReport.isOpen
export const getAIReportAiObjects = (state) => state.AIReport.aiObjects
export const getAIReport = (state) => state.AIReport.analysis

export const getSelectedAiObjectId = (state) => state.AIReport.selectedAiObject.aiObjectId

export const getSelectedTemplate = (state) => state.AIReport.selectedTemplate

export const getIsInPublishMode = (state) => state.AIReport.isInPublishMode
export const getAreAIObjectsLoading = (state) => state.AIReport.areObjectsLoading
export const getIsAnalysisLoading = (state) => state.AIReport.isAnalysisLoading

export const getTemplates = (state) => state.AIReport.publishTemplates
export const getIsRefreshNeeded = (state) => state.AIReport.isRefreshNeeded

export const getIsPublishSettingsDialogOpen = (state) => state.AIReport.isPublishSettingsDialogOpen
export const getDefaultPublishNameSelector = (state) => state.AIReport.defaultPublishName
export const getIsUniqueNameCheckInProgress = (state) => state.AIReport.isUniqueNameCheckInProgress
export const getIsPublishInProgress = (state) => state.AIReport.isPublishInProgress
