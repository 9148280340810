import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Popover } from '@material-ui/core'

import { isFireFoxBrowser } from '@tabeeb/modules/shared/browser'
import { getMe } from '@tabeeb/modules/account/selectors'
import UserCertificatesDialog from '@tabeeb/modules/certificates/components/UserCertificatesDialog'
import SoundSettings from '../SoundSettings'
import VideoSettings from '../VideoSettings'
import UserOptionButton from '../UserOptionButton'
import HealthInfoDialog from '../../../modules/healthData/components/HealthInfoDialog'

import './styles.less'

const CurrentUserOptionsMenu = ({
  anchorEl,
  isOpen,
  onClose,
  isDeviceMenuVisible,
  isHealthInfoOptionsVisible,
  isCertificatesMenuVisible,
}) => {
  const [soundSettingOpened, setSoundSettingOpened] = useState(false)
  const [videoSettingOpened, setVideoSettingOpened] = useState(false)
  const [healthDataOpened, setHealthDataOpened] = useState(false)
  const [certificatesOpened, setCertificatesOpened] = useState(false)

  const currentUser = useSelector(getMe)

  const handleSoundSettingsOpen = useCallback(() => {
    setSoundSettingOpened(true)
    onClose()
  }, [])

  const handleSoundSettingsClose = useCallback(() => {
    setSoundSettingOpened(false)
  }, [])

  const handleVideoSettingsOpen = useCallback(() => {
    setVideoSettingOpened(true)
    onClose()
  }, [])

  const handleVideoSettingsClose = useCallback(() => {
    setVideoSettingOpened(false)
  }, [])

  const handleHealthDataOpen = useCallback(() => {
    setHealthDataOpened(true)
    onClose()
  }, [])

  const handleHealthDataClose = useCallback(() => {
    setHealthDataOpened(false)
  }, [])

  const handleCertificatesOpen = useCallback(() => {
    setCertificatesOpened(true)
    onClose()
  }, [])

  const handleCertificatesClose = useCallback(() => {
    setCertificatesOpened(false)
  }, [])

  return (
    <>
      <Popover
        className='user-options-controls-popover'
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <div className='user-options-controls'>
          {isDeviceMenuVisible && (
            <>
              <UserOptionButton
                onClick={handleSoundSettingsOpen}
                buttonText='Audio settings'
                disabled={isFireFoxBrowser}
              />
              <UserOptionButton onClick={handleVideoSettingsOpen} buttonText='Video settings' />
            </>
          )}
          {isHealthInfoOptionsVisible && (
            <UserOptionButton onClick={handleHealthDataOpen} buttonText='Show Health Data' />
          )}
          {isCertificatesMenuVisible && (
            <UserOptionButton onClick={handleCertificatesOpen} buttonText='Show Certificates' />
          )}
        </div>
      </Popover>
      <SoundSettings isOpen={soundSettingOpened} anchorEl={anchorEl} onClose={handleSoundSettingsClose} />
      <VideoSettings isOpen={videoSettingOpened} anchorEl={anchorEl} onClose={handleVideoSettingsClose} />
      <HealthInfoDialog
        isOpen={healthDataOpened}
        anchorEl={anchorEl}
        onClose={handleHealthDataClose}
        userId={currentUser.IdentityGuid}
      />
      <UserCertificatesDialog user={currentUser} personal onClose={handleCertificatesClose} open={certificatesOpened} />
    </>
  )
}

CurrentUserOptionsMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  isDeviceMenuVisible: PropTypes.bool.isRequired,
  isHealthInfoOptionsVisible: PropTypes.bool.isRequired,
  isCertificatesMenuVisible: PropTypes.bool.isRequired,
}

export default CurrentUserOptionsMenu
