import { FetchStatus } from '@tabeeb/enums'
import { createSelector } from 'reselect'

export const getTenantPermissions = (state) => state.permissions.tenant || []
export const getSessionPermissions = (state) => state.permissions.session.value || []
export const getSessionPermissionsFetchStatus = (state) => state.permissions.session.status || FetchStatus.Idle
export const getIsSessionPermissionsLoaded = (state) => getSessionPermissionsFetchStatus(state) === FetchStatus.Loaded

export const hasSessionPermission = createSelector(
  [getSessionPermissions, (_, permission) => permission],
  (permissions, permission) => {
    if (permission === null) {
      return true
    }
    permissions = permissions?.Permissions ?? permissions
    return permissions.includes(permission)
  }
)

export const hasTenantPermission = createSelector(
  [getTenantPermissions, (_, permission) => permission],
  (permissions, permission) => {
    if (permission === null) {
      return true
    }
    permissions = permissions?.Permissions ?? permissions
    return permissions.includes(permission)
  }
)
