export default (theme) => {
  return {
    arrow: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
      color: theme.palette.text.secondary,
      background: 'linear-gradient(45deg, rgba(255, 255, 255, 0.5), white)',
      width: '12px',
      height: '12px',
      cursor: 'nw-resize',
      transform: 'rotate(90deg)',
    },
  }
}
