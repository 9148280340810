import { combineActions, handleActions } from 'redux-actions'

import { getAiObjectAnalysis } from '../actions'

const defaultState = false

export default handleActions(
  {
    [getAiObjectAnalysis.request]() {
      return true
    },
    [combineActions(getAiObjectAnalysis.success, getAiObjectAnalysis.failed, getAiObjectAnalysis.cancel)]() {
      return false
    },
  },
  defaultState
)
