import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import {
  withStyles,
  Typography,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'

import { DIALOG_DEFAULT_SIZE, MIN_DIALOG_SIZE } from '@tabeeb/modules/shared/uikit/components/ResizableDialog/constants'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { PageFolderAccessViewType } from '@tabeeb/enums'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { ResizableDialog } from '@tabeeb/uikit'
import EditFolderDialogUserSelect from '../EditFolderDialogUserSelect'
import {
  closeEditFolderDialog,
  getContentUsersSessionFolderPermissions,
  renameSessionFolderRequest,
  setContentUsersPageFolderPermissions,
  setPageFolderPermissions,
  updateSessionFolderPrivacy,
} from '../../actions'
import { getContentUsersWithFolderPermissions, getEditingFolder, getIsPermissionsRequestLoading } from '../../selectors'

import styles from './styles'

const defaultDialogSize = {
  height: 500,
  width: DIALOG_DEFAULT_SIZE.width,
}

const EditFolderDialog = ({ classes }) => {
  const dispatch = useDispatch()

  const folder = useSelector(getEditingFolder)
  const currentUserId = useSelector(getCurrentUserId)

  const usersWithPermissions = useSelector(getContentUsersWithFolderPermissions)

  const usersExceptCurrent = usersWithPermissions.filter((user) => user.userId !== currentUserId)

  const isOpen = Boolean(folder)

  useEffect(() => {
    if (!isOpen) {
      return
    }

    if (folder.IsPrivate) {
      dispatch(getContentUsersSessionFolderPermissions.request({ folderId: folder.Id }))
    }
  }, [isOpen, folder, dispatch])

  const isPermissionsRequestLoading = useSelector(getIsPermissionsRequestLoading)
  const isLoading = !folder || isPermissionsRequestLoading

  let accessType
  if (!folder?.IsPrivate) {
    accessType = PageFolderAccessViewType.Public
  } else if (usersExceptCurrent.length) {
    accessType = PageFolderAccessViewType.Shared
  } else {
    accessType = PageFolderAccessViewType.Private
  }

  const initialValues = useMemo(
    () => ({
      folderName: folder?.Name,
      accessType,
      permissionsSelect: usersExceptCurrent,
    }),
    [folder, accessType, usersExceptCurrent]
  )

  const handleClose = () => {
    dispatch(closeEditFolderDialog())
  }

  const handleSaveChanges = ({ folderName, accessType, permissionsSelect }, { resetForm }) => {
    dispatch(setContentUsersPageFolderPermissions(permissionsSelect))

    if (folderName !== initialValues.folderName) {
      const model = { Id: folder.Id, Name: folderName }
      dispatch(renameSessionFolderRequest(model))
    }

    if (accessType !== initialValues.accessType) {
      dispatch(
        updateSessionFolderPrivacy.request({
          FolderId: folder.Id,
          IsPrivate: accessType !== PageFolderAccessViewType.Public,
        })
      )
    }

    if (accessType !== PageFolderAccessViewType.Public) {
      const permissionModels = permissionsSelect.map((permission) => ({
        userId: permission.userId,
        permissions: accessType === PageFolderAccessViewType.Private ? [] : permission.permissions,
      }))

      dispatch(setPageFolderPermissions.request({ folderId: folder.Id, permissionModels }))
    }

    resetForm({ values: { folderName, accessType, permissionsSelect } })
    dispatch(closeEditFolderDialog())
  }

  return (
    <ResizableDialog
      open={isOpen}
      onClose={handleClose}
      header={
        <div className={classes.header}>
          <Typography className={classes.title}>Folder Settings</Typography>
        </div>
      }
      content={
        isLoading ? (
          <CircularProgressBar />
        ) : (
          <Formik initialValues={initialValues} onSubmit={handleSaveChanges}>
            {({ isSubmitting, dirty, isValid, values, handleChange, errors, touched }) => (
              <Form autoComplete='off' style={{ height: '100%' }}>
                <div className={classes.body}>
                  <TextField
                    name='folderName'
                    onChange={handleChange}
                    value={values.folderName}
                    error={touched.folderName && Boolean(errors.folderName)}
                    helperText={touched.folderName && errors.folderName}
                    size='small'
                    variant='outlined'
                    label='Folder name'
                  />

                  <div className={classNames(classes.permissionsContainer)}>
                    <FormControl>
                      <FormLabel>Access Type</FormLabel>
                      <RadioGroup value={values.accessType} onChange={handleChange} name='accessType'>
                        <FormControlLabel
                          value={PageFolderAccessViewType.Public}
                          control={<Radio color='primary' />}
                          label={PageFolderAccessViewType.Public}
                        />
                        <FormControlLabel
                          value={PageFolderAccessViewType.Private}
                          control={<Radio color='primary' />}
                          label={PageFolderAccessViewType.Private}
                        />
                        <FormControlLabel
                          value={PageFolderAccessViewType.Shared}
                          control={<Radio color='primary' />}
                          label={PageFolderAccessViewType.Shared}
                        />
                      </RadioGroup>
                    </FormControl>

                    <Field
                      name='permissionsSelect'
                      component={EditFolderDialogUserSelect}
                      accessType={values.accessType}
                      folderId={folder.Id}
                    />
                  </div>

                  <Button
                    className={classNames(classes.button, classes.saveButton)}
                    disabled={isSubmitting || !dirty}
                    type='submit'
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )
      }
      minDialogSize={{
        height: MIN_DIALOG_SIZE.height,
        width: 416,
      }}
      defaultDialogSize={defaultDialogSize}
    />
  )
}

EditFolderDialog.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    saveButton: PropTypes.string.isRequired,
    permissionsContainer: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(EditFolderDialog)
