import { useCallback, useMemo } from 'react'
import { TextField, Autocomplete } from '@mui/material'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

const TimeZonePicker = ({ selectedTimeZone, onTimeZoneChange }) => {
  const timeZones = useMemo(() => moment.tz.names(), [])

  const handleChange = useCallback(
    (timeZone) => {
      onTimeZoneChange(timeZone)
    },
    [onTimeZoneChange]
  )

  return (
    <Autocomplete
      size='small'
      value={selectedTimeZone}
      getOptionLabel={(tz) => `${tz} (UTC${moment.tz(tz).format('Z')})`}
      onChange={(event, newValue) => {
        handleChange(newValue)
      }}
      sx={{ width: '100%' }}
      options={timeZones}
      renderInput={(params) => <TextField {...params} label='Select Time Zone' fullWidth />}
    />
  )
}

TimeZonePicker.propTypes = {
  onTimeZoneChange: PropTypes.func.isRequired,
  selectedTimeZone: PropTypes.string,
}

export default TimeZonePicker
