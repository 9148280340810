import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  CircularProgress,
  Box,
  Chip,
  Grid,
} from '@mui/material'
import { Close, Add, InfoOutlined } from '@mui/icons-material'

import { getAddPowerBIReportModalState } from '@tabeeb/modules/powerBIReports/selectors'
import { closeAddPowerBIReportModal, createPowerBIReport } from '@tabeeb/modules/powerBIReports/actions'
import SecurityModelsSelect from '@tabeeb/modules/powerBIReports/components/SecurityModelsSelect'
import { FetchStatus } from '@tabeeb/enums'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { GUIDRegexp } from '@tabeeb/modules/shared/constants'
import PreTag from '@tabeeb/modules/shared/uikit/components/PreTag'
import usePowerBIReportConfigurationCheck from '@tabeeb/modules/powerBIReports/hooks/usePowerBIReportConfigurationCheck'
import { requiredField } from '@tabeeb/modules/shared/utils/validationErrorMessages'
import { StyledTextField } from './styles'
import ReportingProviderSelect from '../../ReportingProviderSelect'
import CheckPowerBIReportConfigurationButton from '../../CheckPowerBIReportConfigurationButton'

const validationSchema = Yup.object({
  name: Yup.string().required(requiredField).notEmptyString(),
  workspaceId: Yup.string().required(requiredField).matches(GUIDRegexp, 'Must be a valid GUID.'),
  dashboardId: Yup.string().required(requiredField).matches(GUIDRegexp, 'Must be a valid GUID.'),
  reportingProviderId: Yup.number().min(1, requiredField),
  securityModel: Yup.number().min(1, requiredField),
})

const AddPowerBIReportModal = () => {
  const dispatch = useDispatch()

  const { open, fetchStatus: createFetchStatus } = useSelector(getAddPowerBIReportModalState)
  const {
    fetchStatus: validateConfigurationFetchStatus,
    errorObject,
    onInitCheck,
    onResetState: onResetConfigurationCheckState,
  } = usePowerBIReportConfigurationCheck()

  const addButtonStartIcon = useMemo(() => {
    return createFetchStatus === FetchStatus.Loading ? <CircularProgress size={18} /> : <Add color='primary' />
  }, [createFetchStatus])

  const onSubmit = useCallback(
    (values) => {
      const requestPayload = {
        name: values.name,
        reportingProviderId: values.reportingProviderId,
        powerBIWorkspaceId: values.workspaceId,
        powerBIDashboardId: values.dashboardId,
        securityModel: values.securityModel,
      }
      dispatch(createPowerBIReport.request(requestPayload))
    },
    [dispatch]
  )

  const form = useFormik({
    initialValues: {
      name: '',
      workspaceId: '',
      dashboardId: '',
      reportingProviderId: 0,
      securityModel: 0,
    },
    validationSchema,
    onSubmit,
    validateOnChange: true,
  })

  const onClose = useCallback(
    (_, reason) => {
      if (reason === 'backdropClick') {
        return
      }
      form.resetForm()
      onResetConfigurationCheckState()
      dispatch(closeAddPowerBIReportModal())
    },
    [dispatch, form, onResetConfigurationCheckState]
  )

  useEffect(() => {
    if (createFetchStatus === FetchStatus.Loaded) {
      onClose()
    }
  }, [createFetchStatus, form, onClose])

  const onProviderChange = useCallback(
    (_event, value, reason) => {
      if (reason === 'selectOption') {
        form.setValues((values) => ({ ...values, reportingProviderId: value.id }))
      } else if (reason === 'clear') {
        form.setValues((values) => ({ ...values, reportingProviderId: 0 }))
      }
      onResetConfigurationCheckState()
    },
    [form, onResetConfigurationCheckState]
  )

  const onSecurityModelsChange = useCallback(
    (_event, value, reason) => {
      onResetConfigurationCheckState()
      if (reason === 'selectOption') {
        form.setValues((values) => ({ ...values, securityModel: value.id }))
      } else if (reason === 'clear') {
        form.setValues((values) => ({ ...values, securityModel: 0 }))
      }
    },
    [form, onResetConfigurationCheckState]
  )

  const onInputChange = useCallback(
    (event) => {
      onResetConfigurationCheckState()
      form.handleChange(event)
    },
    [onResetConfigurationCheckState, form]
  )

  const onValidateConfiguration = useCallback(() => {
    const { values } = form
    onInitCheck({
      workspaceId: values.workspaceId,
      dashboardId: values.dashboardId,
      reportingProviderId: values.reportingProviderId,
      securityModel: values.securityModel,
    })
  }, [form, onInitCheck])

  const isAddButtonDisabled = useMemo(() => {
    return !form.isValid || validateConfigurationFetchStatus !== FetchStatus.Loaded
  }, [form.isValid, validateConfigurationFetchStatus])

  const onOpenInfo = useCallback(() => {
    window
      .open('https://nxteccorp.atlassian.net/wiki/external/NTZmMzBiNzgyMDA4NDBjOWFiZWZjOTMwZDcyNTA2NDI', '_blank')
      .focus()
  }, [])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Add PowerBI report</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 12,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Box component='form' autoComplete='off' sx={{ display: 'flex', flexDirection: 'column' }}>
          <StyledTextField
            required
            variant='standard'
            name='name'
            value={form.values.name}
            helperText={form.touched.name && form.errors.name}
            onChange={onInputChange}
            onBlur={form.handleBlur}
            label='Report name'
            fullWidth
            inputProps={{ maxLength: 100 }}
          />
          <ReportingProviderSelect
            inputName='reportingProviderId'
            onChange={onProviderChange}
            onBlur={form.handleBlur}
            helperText={form.touched.reportingProviderId && form.errors.reportingProviderId}
          />
          <StyledTextField
            required
            variant='standard'
            name='workspaceId'
            value={form.values.workspaceId}
            helperText={form.touched.workspaceId && form.errors.workspaceId}
            onChange={onInputChange}
            onBlur={form.handleBlur}
            label='Workspace ID'
            fullWidth
            inputProps={{ maxLength: 36 }}
          />
          <StyledTextField
            required
            variant='standard'
            name='dashboardId'
            value={form.values.dashboardId}
            helperText={form.touched.dashboardId && form.errors.dashboardId}
            onChange={onInputChange}
            onBlur={form.handleBlur}
            label='Report ID'
            fullWidth
            inputProps={{ maxLength: 36 }}
          />
          <Grid container alignItems='center'>
            <Grid item xs={11}>
              <SecurityModelsSelect
                inputName='securityModel'
                helperText={form.touched.securityModel && form.errors.securityModel}
                onChange={onSecurityModelsChange}
                onBlur={form.handleBlur}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton color='primary' onClick={onOpenInfo}>
                <InfoOutlined />
              </IconButton>
            </Grid>
          </Grid>
          {errorObject && <PreTag>{JSON.stringify(errorObject, null, 2)}</PreTag>}
        </Box>
      </DialogContent>
      <DialogActions>
        {validateConfigurationFetchStatus === FetchStatus.Loaded && (
          <Chip label='Validation success' color='success' variant='filled' sx={{ mr: 1 }} />
        )}
        {validateConfigurationFetchStatus === FetchStatus.Failed && (
          <Chip label='Failed to validate report' color='error' variant='filled' sx={{ mr: 1 }} />
        )}
        <CheckPowerBIReportConfigurationButton
          disabled={!form.isValid || !form.dirty}
          fullWidth={false}
          onClick={onValidateConfiguration}
          fetchStatus={validateConfigurationFetchStatus}
        />
        <Button
          variant='outlined'
          color='primary'
          disabled={isAddButtonDisabled}
          startIcon={addButtonStartIcon}
          onClick={form.handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddPowerBIReportModal
