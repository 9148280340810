import { put, select, takeLatest, all, takeEvery } from 'redux-saga/effects'

import { AssetType, NotificationEventType } from '@tabeeb/enums'
import * as usersSelectors from '@tabeeb/modules/../users/selectors'
import { isMobileOnly } from 'react-device-detect'
import { PAGE_VIDEO_RESTORED_MESSAGE } from '@tabeeb/modules/gallery/constants'
import * as galleryActions from '../actions'
import { convertServicePageToPluginPage } from '../../../services/dataConverter'
import * as notificationActions from '../../notification/actions'
import { signalrEvents, signalrActions, signalrConstants } from '../../signalr'
import { contentStateSelectors } from '../../shared/content'
import { getGalleryItemById, getIsPageVisibleForUser } from '../selectors'
import * as accountSelectors from '../../account/selectors'

export default function* updateOrAddGallery(isSelectAddedGalleryItem, pages, suppressMessage = false) {
  if (isMobileOnly) {
    return
  }

  const galleryList = yield select((state) => state.gallery.galleryList)
  const isPagesInGallery = galleryList.some((item) => item.id === pages[0].Id)
  yield processLoadedGalleryItems(isSelectAddedGalleryItem, pages)

  if (!suppressMessage) {
    yield showPageNotification(pages.length, isPagesInGallery)
  }
}

function* processLoadedGalleryItems(isSelectAddedGalleryItem, pages) {
  const contentId = yield select(contentStateSelectors.getContentId)
  const ownerId = yield select(contentStateSelectors.getOwnerId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  const pageToPluginPageParams = {
    contentId,
    contentOwnerId: ownerId,
    currentUserId,
  }

  for (const page of pages) {
    const convertedPage = convertServicePageToPluginPage({ ...pageToPluginPageParams, page })
    yield put(galleryActions.addGalleryItem(convertedPage))
  }

  if (isSelectAddedGalleryItem) {
    yield put(galleryActions.onSelectGalleryItem({ pageId: pages[0].Id, updateWeb: true }))
  }
}

function* showPageNotification(pageCount, isPagesInGallery) {
  if (isPagesInGallery) {
    return
  }

  let message = 'New pages added.'
  if (pageCount === 1) {
    message = isPagesInGallery ? 'A page was updated.' : 'A new page was added.'
  }
  yield put(notificationActions.onAddInfoNotification({ message }))
}

function* getGalleryItemSuccess({ payload, response }) {
  const { isSelectAddedGalleryItem = false, suppressMessage = false, pageId } = payload
  if (response.data.PageVideoRestoreRequested) {
    yield put(notificationActions.onAddInfoNotification({ message: PAGE_VIDEO_RESTORED_MESSAGE }))
  }
  const suppressMessageIfNotFirstPageOfPdf = response.data.PdfPageIndex > 1
  yield updateOrAddGallery(
    isSelectAddedGalleryItem,
    [response.data],
    suppressMessage || suppressMessageIfNotFirstPageOfPdf
  )
  yield put(galleryActions.setPageAsLoaded({ pageId }))
}

function* onPageNameChanged(action) {
  const [pageId, name] = action.payload

  yield put(galleryActions.updateGalleryItemName({ pageId, name }))
}

function* updatePageName(action) {
  const [pageId, contentId] = action.payload

  yield put(signalrActions.invokeHubAction({ method: 'UpdatePageName', args: [pageId, contentId] }))
}

function* onAssetUpdated(action) {
  const [data] = action.payload

  if (data.Type == AssetType.Thumbnail) {
    yield put(galleryActions.updateGalleryItemThumbnail({ pageId: data.PageId, thumbnailUrl: data.Url }))

    return
  }

  yield put(galleryActions.getGalleryItemRequest({ pageId: data.PageId, isSelectAddedGalleryItem: false }))
}

function* onAssetCreated(action) {
  const [asset] = action.payload

  const page = yield select((state) => getGalleryItemById(state, { id: asset.PageId }))
  if (!page) {
    return
  }

  yield put(galleryActions.updateOrAddAsset(asset))
}

function* onNotifyContent(action) {
  const [notification] = action.payload

  if (notification.eventType === NotificationEventType.PageAdded) {
    if (isMobileOnly) {
      return
    }

    let isPageVisible = true

    for (const pageId of notification.pageIds) {
      isPageVisible = yield select((state) => getIsPageVisibleForUser(state, { pageId }))

      if (isPageVisible) {
        yield put(galleryActions.getGalleryItemRequest({ pageId }))
      }
    }

    if (!notification.disableNotificationMessage && isPageVisible) {
      yield put(notificationActions.onAddInfoNotification({ message: notification.message }))
    }

    return
  }

  const isReviewer = yield select(usersSelectors.getIsCurrentUserReviewer)

  if (notification.eventType === NotificationEventType.ShowMessage && !isReviewer) {
    yield put(notificationActions.onAddInfoNotification({ message: notification.message }))
  }

  if (notification.eventType === NotificationEventType.PageDeleted) {
    yield put(galleryActions.onDeleteGalleryItem({ pageId: notification.pageId }))
    return
  }

  if (
    notification.eventType === NotificationEventType.PageUpdated ||
    notification.eventType === NotificationEventType.PageVideoRestored
  ) {
    if (notification.eventType === NotificationEventType.PageVideoRestored) {
      yield put(notificationActions.onAddInfoNotification({ message: notification.message }))
    }

    const isPageVisible = yield select((state) => getIsPageVisibleForUser(state, { pageId: notification.pageId }))

    if (isPageVisible) {
      yield put(galleryActions.getGalleryItemRequest({ pageId: notification.pageId, suppressMessage: true }))
    }
  }

  if (notification.eventType === NotificationEventType.PageThumbnailUpdated) {
    yield put(
      galleryActions.updateGalleryItemThumbnail({
        pageId: notification.pageId,
        thumbnailUrl: notification.thumbnailUrl,
      })
    )
  }
}

export function* updateOrAddGalleryPages() {
  yield all([
    takeLatest(galleryActions.getGalleryItemSuccess, getGalleryItemSuccess),
    // signalr
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onPageNameChanged, onPageNameChanged),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].updatePageName, updatePageName),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onAssetUpdated, onAssetUpdated),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onNotifyContent, onNotifyContent),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onAssetCreated, onAssetCreated),
  ])
}
