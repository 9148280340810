import { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as rawContentActions from '@tabeeb/shared/content/actions'
import * as contentStateSelectors from '@tabeeb/shared/content/selectors'
import StartCallButton from '../components/StartCallButton'

import * as connectionActions from '../../presentation/actions/connection'

import * as presentationSelectors from '../../presentation/selectors'
import * as navbarSelectors from '../selectors'

class StartCallButtonContainer extends Component {
  state = {
    isSessionLeaveDialogOpened: false,
    confirmationDialogText: '',
  }

  _handleCancelLeaveSession = () => {
    this.setState({ isSessionLeaveDialogOpened: false })
  }

  _handleLeaveButton = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    const {
      contentActions: { leaveContent },
    } = this.props

    leaveContent({ ignoreCallWarning: true })
  }

  _handleCallButton = (e) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    const {
      isCallStarted,
      connectionActions: { notifyStartCall },
      contentActions: { leaveContent },
    } = this.props

    if (!isCallStarted) {
      notifyStartCall()
      return
    }

    leaveContent({ ignoreCallWarning: true })
  }

  render() {
    const { disabled, isStartCallBtn, isEndCallBtn, isLeaveCallBtn, isCallProcessing } = this.props

    const props = {
      disabled,
      isStartCallBtn,
      isEndCallBtn,
      isLeaveCallBtn,
      showCallBtn: isStartCallBtn || isEndCallBtn || isLeaveCallBtn || isCallProcessing,
      handleCallButton: this._handleCallButton,
      handleLeaveButton: this._handleLeaveButton,
    }

    return <StartCallButton {...props} />
  }
}

StartCallButtonContainer.propTypes = {
  connectionActions: PropTypes.shape({
    notifyStartCall: PropTypes.func.isRequired,
    disconnect: PropTypes.func.isRequired,
  }).isRequired,
  contentActions: PropTypes.shape({
    leaveContent: PropTypes.func.isRequired,
  }).isRequired,
  isCallStarted: PropTypes.bool.isRequired,
  isCallProcessing: PropTypes.bool.isRequired,
  isStartCallBtn: PropTypes.bool.isRequired,
  isEndCallBtn: PropTypes.bool.isRequired,
  isLeaveCallBtn: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const { getIsStartCallButton, getIsEndCallButton, getIsLeaveCallButton, getIsCallProcessing } = navbarSelectors

  return {
    disabled: !contentStateSelectors.getContentId(state),
    isStartCallBtn: getIsStartCallButton(state),
    isEndCallBtn: getIsEndCallButton(state),
    isLeaveCallBtn: getIsLeaveCallButton(state),
    isCallProcessing: getIsCallProcessing(state),
    isCallStarted: presentationSelectors.getIsCallStarted(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  connectionActions: bindActionCreators(connectionActions, dispatch),
  contentActions: bindActionCreators(rawContentActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(StartCallButtonContainer)
