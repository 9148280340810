import { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Videocam, VideocamOff } from '@material-ui/icons'

import { accountSelectors } from '@tabeeb/modules/account'
import { contentStateSelectors } from '@tabeeb/shared/content'
import { presentationSelectors } from '@tabeeb/modules/presentation'
import { muteVideo, muteRemoteVideo } from '@tabeeb/modules/presentationToolbar/actions/muteVideo'

import { getSelectedUserId } from '@tabeeb/modules/recording/selectors'
import { isLocalRecordingActive } from '@tabeeb/modules/recording/services/localRecordingService'
import { openMuteCameraDialog } from '@tabeeb/modules/whiteboard/actions'
import { VIDEO_MUTED } from '@tabeeb/modules/presentation/constants'
import UserActionButton from '../UserActionButton'

const MuteUserVideoButton = ({ user }) => {
  const dispatch = useDispatch()

  const currentUserId = useSelector(accountSelectors.getCurrentUserId)
  const isPresenter = useSelector(contentStateSelectors.getIsCurrentUserPresenter)
  const isMuted = useSelector((state) => !presentationSelectors.getIsUserVideoPlaying(state, { Id: user.id }))
  const isMuteInProgress = useSelector((state) => state.presentationToolbar.muteVideoInProgress)
  const selectedUserId = useSelector((state) => getSelectedUserId(state))
  const isCallPreviewOpen = useSelector(presentationSelectors.getIsCallPreviewOpen)

  const handleClick = () => {
    if (isMuteInProgress === true || (user.isOnline === false && !isCallPreviewOpen)) {
      return
    }

    if (user.id === selectedUserId && !isMuted && isLocalRecordingActive()) {
      dispatch(openMuteCameraDialog())
      return
    }

    if (user.id === currentUserId) {
      dispatch(muteVideo())
      if (isCallPreviewOpen) {
        localStorage.setItem(VIDEO_MUTED, JSON.stringify(!isMuted))
      }
    } else if (isPresenter) {
      dispatch(muteRemoteVideo(user.id))
    }
  }

  const icon = isMuted ? VideocamOff : Videocam

  return (
    <UserActionButton
      icon={icon}
      title={isMuted ? 'Unmute video' : 'Mute video'}
      disabled={!isPresenter && currentUserId !== user.id}
      isMuted={isMuted}
      onClick={handleClick}
    />
  )
}

MuteUserVideoButton.propTypes = {
  user: PropTypes.shape({
    isOnline: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
  }),
}

export default memo(MuteUserVideoButton)
