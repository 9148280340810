import { DragDropContext } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import React from 'react'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  return [sourceClone, destClone]
}

// collection {values, droppableId, renderValue, valueIdGetter, noItemsLabel, listClassName}

const DragNDropLists = ({ collections, divider, onCollectionsChanged, CollectionComponent }) => {
  const getList = (id) => collections.find((c) => c.droppableId === id).values
  const onDragEnd = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(getList(source.droppableId), source.index, destination.index)
      onCollectionsChanged([{ droppableId: source.droppableId, values: items }])
    } else {
      const [sourceClone, destClone] = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      )

      onCollectionsChanged([
        { droppableId: source.droppableId, values: sourceClone },
        { droppableId: destination.droppableId, values: destClone },
      ])
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {collections.map((collection, index) => {
        return (
          <React.Fragment key={collection.droppableId}>
            <CollectionComponent {...collection} />
            {index + 1 < collections.length && divider}
          </React.Fragment>
        )
      })}
    </DragDropContext>
  )
}

DragNDropLists.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  divider: PropTypes.element,
  onCollectionsChanged: PropTypes.func.isRequired,
  CollectionComponent: PropTypes.elementType.isRequired,
}

export default DragNDropLists
