import { Button, styled } from '@mui/material'

export const HeaderContainer = styled('div')(({ theme }) => {
  return {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    height: '100%',
    alignItems: 'center',
  }
})

export const ButtonContentContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    width: '80px',
    justifyContent: 'space-around',
  }
})

export const StyledButton = styled(Button)(({ theme }) => {
  return {
    margin: '5px !important',
    width: '125px !important',
    height: '38px !important',
    transition: 'all 0.2s ease-out !important',
    backgroundColor: '#ffffff !important',
    boxShadow: 'none !important',
    borderRadius: '8px !important',

    '&:hover': {
      boxShadow: 'none !important',
    },
  }
})

export const ButtonOutlined = styled(StyledButton)(({ theme }) => {
  return {
    color: '#19719E !important',
    border: '1px #19719E solid !important',

    '&:hover': {
      color: '#0E4B6A !important',
      border: '1px #0E4B6A solid !important',
      backgroundColor: '#ffffff !important',
    },

    '&.Mui-disabled': {
      color: '#E3E3E3',
      border: '1px #E3E3E3 solid',
      backgroundColor: '#ffffff',
    },
  }
})

export const ButtonFilled = styled(StyledButton)(({ theme }) => {
  return {
    backgroundColor: '#19719E',
    color: '#ffffff',

    '&:hover': {
      backgroundColor: '#0E4B6A',
    },

    '&.Mui-disabled': {
      color: '#E3E3E3',
      backgroundColor: '#AAAAAA',
    },
  }
})
