import { autocompleteClasses, chipClasses, LinearProgress } from '@mui/material'
import { DataGridPro, GridLogicOperator } from '@mui/x-data-grid-pro'
import { useCallback } from 'react'
import PropTypes from 'prop-types'

import { Pagination } from '@tabeeb/uikit'
import DataGridToolbar from '../DataGridToolbar'

const autosizeOptions = {
  expand: true,
  includeHeaders: true,
  includeOutliers: true,
}

const DataGrid = ({
  dialogComponent,
  loading,
  setSort,
  search,
  filterModel,
  setFilterModel,
  rowCountState,
  paginationModel,
  gridColumns,
  gridRows,
  valueInputSxProps,
  disableColumnSelector = false,
  disableSearch = false,
  columnVisibilityModel,
  DataGridProProps,
  sx,
  slotsProps,
}) => {
  // filter
  const filterColumns = useCallback(({ field, columns, currentFilters }) => {
    // remove already filtered fields from list of columns
    const filteredFields = currentFilters?.map((item) => item.field)
    return columns
      .filter((colDef) => colDef.filterable && (colDef.field === field || !filteredFields.includes(colDef.field)))
      .map((column) => column.field)
  }, [])

  const getColumnForNewFilter = useCallback(({ currentFilters, columns }) => {
    const filteredFields = currentFilters?.map(({ field }) => field)
    const columnForNewFilter = columns
      .filter((colDef) => colDef.filterable && !filteredFields.includes(colDef.field))
      .find((colDef) => colDef.filterOperators?.length)
    return columnForNewFilter?.field ?? null
  }, [])

  return (
    <>
      <DataGridPro
        sx={{ '& .MuiDataGrid-virtualScroller': { minHeight: '100px' }, ...sx }}
        // general
        getRowId={(row) => row.Id}
        disableMultipleRowSelection
        loading={loading}
        disableRowSelectionOnClick
        disableColumnSelector={disableColumnSelector}
        columnVisibilityModel={columnVisibilityModel}
        slots={{ loadingOverlay: LinearProgress, toolbar: DataGridToolbar, pagination: Pagination, ...slotsProps }}
        slotProps={{
          pagination: {
            current: paginationModel.page,
            pages: paginationModel.pages,
            pageSize: paginationModel.pageSize,
            onPageNumberChange: paginationModel.onPageNumberChange,
            onPageSizeChange: paginationModel.onPageSizeChange,
            boxSx: { flex: 1 },
          },
          filterPanel: {
            filterFormProps: {
              filterColumns,
              valueInputProps: {
                sx: {
                  [`& .${chipClasses.root}`]: {
                    height: '20px',
                  },
                  [`& .${autocompleteClasses.input}`]: { p: '4px 0px !important' },
                  ...valueInputSxProps,
                },
                InputComponentProps: {
                  disableCloseOnSelect: true,
                },
              },
            },
            getColumnForNewFilter,
            logicOperators: [GridLogicOperator.And],
          },
          toolbar: {
            showSearch: !disableSearch,
          },
        }}
        // sorting
        disableMultipleColumnsSorting
        sortingMode='server'
        onSortModelChange={(newSort) =>
          setSort(newSort.length > 0 ? { field: newSort[0].field, sortOrder: newSort[0].sort } : {})
        }
        // filter
        filterMode='server'
        onFilterModelChange={(newFilterModel) => {
          setFilterModel(newFilterModel)
        }}
        filterModel={{
          items: filterModel,
          quickFilterValues: [search],
        }}
        // pagination
        pagination
        paginationMode='server'
        rowCount={rowCountState}
        // dimensions
        autosizeOnMount
        autosizeOptions={autosizeOptions}
        // data grid columns and rows
        columns={gridColumns}
        rows={gridRows}
        {...DataGridProProps}
      />

      {dialogComponent}
    </>
  )
}

DataGrid.propTypes = {
  dialogComponent: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  setSort: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterModel: PropTypes.array.isRequired,
  setFilterModel: PropTypes.func.isRequired,
  rowCountState: PropTypes.number.isRequired,
  paginationModel: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    onPageNumberChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
  }).isRequired,
  gridColumns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
    })
  ).isRequired,
  gridRows: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    })
  ).isRequired,
  valueInputSxProps: PropTypes.shape({
    minWidth: PropTypes.string,
    width: PropTypes.string,
  }),
  disableColumnSelector: PropTypes.bool,
  disableSearch: PropTypes.bool,
  columnVisibilityModel: PropTypes.shape({
    Owner: PropTypes.bool,
    IsDeleted: PropTypes.bool,
  }),
  DataGridProProps: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    ToolbarSlotProps: PropTypes.object,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  slotsProps: PropTypes.object,
}

export default DataGrid
