export const popoverTypes = {
  Filter: 1,
  Grouping: 2,
}

export const sortingDirections = {
  unset: 0,
  asc: 1,
  desc: 2,
}

export const filterOperators = {
  unset: 0,
  equals: 1,
  contains: 2,
  notEquals: 3,
  empty: 4,
  notEmpty: 5,
}

export const filterOperatorsLabels = {
  [filterOperators.unset]: 'Unset',
  [filterOperators.equals]: 'Equals',
  [filterOperators.contains]: 'Contains',
  [filterOperators.notEquals]: 'Not Equals',
  [filterOperators.empty]: 'Empty',
  [filterOperators.notEmpty]: 'Not Empty',
}

export const filterOperatorsOrdered = [
  filterOperators.unset,
  filterOperators.equals,
  filterOperators.notEquals,
  filterOperators.contains,
  filterOperators.empty,
  filterOperators.notEmpty,
]

export const filterOperatorsWithoutValues = [filterOperators.empty, filterOperators.notEmpty]

export const groupingOperators = {
  unset: 0,
  max: 1,
  count: 2,
}
