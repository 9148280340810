import { useCallback, useState } from 'react'

function usePdfViewer() {
  const [numberOfPdfPages, setNumberOfPdfPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)

  const nextPageClick = useCallback(() => {
    setPageNumber((prevState) => prevState + 1)
  }, [])

  const previousPageClick = useCallback(() => {
    setPageNumber((prevState) => prevState - 1)
  }, [])

  const setInitialPage = useCallback(() => {
    setPageNumber(1)
  }, [])
  return [numberOfPdfPages, setNumberOfPdfPages, pageNumber, nextPageClick, previousPageClick, setInitialPage]
}

export default usePdfViewer
