import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import VideocamIcon from '@material-ui/icons/Videocam'
import CallEndIcon from '@material-ui/icons/CallEnd'

import './styles.less'

const StartCallButton = ({
  disabled,
  isStartCallBtn,
  isEndCallBtn,
  isLeaveCallBtn,
  showCallBtn,
  handleCallButton,
  handleLeaveButton,
}) => {
  const buttonText = isStartCallBtn
    ? 'start call'
    : isEndCallBtn
    ? 'end call'
    : isLeaveCallBtn
    ? 'leave call'
    : 'processing...'

  const icon = isStartCallBtn ? <VideocamIcon /> : isEndCallBtn ? <CallEndIcon /> : null

  const className = isStartCallBtn
    ? 'start-call-btn'
    : isEndCallBtn || isLeaveCallBtn
    ? 'end-call-btn'
    : 'processing-btn'

  const clickHandler = isStartCallBtn || isEndCallBtn ? handleCallButton : isLeaveCallBtn ? handleLeaveButton : null

  return (
    showCallBtn && (
      <Button
        onClick={clickHandler}
        variant='contained'
        color='primary'
        disabled={disabled}
        className={className}
        startIcon={icon}
      >
        {buttonText}
      </Button>
    )
  )
}

StartCallButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleCallButton: PropTypes.func.isRequired,
  handleLeaveButton: PropTypes.func.isRequired,
  isStartCallBtn: PropTypes.bool.isRequired,
  isEndCallBtn: PropTypes.bool.isRequired,
  isLeaveCallBtn: PropTypes.bool.isRequired,
  showCallBtn: PropTypes.bool.isRequired,
}

export default StartCallButton
