import { handleActions } from 'redux-actions'
import { closeAiReportDialog, getAiObjectsByContent } from '../actions'

const defaultState = []

export default handleActions(
  {
    [getAiObjectsByContent.success](state, action) {
      return action.response.data
    },
    [closeAiReportDialog](state, action) {
      return defaultState
    },
  },
  defaultState
)
