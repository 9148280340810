import PropTypes from 'prop-types'
import { TextField, withStyles } from '@material-ui/core'

import styles from './styles'

const ConfigTextField = ({ classes, value, ...props }) => {
  const validateInput = (e) => {
    if (props.type === 'number') {
      const charCode = e.which ? e.which : e.keyCode
      const restrictedChars = [107, 109, 187, 189, 69]

      if (restrictedChars.some((char) => char === charCode)) {
        e.stopPropagation()
        e.preventDefault()
        return false
      }

      return true
    }
    return true
  }

  return (
    <TextField
      color='primary'
      variant='outlined'
      classes={{ ...classes }}
      title={value || null}
      InputProps={{
        inputProps: {
          max: props.max,
          min: props.min,
          step: props.step || 1,
          onKeyDown: validateInput,
        },
      }}
      {...props}
      value={value || value === 0 ? value : ''}
    />
  )
}

ConfigTextField.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  type: PropTypes.string,
}

export default withStyles(styles)(ConfigTextField)
