import { handleActions } from 'redux-actions'

import * as actions from '../actions'

const defaultState = []

export default handleActions(
  {
    [actions.getTenantsListSuccess](state, { response }) {
      return response.data.allTenants
    },
  },
  defaultState
)
