import { handleActions } from 'redux-actions'
import { resetWhiteboardState } from '@tabeeb/modules/whiteboard/actions'
import * as userActions from '../actions'
import { ZOOM_STEP, ZOOM_MIN } from '../common/webZoom'

const defaultState = []

const users = handleActions(
  {
    [userActions.setUsers](state, action) {
      return setUsers(state, action.payload)
    },
    [userActions.addUser](state, action) {
      return addUser(state, action.payload)
    },
    [userActions.addOnlineUser](state, action) {
      return addOnlineUser(state, action.payload)
    },
    [userActions.removeOnlineUser](state, action) {
      return removeOnlineUser(state, action.payload)
    },
    [userActions.setVideoLoadForUser](state, action) {
      return setVideoLoadForUser(state, action.payload)
    },
    [userActions.resetVideoLoadForUser](state, action) {
      return resetVideoLoadForUser(state, action.payload)
    },
    [userActions.removeUser](state, action) {
      return removeUser(state, action.payload)
    },
    [userActions.updateChargeLevel](state, action) {
      return updateChargeLevel(state, action.payload)
    },
    [userActions.updateQuality](state, action) {
      return updateQuality(state, action.payload)
    },
    [userActions.updateBitrate](state, action) {
      return updateBitrate(state, action.payload)
    },
    [userActions.onUpdateMobileZoomValue](state, action) {
      return onUpdateMobileZoomValue(state, action.payload)
    },
    [userActions.changeUserDeviceType](state, action) {
      return changeUserDeviceType(state, action.payload)
    },
    [userActions.updateUser](state, action) {
      return updateUser(state, action.payload)
    },
    [userActions.updateContentUserRole](state, action) {
      return updateRole(state, action.payload)
    },
    [userActions.onUserTracksStateChanged](state, action) {
      return onUserTracksStateChanged(state, action.payload)
    },
    [userActions.toggleChatNotificationsMuteRequest](state, action) {
      const { userId, value } = action.payload

      return updateChatNotificationsMute(state, userId, value)
    },

    [userActions.onWebZoomIn](state, action) {
      return onWebZoomIn(state, action.payload)
    },
    [userActions.onWebZoomOut](state, action) {
      return onWebZoomOut(state, action.payload)
    },
    [resetWhiteboardState](state, action) {
      return defaultState
    },
  },
  defaultState
)

export default users

const setUsers = (state, users) => {
  return users.reverse()
}

const updateUser = (state, user) => {
  return state.map((item) => (item.id === user.id ? { ...user } : item))
}

const addUser = (state, user) => {
  const isUserExists = Boolean(state.find((item) => item.id === user.id))
  if (isUserExists) {
    return state.map((item) => (item.id === user.id ? { ...item, ...user, isOnline: item.isOnline } : item))
  }
  return [...state, { ...user }]
}

const setVideoLoadForUser = (state, userId) => {
  return state.map((item) => (item.id === userId ? { ...item, isVideoLoad: true } : item))
}

const resetVideoLoadForUser = (state, userId) => {
  return state.map((item) => (item.id === userId ? { ...item, isVideoLoad: false } : item))
}

const onUserTracksStateChanged = (state, userId) => {
  return state.map((item) => (item.id === userId ? { ...item } : item))
}

const addOnlineUser = (state, userId) => {
  return state.map((item) => (item.id === userId ? { ...item, isOnline: true, isDeleted: false } : item))
}

const removeOnlineUser = (state, userId) => {
  return state.map((item) => (item.id === userId ? { ...item, isOnline: false, webZoomValue: ZOOM_MIN } : item))
}

const removeUser = (state, userId) => {
  return state.map((item) => (item.id === userId ? { ...item, isDeleted: true } : item))
}

const updateChargeLevel = (state, { userId, chargeLevel }) => {
  return state.map((item) => (item.id === userId ? { ...item, chargeLevel } : item))
}

const updateBitrate = (state, { userId, bitrate }) => {
  return state.map((item) => (item.id === userId ? { ...item, bitrate } : item))
}

const updateQuality = (state, { userId, connectionQuality }) => {
  return state.map((item) => (item.id === userId ? { ...item, connectionQuality } : item))
}

const onUpdateMobileZoomValue = (state, { userId, mobileZoomValue, mobileZoomMax }) => {
  return state.map((item) => (item.id === userId ? { ...item, mobileZoomValue, mobileZoomMax } : item))
}

const changeUserDeviceType = (state, { userId, deviceType }) => {
  return state.map((item) => (item.id === userId ? { ...item, deviceType } : item))
}

const updateRole = (state, { UserId, Role }) => {
  return state.map((item) => (item.id === UserId ? { ...item, role: Role } : item))
}

const updateChatNotificationsMute = (state, userId, value) => {
  return state.map((item) => (item.id === userId ? { ...item, chatNotificationsMuted: value } : item))
}

const onWebZoomIn = (state, userId) => {
  return state.map((item) =>
    item.id === userId ? { ...item, webZoomValue: (item.webZoomValue || ZOOM_MIN) + ZOOM_STEP } : item
  )
}

const onWebZoomOut = (state, userId) => {
  return state.map((item) => (item.id === userId ? { ...item, webZoomValue: item.webZoomValue - ZOOM_STEP } : item))
}
