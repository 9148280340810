import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import { TableRow, TableCell, Button } from '@mui/material'
import { Edit } from '@mui/icons-material'
import Routes from '@tabeeb/routes'
import { ClickableTableCell } from '@tabeeb/modules/shared/uikit/components/ClickableTableCell'
import IdentityServerUserInfo from '@tabeeb/modules/shared/uikit/components/IdentityServerUserInfo'
import { getCurrentUserGuid } from '@tabeeb/modules/account/selectors'
import { getIsUserPowerBIAdmin } from '@tabeeb/modules/reports/selectors'

const PowerBIReportsTableRow = ({ report }) => {
  const dispatch = useDispatch()

  const currentUserId = useSelector(getCurrentUserGuid)
  const isUserPowerBIAdmin = useSelector(getIsUserPowerBIAdmin)

  const showEditButton = useMemo(() => {
    return currentUserId === report.OwnerId || isUserPowerBIAdmin
  }, [currentUserId, isUserPowerBIAdmin, report.OwnerId])

  const onOpen = useCallback(() => {
    dispatch(push(generatePath(Routes.powerBIReport, { id: report.Id })))
  }, [report.Id, dispatch])

  const onEdit = useCallback(() => {
    dispatch(push(generatePath(Routes.editPowerBIReport, { id: report.Id })))
  }, [dispatch, report.Id])

  return (
    <TableRow>
      <ClickableTableCell align='left' onClick={onOpen}>
        {report.Name}
      </ClickableTableCell>
      <TableCell align='left'>
        <IdentityServerUserInfo userIdentityId={report.OwnerId} />
      </TableCell>
      <TableCell align='left'>{report.SecurityModel}</TableCell>
      <TableCell align='left'>{report.ProviderName}</TableCell>
      <TableCell align='left'>
        {showEditButton && (
          <Button variant='outlined' color='primary' onClick={onEdit} startIcon={<Edit />} sx={{ marginLeft: '5px' }}>
            Edit
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}

PowerBIReportsTableRow.propTypes = {
  report: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    OwnerId: PropTypes.string.isRequired,
    ProviderName: PropTypes.string.isRequired,
    SecurityModel: PropTypes.string.isRequired,
  }),
}

export default PowerBIReportsTableRow
