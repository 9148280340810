import { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, CircularProgress, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { Add, Delete, RefreshOutlined } from '@mui/icons-material'
import InfiniteScroll from 'react-infinite-scroller'
import { FetchStatus } from '@tabeeb/enums'
import usePowerBIReportUsers from '@tabeeb/modules/powerBIReports/hooks/usePowerBIReportUsers'
import { useDialogState } from '@tabeeb/modules/shared/utils/hooks'
import IdentityServerUserInfo from '@tabeeb/modules/shared/uikit/components/IdentityServerUserInfo'
import { ReportUserAccessTypeNames } from '@tabeeb/modules/powerBIReports/enums'
import { deletePowerBIReportUser, openPowerBIReportUserModal } from '@tabeeb/modules/powerBIReports/actions'
import DeleteReportUserConfirmationDialog from '../../Dialogs/DeleteReportUserConfirmationDialog'
import PowerBIReportUserModal from '../../Modals/PowerBIReportUserModal'

const PowerBIReportUsers = ({ report }) => {
  const dispatch = useDispatch()
  const containerRef = useRef()
  const [selectedReportUserId, setSelectedReportUserId] = useState(0)

  const { fetchStatus, loading, users, hasMore, onLoadMore, onReload } = usePowerBIReportUsers(report.Id)
  const [deleteReportUserDialogOpen, onDeleteReportUserDialogOpen, onDeleteReportUserDialogClose] = useDialogState()

  const onAdd = useCallback(() => {
    dispatch(openPowerBIReportUserModal({ report }))
  }, [dispatch, report])

  const onDelete = useCallback(() => {
    dispatch(deletePowerBIReportUser.request(selectedReportUserId))
    onDeleteReportUserDialogClose()
  }, [dispatch, onDeleteReportUserDialogClose, selectedReportUserId])

  const openDeleteConfirmationDialog = useCallback(
    (id) => {
      onDeleteReportUserDialogOpen()
      setSelectedReportUserId(id)
    },
    [onDeleteReportUserDialogOpen]
  )

  const buttonsDisabled = useMemo(() => {
    return loading
  }, [loading])

  const refreshButtonIcon = useMemo(() => {
    return buttonsDisabled ? <CircularProgress size={18} /> : <RefreshOutlined color='primary' />
  }, [buttonsDisabled])

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid container item xs={12} spacing={1} justifyContent='flex-end'>
        <Grid item>
          <Button variant='outlined' disabled={buttonsDisabled} startIcon={refreshButtonIcon} onClick={onReload}>
            Reload
          </Button>
        </Grid>
        <Grid item>
          <Button variant='outlined' disabled={buttonsDisabled} startIcon={<Add color='primary' />} onClick={onAdd}>
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container ref={containerRef}>
        <InfiniteScroll
          useWindow={false}
          loadMore={onLoadMore}
          hasMore={hasMore}
          getScrollParent={() => containerRef.current}
          style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}
        >
          {fetchStatus === FetchStatus.Failed && (
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center' }}>Failed to load report users.</Typography>
            </Grid>
          )}
          {fetchStatus === FetchStatus.Loaded && users.length === 0 && (
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center' }}>Users list is empty.</Typography>
            </Grid>
          )}
          {users.map((reportUser) => {
            const accessScope = reportUser.TenantId
              ? `${reportUser.TenantName} tenant scope`
              : `${ReportUserAccessTypeNames[reportUser.AccessType]} scope`
            return (
              <Grid key={reportUser.Id} item xs={6} container alignItems='center'>
                <Grid container item xs={0.3} height='100%'>
                  <Divider orientation='vertical' flexItem />
                </Grid>
                <Grid item xs={5.7}>
                  <IdentityServerUserInfo userIdentityId={reportUser.UserId} />
                </Grid>
                <Grid item xs={5}>
                  <Tooltip title={accessScope}>
                    <Typography>{accessScope}</Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => openDeleteConfirmationDialog(reportUser.Id)}>
                    <Delete color='error' />
                  </IconButton>
                </Grid>
              </Grid>
            )
          })}
        </InfiniteScroll>
      </Grid>
      <PowerBIReportUserModal />
      <DeleteReportUserConfirmationDialog
        open={deleteReportUserDialogOpen}
        onClose={onDeleteReportUserDialogClose}
        onSubmit={onDelete}
      />
    </Grid>
  )
}

PowerBIReportUsers.propTypes = {
  report: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    SecurityModel: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default PowerBIReportUsers
