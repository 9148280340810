import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { MenuItem, FormControl, Select, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { getFormTitle } from '@tabeeb/modules/appConfigState/selectors'
import { ConfirmationDialog } from '@tabeeb/modules/shared/dialogs'
import {
  getFormRecordsList,
  getIsDeleteFormRecordButtonVisible,
  getIsFormRecordAreaVisible,
  getIsSaveFormRecordButtonVisible,
} from '@tabeeb/modules/forms/selectors'
import { removeFormRecordRequest, saveFormRecord, setSelectedRecord } from '@tabeeb/modules/forms/actions'
import './styles.less'

const FormRecords = ({
  records,
  isSaveButtonVisible,
  isDeleteButtonVisible,
  showRecords,
  selectedRecord,
  recordSavingInProgress,
}) => {
  const [selectedRecordForDelete, setSelectedRecordForDelete] = useState(null)
  const formTitle = useSelector(getFormTitle)
  const dispatch = useDispatch()

  const _handleDeleteFormRecord = () => {
    setSelectedRecordForDelete(null)
    dispatch(removeFormRecordRequest(selectedRecordForDelete))
  }

  const _handleSelectFormRecordForDelete = (e, recordId) => {
    e.stopPropagation()
    setSelectedRecordForDelete(recordId)
  }

  const dialogProps = {
    onCloseDialog: () => setSelectedRecordForDelete(null),
    onSubmit: _handleDeleteFormRecord,
    isOpen: selectedRecordForDelete !== null,
    title: `${formTitle} record will be permanently deleted.`,
  }

  return showRecords ? (
    <div className='form-records'>
      <div className='forms-filters-item'>
        <FormControl className='forms-filters-item-control'>
          <Select
            value={selectedRecord}
            onChange={(e) => dispatch(setSelectedRecord(parseInt(e.target.value, 10)))}
            MenuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
              getContentAnchorEl: null,
              id: 'forms-filters-menu',
            }}
          >
            <MenuItem value={0}>
              <span className='forms-filters-menu-text'>Current view</span>
            </MenuItem>
            {records.map((record) => {
              const createdOn = `${record.CreatedOn}${record.CreatedOn.endsWith('Z') ? '' : 'Z'}`
              const date = dayjs(createdOn).format('MMM D, YYYY h:mm A')

              return (
                <MenuItem value={record.Id} key={record.Id}>
                  <div className='form-records-menu'>
                    <span className='forms-filters-menu-text'>{date}</span>
                    {isDeleteButtonVisible && (
                      <span
                        className='delete-form-record-btn'
                        title='Delete record'
                        onClick={(e) => _handleSelectFormRecordForDelete(e, record.Id)}
                      >
                        <DeleteIcon fontSize='inherit' color='inherit' />
                      </span>
                    )}
                  </div>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      {isSaveButtonVisible && (
        <div className='forms-filters-item save-form-record-btn'>
          <Button
            disabled={selectedRecord > 0 || recordSavingInProgress}
            onClick={() => dispatch(saveFormRecord())}
            variant='outlined'
            color='primary'
          >
            {recordSavingInProgress ? 'Saving...' : 'Save record'}
          </Button>
        </div>
      )}
      <ConfirmationDialog {...dialogProps} />
    </div>
  ) : null
}

FormRecords.propTypes = {
  records: PropTypes.array.isRequired,
  showRecords: PropTypes.bool.isRequired,
  isSaveButtonVisible: PropTypes.bool.isRequired,
  isDeleteButtonVisible: PropTypes.bool.isRequired,
  selectedRecord: PropTypes.number.isRequired,
  recordSavingInProgress: PropTypes.bool.isRequired,
}

FormRecords.defaultProps = {
  selectedRecord: 0,
}

const mapStateToProps = (state) => {
  const {
    currentForm: { selectedRecord },
    recordSavingInProgress,
  } = state.forms

  return {
    showRecords: getIsFormRecordAreaVisible(state),
    isSaveButtonVisible: getIsSaveFormRecordButtonVisible(state),
    isDeleteButtonVisible: getIsDeleteFormRecordButtonVisible(state),
    records: getFormRecordsList(state),
    selectedRecord,
    recordSavingInProgress,
  }
}

export default connect(mapStateToProps, null)(FormRecords)
