import { createTheme } from '@material-ui/core'

const tabeebTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0089cf',
    },
    secondary: {
      main: '#ffffff',
      light: '#ffffff',

      mobile: {
        dark: '#201E1E',
      },
    },
    accent: {
      disabled: 'rgba(186, 186, 186, 1)',
      pressed: '#005f90d9',
    },
    background: {
      primary: '#FFFFFF',
      secondary: '#ECECEC',
      semitransparent: 'rgba(0, 0, 0, 0.4)',
      message: {
        primary: '#f5f5f5',
        secondary: '#E1F5FD',
        unread: '#82D5F8',
      },
      user: {
        action: 'rgba(0, 0, 0, 0.35)',
        deleted: '#616161',
      },
      formAnswerStatus: {
        noAction: '#d3d3d3',
        approved: '#4CAF50',
        rejected: '#f44336',
        submitted: '#00BCD4',
      },
    },
    text: {
      primary: '#616161',
      contrast: '#ffffff',
      mobile: {
        primary: '#3C3C3C',
        secondary: '#777777',
        disabled: 'rgba(255, 255, 255, 0.4)',
      },
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
  },
  props: {
    MuiTooltip: {
      enterDelay: 500,
      enterNextDelay: 500,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        maxWidth: 500,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiFab: {
      root: {
        boxShadow: '0px 2px 3px -1px rgb(0 0 0 / 75%), 0px -1px 1px -1px rgb(0 0 0 / 50%)',
      },
    },
    MuiFormLabel: {
      colorSecondary: {
        '&.Mui-focused': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiOutlinedInput: {
      colorSecondary: {
        '&.Mui-focused': {
          '& > .MuiOutlinedInput-notchedOutline': {
            borderColor: '#767676',
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
})

export default tabeebTheme
