import { handleActions } from 'redux-actions'

import { getDefaultPublishName, closeAiReportPublishSettingsDialog } from '../actions'

const defaultState = ''

export default handleActions(
  {
    [getDefaultPublishName.success](state, action) {
      return action.response.data
    },
    [closeAiReportPublishSettingsDialog]() {
      return defaultState
    },
  },
  defaultState
)
