import PropTypes from 'prop-types'
import { Tooltip, withStyles } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'

import styles from './styles'

const HelpText = ({ classes, helpText }) => {
  return helpText ? (
    <Tooltip title={helpText}>
      <div className={classes.help}>
        <HelpOutline />
      </div>
    </Tooltip>
  ) : null
}

HelpText.propTypes = {
  classes: PropTypes.object.isRequired,
  helpText: PropTypes.string.isRequired,
}

export default withStyles(styles)(HelpText)
