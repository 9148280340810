import { put, takeEvery, takeLatest, select, call, fork, all, delay, cancel } from 'redux-saga/effects'

import { convertServiceUserToPluginUser } from '@tabeeb/services/dataConverter'
import { getIsSoundNotificationOnCallJoinEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { RecordingType, TabeebConnectEvents, ServerRecordingState } from '@tabeeb/enums'
import { enableGridView } from '@tabeeb/modules/gridView/actions'
import { isMobileOnly } from 'react-device-detect'
import { isCurrentUserRecorder } from '@tabeeb/modules/recording/services/localRecordingService'
import { getRecordingState, getSelectedUserId } from '@tabeeb/modules/recording/selectors'
import * as conferenceActions from '../actions/conference'
import * as trackActions from '../actions/tracks'
import * as jitsiTrackActions from '../actions/jitsiTrack'
import * as snapshotActions from '../../presentationToolbar/actions/snapshot'
import bindEvent from '../../shared/utils/bindEvent'
import bindJitsiCommand from '../../shared/utils/bindJitsiCommand'
import { usersActions, usersSelectors } from '../../../users'
import * as muteAudioActions from '../../presentationToolbar/actions/muteAudio'
import * as muteVideoActions from '../../presentationToolbar/actions/muteVideo'
import { recordingActions } from '../../recording'
import { signalrEvents, signalrActions, signalrConstants } from '../../signalr'

import { generateId } from '../../presentationToolbar/services/snapshotService'
import * as notificationActions from '../../notification/actions'
import { accountSelectors } from '../../account'
import { getCurrentUserId, getLargeVideoUser, getParticipantIdByUserId, getSelectedUser } from '../selectors'
import { contentStateSelectors } from '../../shared/content'
import { leaveContent } from '../../whiteboard/services'
import { showSpeaker, updateAudioLevelIndicatorForUser } from '../services/audioLevelService'
import * as presentationSelectors from '../selectors'
import WatchRTC from '../services/watchRTC/WatchRTC'

let externalEventChannels = []
let jitsiCommandChannels = []
let mobilePriorityViewEventChannel

function* initRoom() {
  const jitsiConnection = yield select((state) => state.presentation.connection.connection)
  const roomId = yield select((state) => state.presentation.connection.roomId)
  const roomName = `${roomId}`
  const jitsiRoom = jitsiConnection.initJitsiConference(roomName, window.config)

  const { TenantId: tenantId, Id: currentUserId } = yield select(accountSelectors.getMe)
  WatchRTC.start(roomName, jitsiRoom.myUserId(), {
    tenantId,
    userId: currentUserId,
  })

  yield put(conferenceActions.setJitsiRoom(jitsiRoom))
  yield put(conferenceActions.bindRoomEvents())

  const currentUserName = yield select(accountSelectors.getCurrentUserFullName)
  jitsiRoom.setDisplayName(currentUserName)

  yield put(trackActions.createLocalTracks())
}

function leaveJitsiRoom(jitsiRoom) {
  if (jitsiRoom) {
    jitsiRoom.leave().catch(() => {})
  }
}

function* leaveRoom() {
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  yield put(conferenceActions.unBindRoomEvents())
  yield call(leaveJitsiRoom, jitsiRoom)
}

function* onKicked() {
  yield put(
    notificationActions.onAddInfoNotification({ message: 'User with same credentials has joined the video call' })
  )
  yield delay(2500)

  yield leaveContent({ force: true })
}

function* bindRoomEvents() {
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  const jitsiRoomEvents = window.JitsiMeetJS.events.conference

  yield fork(bindEvent, jitsiRoom, jitsiRoomEvents.TRACK_ADDED, conferenceActions.onTrackAdded, externalEventChannels)
  yield fork(
    bindEvent,
    jitsiRoom,
    jitsiRoomEvents.CONFERENCE_JOINED,
    conferenceActions.onConferenceJoined,
    externalEventChannels
  )
  yield fork(bindEvent, jitsiRoom, jitsiRoomEvents.USER_LEFT, conferenceActions.onUserLeft, externalEventChannels)
  yield fork(
    bindEvent,
    jitsiRoom,
    jitsiRoomEvents.TRACK_REMOVED,
    conferenceActions.onTrackRemoved,
    externalEventChannels
  )
  yield fork(bindEvent, jitsiRoom, jitsiRoomEvents.USER_JOINED, conferenceActions.onUserJoined, externalEventChannels)
  yield fork(bindEvent, jitsiRoom, jitsiRoomEvents.KICKED, conferenceActions.onKicked, externalEventChannels)

  yield fork(
    bindEvent,
    jitsiRoom,
    jitsiRoomEvents.TRACK_AUDIO_LEVEL_CHANGED,
    conferenceActions.trackAudioLevelChanged,
    externalEventChannels
  )

  yield fork(
    bindEvent,
    jitsiRoom,
    window.JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
    usersActions.localStatsReceived,
    externalEventChannels
  )

  yield fork(
    bindEvent,
    jitsiRoom,
    window.JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED,
    usersActions.remoteStatsReceived,
    externalEventChannels
  )

  yield fork(
    bindJitsiCommand,
    jitsiRoom,
    TabeebConnectEvents.ON_RECORDER_VIDEO_ATTACHED,
    recordingActions.onRecordingVideoAttached,
    jitsiCommandChannels
  )

  yield fork(
    bindJitsiCommand,
    jitsiRoom,
    TabeebConnectEvents.SET_REMOTE_USER_VIDEO_STATE,
    muteVideoActions.onRemoteVideoMuted,
    jitsiCommandChannels
  )

  yield fork(
    bindJitsiCommand,
    jitsiRoom,
    TabeebConnectEvents.SET_REMOTE_USER_AUDIO_STATE,
    muteAudioActions.onRemoteAudioMuted,
    jitsiCommandChannels
  )

  yield fork(
    bindJitsiCommand,
    jitsiRoom,
    TabeebConnectEvents.TAKING_SNAPSHOT,
    snapshotActions.onSnapshotRequestReceived,
    jitsiCommandChannels
  )

  yield fork(
    bindJitsiCommand,
    jitsiRoom,
    TabeebConnectEvents.TAKING_SNAPSHOT,
    snapshotActions.onSnapshotCreated,
    jitsiCommandChannels
  )

  yield fork(
    bindJitsiCommand,
    jitsiRoom,
    jitsiRoomEvents.CONFERENCE_FAILED,
    conferenceActions.onConferenceFailed,
    jitsiCommandChannels
  )

  yield fork(
    bindJitsiCommand,
    jitsiRoom,
    TabeebConnectEvents.TRACKS_INITIALIZATION_FAILED,
    conferenceActions.onTracksInitializationFailed,
    jitsiCommandChannels
  )
}

function* unBindRoomEvents() {
  externalEventChannels.forEach((channel) => channel.close())
  jitsiCommandChannels.forEach((channel) => channel.close())
  externalEventChannels = []
  jitsiCommandChannels = []
  yield put(trackActions.unbindTrackEvents())
}

function* showJoinNotification({ payload }) {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const [userId] = payload

  const isCallStarted = yield select(presentationSelectors.getIsCallStarted)

  if (!isCallStarted) {
    return
  }

  if (currentUserId === userId) {
    return
  }

  if (isCurrentUserRecorder()) {
    const selectedUserId = yield select(getSelectedUserId)
    const { startDate, session } = yield select(getRecordingState)

    yield put(
      signalrActions.invokeHubAction({
        method: 'RecordingStatusUpdate',
        args: [
          {
            state: ServerRecordingState.processing,
            recordingType: RecordingType.local,
            session,
            selectedUser: selectedUserId,
            startDate: startDate / 1000,
          },
        ],
      })
    )
  }

  const connectedUser = yield select((state) => usersSelectors.getUserById(state, { Id: userId }) || {})
  const userName = connectedUser.displayName

  if (!userName) {
    return
  }

  yield put(notificationActions.onAddInfoNotification({ message: `${userName} has joined the call.` }))

  const isSoundNotificationOnCallJoin = yield select(getIsSoundNotificationOnCallJoinEnabled)

  if (!isSoundNotificationOnCallJoin) {
    return
  }

  const audio = new Audio(new URL('@tabeeb/assets/audio/userJoined.mp3', import.meta.url))
  audio.play()
}

function* onTrackAdded(action) {
  const track = action.payload
  const participantId = track.getParticipantId
    ? track.getParticipantId()
    : yield select(accountSelectors.getCurrentUserId)

  if (typeof participantId === 'string' && participantId.indexOf(window.config.recorderPrefix) !== -1) {
    return
  }
  const userId = parseInt(participantId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  if (!track.isLocal() && userId !== currentUserId) {
    yield put(trackActions.addRemoteTrack(track))
    yield put(trackActions.bindTrackEvents(track))
  }

  yield put(trackActions.attachTrack({ track, userId }))
}

function* onTrackRemoved(action) {
  const track = action.payload

  yield put(jitsiTrackActions.detachTrack(track))
}

function* onUserLeft(action) {
  const participantId = action.payload[0]
  if (participantId.indexOf(window.config.recorderPrefix) !== -1) {
    return
  }
  const userId = parseInt(participantId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  if (userId === currentUserId) {
    return
  }

  const usersParticipantId = yield select((state) => getParticipantIdByUserId(state, userId))
  if (participantId !== usersParticipantId) {
    return
  }

  // reset large video for left user
  const largeVideoUser = yield select((state) => state.presentation.tracks.largeVideoUser)
  if (largeVideoUser !== null && largeVideoUser.id === userId) {
    yield put(trackActions.setLargeVideoUser(null))
  }

  yield put(usersActions.removeOnlineUser(userId))
  yield put(usersActions.resetVideoLoadForUser(userId))
  yield put(trackActions.resetTracksForUser(userId))
  yield put(trackActions.detachTracksForUser(userId))
  yield put(recordingActions.onStopRecordingForUser({ userId }))
  yield put(usersActions.onUpdateMobileZoomValue({ userId, mobileZoomValue: 100, mobileZoomMax: 800 }))
  updateAudioLevelIndicatorForUser(userId, 0)
}

function* onConferenceJoined() {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const roomId = yield select((state) => state.presentation.connection.roomId)
  yield put(usersActions.addOnlineUser(currentUserId))

  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  if (jitsiRoom) {
    const myParticipantId = jitsiRoom.myUserId()
    yield put(conferenceActions.setParticipantIdForUser({ userId: currentUserId, participantId: myParticipantId }))
    yield put(signalrActions.invokeHubAction({ method: 'ConferenceJoined', args: [roomId, currentUserId] }))

    if (isMobileOnly) {
      yield put(trackActions.toggleMobilePriorityView(true))
    }
  }
}

function* onConferenceFailed(action) {
  const error = action.payload[0]
  yield put(unBindRoomEvents())
  yield put(notificationActions.onAddErrorNotification(error))
  console.error(error)
}

function* onUserJoined({ payload: [participantId, jitsiParticipant] }) {
  if (participantId.indexOf(window.config.recorderPrefix) !== -1) {
    return
  }
  const userId = parseInt(participantId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const presenterId = yield select(contentStateSelectors.getPresenterId)
  const usersParticipantId = yield select((state) => getParticipantIdByUserId(state, userId))

  if (currentUserId === presenterId && usersParticipantId) {
    const jitsiRoom = yield select((state) => state.presentation.conference.room)
    const myParticipantId = jitsiRoom.myUserId()
    if (myParticipantId === usersParticipantId) {
      jitsiRoom.kickParticipant(participantId)
      yield put(
        notificationActions.onAddInfoNotification({ message: 'User with same credentials has joined the video call' })
      )
    } else {
      jitsiRoom.kickParticipant(usersParticipantId)
    }
  }

  if (userId === currentUserId) {
    return
  }

  const joinedUser = yield select((state) => usersSelectors.getUserById(state, { Id: userId }))
  if (!joinedUser) {
    const ownerId = yield select(contentStateSelectors.getOwnerId)
    const displayName = jitsiParticipant.getDisplayName()
    const user = convertServiceUserToPluginUser({ Id: userId, Name: displayName, isDeleted: false }, { ownerId })
    yield put(usersActions.addUser(user))
  }

  yield put(conferenceActions.setParticipantIdForUser({ userId, participantId }))
  yield put(trackActions.initTracksForUser(userId))
  yield put(usersActions.addOnlineUser(userId))
}

function* muteRemoteVideo(action) {
  const userId = action.payload
  const videoState = yield select((state) => presentationSelectors.getIsUserVideoPlaying(state, { Id: userId }))
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  jitsiRoom.sendCommandOnce(TabeebConnectEvents.SET_REMOTE_USER_VIDEO_STATE, {
    value: userId,
    attributes: {
      xmlns: `http://jitsi.org/jitmeet/${TabeebConnectEvents.SET_REMOTE_USER_VIDEO_STATE}`,
      videoState: !videoState,
    },
  })
}

function* muteRemoteAudio(action) {
  const userId = action.payload
  const audioState = yield select((state) => presentationSelectors.getIsUserAudioPlaying(state, { Id: userId }))
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  jitsiRoom.sendCommandOnce(TabeebConnectEvents.SET_REMOTE_USER_AUDIO_STATE, {
    value: userId,
    attributes: {
      xmlns: `http://jitsi.org/jitmeet/${TabeebConnectEvents.SET_REMOTE_USER_AUDIO_STATE}`,
      audioState: !audioState,
    },
  })
}

function* updateRemoteAudioIndicator({ payload }) {
  const jitsiRoom = yield select((state) => state.presentation.conference.room)

  jitsiRoom.sendCommandOnce(TabeebConnectEvents.ON_REMOTE_AUDIO_INDICATOR_UPDATED, {
    value: payload,
    attributes: {
      xmlns: `http://jitsi.org/jitmeet/${TabeebConnectEvents.ON_REMOTE_AUDIO_INDICATOR_UPDATED}`,
    },
  })
}

function* onSnapshotTaking(action) {
  const selectedUser = yield select((state) => getSelectedUser(state))

  if (!selectedUser) {
    return
  }

  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const generatedId = generateId()
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  jitsiRoom.sendCommandOnce(TabeebConnectEvents.TAKING_SNAPSHOT, {
    value: selectedUser.id,
    attributes: {
      snapshoptId: generatedId,
      timestamp: new Date(),
      sourceUserId: currentUserId,
      targetUserId: selectedUser.id,
      xmlns: `http://jitsi.org/jitmeet/${TabeebConnectEvents.TAKING_SNAPSHOT}`,
    },
  })
}

function* onSnapshotCreated(action) {
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  jitsiRoom.sendCommandOnce(TabeebConnectEvents.SNAPSHOT_CREATED, {
    attributes: {
      xmlns: `http://jitsi.org/jitmeet/${TabeebConnectEvents.SNAPSHOT_CREATED}`,
    },
  })
}

function* onTrackMuteChanged(action) {
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  jitsiRoom.sendCommandOnce(TabeebConnectEvents.TRACK_MUTE_UPDATED, {
    attributes: {
      xmlns: `http://jitsi.org/jitmeet/${TabeebConnectEvents.TRACK_MUTE_UPDATED}`,
    },
  })
}

function* onLocalStatsReceived(action) {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  yield put(usersActions.updateBitrate({ userId: currentUserId, bitrate: action.payload.bitrate }))
  yield put(
    usersActions.updateQuality({
      userId: currentUserId,
      connectionQuality: action.payload.connectionQuality,
    })
  )
}

// TODO: Implement remote stats functionality
function* onRemoteStatsReceived(action) {
  const userId = parseInt(action.payload[0], 10)
  const connectivity = action.payload[1]

  yield put(usersActions.updateBitrate({ userId, bitrate: connectivity.bitrate }))
  yield put(
    usersActions.updateQuality({
      userId,
      connectionQuality: connectivity.connectionQuality,
    })
  )
}

function* onTracksInitializationFailed({ payload }) {
  const userId = parseInt(payload[0].value, 10)
  yield put(usersActions.resetVideoLoadForUser(userId))
}

function* trackAudioLevelChanged(action) {
  const [participantId, audioLevel] = action.payload
  const userId = parseInt(participantId, 10)

  yield updateAudioLevelIndicatorForUser(userId, audioLevel)
}

function* onShowSpeaker(action) {
  const [participantId, audioLevel] = action.payload
  const userId = parseInt(participantId, 10)

  yield showSpeaker(userId, audioLevel)
}

function* enableMobilePriorityView() {
  const presenterId = yield select(contentStateSelectors.getPresenterId)
  const currentUserId = yield select(getCurrentUserId)

  const largeVideoUser = yield select(getLargeVideoUser)

  if ((!largeVideoUser || largeVideoUser.id !== presenterId) && currentUserId !== presenterId) {
    yield put(trackActions.toggleLargeVideo({ userId: presenterId }))
  }

  const jitsiRoom = yield select((state) => state.presentation.conference.room)

  if (!jitsiRoom) {
    return
  }

  const jitsiRoomEvents = window.JitsiMeetJS.events.conference

  mobilePriorityViewEventChannel = yield fork(
    bindEvent,
    jitsiRoom,
    jitsiRoomEvents.TRACK_AUDIO_LEVEL_CHANGED,
    conferenceActions.showSpeaker,
    externalEventChannels
  )
}

function* disableMobilePriorityView(action) {
  if (!mobilePriorityViewEventChannel) {
    return
  }

  yield cancel(mobilePriorityViewEventChannel)
}

function* onGridViewEnabled(action) {
  yield put(trackActions.toggleMobilePriorityView(false))
}

function* toggleMobilePriorityView(action) {
  if (!isMobileOnly) {
    return
  }

  const enablePriorityView = action.payload

  if (enablePriorityView) {
    yield call(enableMobilePriorityView)
  } else {
    yield call(disableMobilePriorityView)
  }
}

function* conferenceSaga() {
  yield all([
    takeLatest(conferenceActions.initRoom, initRoom),
    takeLatest(conferenceActions.leaveRoom, leaveRoom),
    takeLatest(conferenceActions.bindRoomEvents, bindRoomEvents),
    takeLatest(conferenceActions.unBindRoomEvents, unBindRoomEvents),
    takeLatest(conferenceActions.onTrackAdded, onTrackAdded),
    takeLatest(conferenceActions.onTrackRemoved, onTrackRemoved),
    takeLatest(conferenceActions.onKicked, onKicked),
    takeLatest(conferenceActions.onUserLeft, onUserLeft),
    takeLatest(conferenceActions.onConferenceJoined, onConferenceJoined),
    takeLatest(conferenceActions.onConferenceFailed, onConferenceFailed),
    takeLatest(conferenceActions.onUserJoined, onUserJoined),
    takeLatest(usersActions.localStatsReceived, onLocalStatsReceived),
    takeLatest(usersActions.remoteStatsReceived, onRemoteStatsReceived),
    takeLatest(muteVideoActions.muteRemoteVideo, muteRemoteVideo),
    takeLatest(muteAudioActions.muteRemoteAudio, muteRemoteAudio),
    takeLatest(muteVideoActions.onRemoteVideoMuted, onTrackMuteChanged),
    takeLatest(muteAudioActions.onRemoteAudioMuted, onTrackMuteChanged),
    takeLatest(snapshotActions.onSnapshotTaking, onSnapshotTaking),
    takeLatest(snapshotActions.onSnapshotCreated, onSnapshotCreated),
    takeLatest(muteAudioActions.updateRemoteAudioIndicator, updateRemoteAudioIndicator),
    takeLatest(conferenceActions.onTracksInitializationFailed, onTracksInitializationFailed),
    takeEvery(conferenceActions.trackAudioLevelChanged, trackAudioLevelChanged),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onConferenceJoined, showJoinNotification),
    takeLatest(enableGridView, onGridViewEnabled),
    takeLatest(trackActions.toggleMobilePriorityView, toggleMobilePriorityView),
    takeLatest(conferenceActions.showSpeaker, onShowSpeaker),
  ])
}

export default conferenceSaga
