import { useCallback, useMemo, useState } from 'react'
import { useApiRequest, useDebouncedValue } from '@tabeeb/shared/utils/hooks'
import {
  createCertificate,
  getCertificates,
  updateCertificate,
  reviewCertificate,
  getAllCertificateCategories,
  getAllCertificateTypes,
  deleteCertificate,
  getMyCertificates,
  getLocations,
} from '@tabeeb/modules/certificates/actions'
import { useMutation } from '@tabeeb/modules/certificates/hooks'
import { getUsersByIdentityGuids } from '../../../users/actions'

function useCertificatesGrid({ getCertificatesParams, personalCertificates = false }) {
  const { status, response, loading, onMutated, abortRequest } = useApiRequest({
    defaultResponse: { Certificates: [], TotalCount: 0 },
    request: personalCertificates ? getMyCertificates.request : getCertificates.request,
    payload: getCertificatesParams,
  })

  const getUsersPayload = useMemo(() => {
    const guids = response.Certificates.filter((_) => Boolean(_.OwnerIdentityGuid)).map((_) => _.OwnerIdentityGuid)
    const uniqueGuids = [...new Set(guids)]
    return { identityGuids: uniqueGuids }
  }, [response.Certificates])

  const { loading: loadingGetUsers, response: responseGetUsers } = useApiRequest({
    defaultResponse: [],
    request: getUsersByIdentityGuids.request,
    payload: getUsersPayload,
    enabled: !personalCertificates && getUsersPayload.identityGuids.length > 0,
  })

  const {
    status: getAllCertificateCategoriesStatus,
    response: getAllCertificateCategoriesResponse,
    loading: getAllCertificateCategoriesLoading,
    abortRequest: abortGetAllCertificateCategoriesRequest,
  } = useApiRequest({
    defaultResponse: [],
    request: getAllCertificateCategories.request,
  })

  const [certificateTypesSearch, setCertificateTypesSearch] = useState('')
  const debouncedCertificateTypesSearch = useDebouncedValue(certificateTypesSearch, 500)

  const getAllTypesPayload = useMemo(
    () => ({ showCurrentUserCertificates: personalCertificates, search: debouncedCertificateTypesSearch }),
    [debouncedCertificateTypesSearch, personalCertificates]
  )
  const {
    response: getAllCertificateTypesResponse,
    loading: getAllCertificateTypesLoading,
    abortRequest: abortAllCertificateTypesRequest,
    onMutated: getAllCertificateTypesOnMutated,
  } = useApiRequest({
    defaultResponse: [],
    request: getAllCertificateTypes.request,
    payload: getAllTypesPayload,
  })

  const {
    response: getLocationsResponse,
    loading: getLocationsLoading,
    abortRequest: abortLocationRequest,
  } = useApiRequest({
    defaultResponse: [],
    request: getLocations.request,
  })

  const updateMutation = useMutation({
    mutationRequest: updateCertificate.request,
  })

  const createMutation = useMutation({
    mutationRequest: createCertificate.request,
  })

  const reviewMutation = useMutation({
    mutationRequest: reviewCertificate.request,
  })

  const deleteMutation = useMutation({
    mutationRequest: deleteCertificate.request,
  })

  const reRequestAll = useCallback(() => {
    onMutated()
    getAllCertificateTypesOnMutated()
  }, [onMutated, getAllCertificateTypesOnMutated])

  return {
    getCertificatesResponse: response,
    getCertificatesStatus: status,
    abortGetCertificatesRequest: abortRequest,
    getCertificatesLoading: loading,
    onGetCertificatesMutated: onMutated,
    // get all categories
    getAllCertificateCategoriesResponse,
    getAllCertificateCategoriesStatus,
    getAllCertificateCategoriesLoading,
    abortGetAllCertificateCategoriesRequest,
    // get all types
    getAllCertificateTypesResponse,
    getAllCertificateTypesLoading,
    abortAllCertificateTypesRequest,
    // update
    updateCertificateLoading: updateMutation.loading,
    updateCertificateMutate: updateMutation.mutate,
    // create
    createCertificateLoading: createMutation.loading,
    createCertificateMutate: createMutation.mutate,
    // review
    reviewCertificateLoading: reviewMutation.loading,
    reviewCertificateMutate: reviewMutation.mutate,
    reRequestAll,
    setCertificateTypesSearch,
    // delete
    deleteCertificateLoading: deleteMutation.loading,
    deleteCertificateMutate: deleteMutation.mutate,
    // get users
    loadingGetUsers,
    responseGetUsers,
    // locations
    getLocationsResponse,
    getLocationsLoading,
    abortLocationRequest,
  }
}

export default useCertificatesGrid
