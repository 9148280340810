import { combineActions, handleActions } from 'redux-actions'
import {
  closeAiReportDialog,
  selectAiObject,
  selectTemplate,
  createPublishTemplate,
  getAiObjectAnalysis,
} from '../actions'
import { apiColumnMap } from '../services/apiColumnMap'

const defaultState = { id: 0, columns: null }

export default handleActions(
  {
    [selectTemplate](state, action) {
      return action.payload
    },
    [getAiObjectAnalysis.success](state, action) {
      if (!action.payload.columns) {
        const columns = action.response.data.columns.map((c) => ({ field: c.Name }))
        return { ...state, columns } // all columns are selected
      }
      return state
    },
    [createPublishTemplate.success](state, action) {
      const newTemplate = action.response.data

      return {
        name: newTemplate.Name,
        columns: newTemplate.Columns.map((c) => apiColumnMap(c)),
        id: newTemplate.Id,
      }
    },
    [combineActions(closeAiReportDialog, selectAiObject)]() {
      return defaultState
    },
  },
  defaultState
)
