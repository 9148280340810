import { useState } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

import styles from './styles'

const Logo = ({ classes, url, onClick }) => {
  const [loaded, setLoaded] = useState(false)

  const handleFailed = () => {
    setLoaded(false)
    window.dispatchEvent(new CustomEvent('resize'))
  }

  const handleLoad = () => {
    setLoaded(true)
    window.dispatchEvent(new CustomEvent('resize'))
  }

  return (
    <img
      className={classes.logo}
      style={{
        opacity: loaded ? 1 : 0,
      }}
      onClick={onClick}
      onLoad={handleLoad}
      onError={handleFailed}
      src={url}
      alt='logo'
    />
  )
}

Logo.propTypes = {
  classes: PropTypes.shape({
    logo: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(Logo)
