export default (theme) => {
  return {
    paper: {
      position: 'absolute',
      margin: 0,
      maxWidth: theme.breakpoints.values.lg,
    },
    backdrop: {
      background: 'transparent',
    },
    header: {
      display: 'flex',
      alignItems: 'flex-start',
      color: theme.palette.text.secondary,
      padding: theme.spacing() * 2,
    },
    content: {
      position: 'relative',
      flexGrow: 1,
      height: 768,
      overflowY: 'auto',
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      marginTop: -theme.spacing(),
      marginRight: -theme.spacing(),
    },
  }
}
