import { memo, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ConfirmationDialog } from '@tabeeb/shared/dialogs'
import { sessionTitleFormatter } from '@tabeeb/shared/utils/text'
import { deleteSession } from '@tabeeb/modules/sessionsPage/actions'
import { getCanDeleteSession } from '@tabeeb/modules/sessionsPage/selectors/sessionActions'

const DeleteSessionDialog = ({ open, session, onClose }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(() => {
    dispatch(deleteSession.request({ contentId: session.Id }))

    onClose()
  }, [dispatch, session.Id, onClose])

  const canDelete = useSelector((state) => getCanDeleteSession(state, session))

  useLayoutEffect(() => {
    if (open && !canDelete) {
      onClose()
    }
  }, [open, canDelete, onClose])

  if (!canDelete) {
    return null
  }

  return (
    <ConfirmationDialog
      isOpen={open}
      title={sessionTitleFormatter.format('Delete session')}
      dialogMessage={sessionTitleFormatter.format('Are you sure you want to delete this session?')}
      onCloseDialog={onClose}
      onSubmit={onSubmit}
      cancelButtonText='No'
      submitButtonText='Yes'
    />
  )
}

DeleteSessionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(DeleteSessionDialog)
