import { ListItemButton, alpha, styled } from '@mui/material'

export const VersionsListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginBlock: theme.spacing(0.5),

  '&[data-pending-version="true"]': {
    background: alpha(theme.palette.primary.main, 0.04),
    border: `1px solid ${theme.palette.primary.light}`,
  },

  '&[data-active-version="true"]': {
    background: alpha(theme.palette.success.main, 0.04),
    border: `1px solid ${theme.palette.success.light}`,
  },

  '&[data-expired-version="true"]': {
    background: alpha(theme.palette.error.main, 0.04),
    border: `1px solid ${theme.palette.error.light}`,
  },
}))
