import { isMobile } from 'react-device-detect'
// TODO: remove 'isMobile' when redesign

export default (theme) => {
  return {
    container: {
      position: 'relative',
      display: 'flex',
      flexShrink: 0,
      height: isMobile ? 176 : '100%',
      marginLeft: theme.spacing(),
      width: isMobile ? 'auto' : 270,
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden',
      userSelect: 'none',
      transition: 'box-shadow .3s ease',
    },
    recording: {
      boxShadow: `0px 0px 2px 2px ${theme.palette.error.light}`,
    },
    header: {
      position: 'absolute',
      top: theme.spacing() / 2,
      left: theme.spacing() / 2,
      display: 'flex',
      alignItems: 'center',
      zIndex: 1,

      '& > div': {
        marginRight: theme.spacing() / 2,
      },
    },
    sticky: {
      position: 'sticky !important',
      left: 0,
      zIndex: 11,
    },
    footer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 1,
    },
    userControls: {
      display: 'flex',
      height: '100%',
    },
    border: {
      width: `100%`,
      height: `100%`,
      position: 'absolute',
      zIndex: 1,
      transition: 'box-shadow .3s ease',
      borderRadius: theme.shape.borderRadius,
    },
    mobileOffline: {
      opacity: 0.5,
    },
  }
}
