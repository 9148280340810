import { TenantPermission, SessionPermission, FolderPermission } from '@tabeeb/enums'

const migrations = {
  1: (state) => {
    const transformPermissions = (permissionsEnum, oldPermissions) => {
      const newPermissions = []

      Object.values(permissionsEnum).forEach((permissionValue) => {
        if ((BigInt(oldPermissions) & BigInt(2 ** permissionValue)) === BigInt(2 ** permissionValue)) {
          newPermissions.push(permissionValue)
        }
      })

      return newPermissions
    }

    return {
      ...state,
      permissions: {
        tenant: transformPermissions(TenantPermission, state.permissions.tenant),
        session: transformPermissions(SessionPermission, state.permissions.session),
        folder: transformPermissions(FolderPermission, state.permissions.folder),
      },
    }
  },
}

export default migrations
