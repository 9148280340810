import { put, select, takeLatest, all } from 'redux-saga/effects'
import * as tenantFormsActions from '../actions'

import { ConditionTypes } from '../../../Enums'
import * as notificationActions from '../../notification/actions'
import * as tenantConfigActions from '../../tenantConfigPage/actions'
import { accountSelectors } from '../../account'
import { getFormTitle, getSubdomain } from '../../appConfigState/selectors'
import { removeTemporaryFields } from '../../tenantConfigPage/services/configHelpers'
import * as tenantFormsSelectors from '../selectors'

function* openAvailableFormsForTenantDialog() {
  yield put(tenantFormsActions.getAvailableFormsForTenantRequest())
}

function* getAvailableFormsForTenantSuccess({ payload, response }) {
  yield put(tenantFormsActions.setFormsForTenant(response.data))
}

function* prepareAttachingFormToTenant(action) {
  const formId = action.payload.FormId
  const subdomain = yield select(getSubdomain)
  const attachModel = {
    Subdomain: subdomain,
    FormId: formId,
  }
  yield put(tenantFormsActions.attachFormToTenantRequest(attachModel))
}

function* attachFormToTenantSuccess(action) {
  const subdomain = yield select(getSubdomain)
  const formTitle = yield select(getFormTitle)

  yield put(tenantFormsActions.closeAvailableFormsForTenantDialog())
  yield put(tenantFormsActions.getTenantFormsRequest(subdomain))
  yield put(notificationActions.onAddInfoNotification({ message: `${formTitle} was successfully attached to tenant` }))
}

function* detachFormFromTenantSuccess(action) {
  const subdomain = yield select(getSubdomain)
  const formTitle = yield select(getFormTitle)

  yield put(tenantFormsActions.getTenantFormsRequest(subdomain))
  yield put(
    notificationActions.onAddInfoNotification({ message: `${formTitle} was successfully detached from tenant` })
  )

  const tenantConfigForUpdate = yield select((state) => state.tenantConfigPage.tenantConfigForUpdate)

  if (tenantConfigForUpdate.useSessionNameConvention) {
    const newConfig = { ...tenantConfigForUpdate, useSessionNameConvention: false }
    removeTemporaryFields(newConfig)
    yield put(tenantConfigActions.updateConfigRequest({ subdomain: newConfig.subdomain, config: newConfig }))
  }
}

function* getTenantFormsSuccess({ payload, response }) {
  yield put(tenantFormsActions.setTenantForms(response.data))
}

function* openTenantFormsDialog({ payload }) {
  let subdomain = yield select(getSubdomain)

  if (payload?.subdomain) {
    subdomain = payload.subdomain
  }

  yield put(tenantFormsActions.getTenantFormRequest(subdomain))
}

function* getTenantFormSuccess({ response }) {
  yield put(tenantFormsActions.addOpenedTenantForm(response.data))
}

function* closeTenantFormsDialog() {
  yield put(tenantFormsActions.resetTenantFormAnswers())
  yield put(tenantFormsActions.resetOpenedTenantForms())
}

function* checkTenantFormConditions({ payload }) {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  const data = {
    FormControlId: payload.formControlId,
    FormId: payload.formId,
    CheckedFormControlOptionId: payload.optionId,
    UserId: currentUserId,
    Value: payload.value,
  }

  yield put(tenantFormsActions.checkTenantFormConditionsRequest(data))
}

function* removeTenantFormAnswersForSubforms({ payload }) {
  const formsForRemove = payload

  const actions = []

  formsForRemove.forEach((form) => {
    form.Controls.forEach((control) => {
      actions.push(tenantFormsActions.removeTenantFormAnswersForControl(control.Id))
    })
  })

  yield all(actions.map((action) => put(action)))
}

function* checkTenantFormConditionsSuccess({ payload, response }) {
  const isTenantFormsDialogOpen = Boolean(yield select(tenantFormsSelectors.getSncType))
  const openedForms = yield select(tenantFormsSelectors.getOpenedTenantForms)
  const currentForm = openedForms.find((item) => item.Id === payload.FormId)
  const currentControl = currentForm.Controls.find((control) => control.Id === payload.FormControlId)

  if (!isTenantFormsDialogOpen) return

  const subFormsForRemove = yield getSubFormsForRemove(currentForm.Id, currentControl.Id)

  if (subFormsForRemove.length > 0) {
    yield put(tenantFormsActions.removeTenantFormAnswersForSubforms(subFormsForRemove))
    yield put(tenantFormsActions.removeOpenedTenantSubForms(subFormsForRemove))
  }

  const condition = response.data

  if (condition !== null && condition.Type === ConditionTypes.AttachForm) {
    const formId = condition.Solution

    yield put(
      tenantFormsActions.getAdditionalTenantFormRequest({
        formId,
        attachedByForm: { formId: currentForm.Id, controlId: currentControl.Id },
      })
    )
  }
}

function* getAdditionalTenantFormSuccess({ payload, response }) {
  const { attachedByForm } = payload
  const attachedForm = response.data
  attachedForm.AttachedByForm = attachedByForm
  yield put(tenantFormsActions.addOpenedTenantForm(attachedForm))
}

function* getSubFormsForRemove(parentFormId, controlId) {
  const openedForms = yield select((state) => state.tenantForms.openedTenantForms)
  const formsForRemove = []

  const firstChildForm = openedForms.find(
    (form) =>
      form.AttachedByForm && form.AttachedByForm.formId === parentFormId && form.AttachedByForm.controlId === controlId
  )

  if (firstChildForm) formsForRemove.push(firstChildForm)

  let targetForm = firstChildForm

  while (targetForm) {
    const nextChildForm = openedForms.find((form) => form.AttachedByForm?.formId === targetForm.Id)

    if (nextChildForm) {
      targetForm = nextChildForm
      formsForRemove.push(nextChildForm)
    } else {
      targetForm = null
    }
  }

  return formsForRemove
}

function* setTenantFormsSearchText({ payload }) {
  const searchText = payload
  const availableForms = yield select((state) => state.tenantForms.formsForTenant)
  if (!searchText) {
    return availableForms
  }

  const searchTextLowerCase = searchText.toLowerCase()
  const foundForms = availableForms.filter((form) => {
    const isContainsText = form.Name.toLowerCase().indexOf(searchTextLowerCase)
    if (isContainsText >= 0) {
      return form
    }
  })
  yield put(tenantFormsActions.setFoundFormsForTenant(foundForms))
}

function* closeAvailableFormsForTenantDialog() {
  yield put(tenantFormsActions.setTenantFormsSearchText(''))
  yield put(tenantFormsActions.setFoundFormsForTenant([]))
}

function* openFolder(action) {
  const folder = action.payload

  yield put(tenantFormsActions.setCurrentFolder(folder))

  const folderId = folder.id
  if (folderId) {
    yield put(tenantFormsActions.getAvailableFormsForTenantByFolderIdRequest(folderId))
  } else {
    yield put(tenantFormsActions.getAvailableFormsForTenantRequest())
    yield put(tenantFormsActions.getAllFoldersInTenantFormsRequest())
  }
}

function* tenantFormsSaga() {
  yield all([
    takeLatest(tenantFormsActions.openAvailableFormsForTenantDialog, openAvailableFormsForTenantDialog),
    takeLatest(
      [
        tenantFormsActions.getAvailableFormsForTenantSuccess,
        tenantFormsActions.getAvailableFormsForTenantByFolderIdSuccess,
      ],
      getAvailableFormsForTenantSuccess
    ),
    takeLatest(tenantFormsActions.prepareAttachingFormToTenant, prepareAttachingFormToTenant),
    takeLatest(tenantFormsActions.attachFormToTenantSuccess, attachFormToTenantSuccess),
    takeLatest(tenantFormsActions.detachFormFromTenantSuccess, detachFormFromTenantSuccess),
    takeLatest(tenantFormsActions.getTenantFormsSuccess, getTenantFormsSuccess),
    takeLatest(tenantFormsActions.openTenantFormsDialog, openTenantFormsDialog),
    takeLatest(tenantFormsActions.getTenantFormSuccess, getTenantFormSuccess),
    takeLatest(tenantFormsActions.closeTenantFormsDialog, closeTenantFormsDialog),
    takeLatest(tenantFormsActions.checkTenantFormConditions, checkTenantFormConditions),
    takeLatest(tenantFormsActions.checkTenantFormConditionsSuccess, checkTenantFormConditionsSuccess),
    takeLatest(tenantFormsActions.getAdditionalTenantFormSuccess, getAdditionalTenantFormSuccess),
    takeLatest(tenantFormsActions.removeTenantFormAnswersForSubforms, removeTenantFormAnswersForSubforms),
    takeLatest(tenantFormsActions.setTenantFormsSearchText, setTenantFormsSearchText),
    takeLatest(tenantFormsActions.closeAvailableFormsForTenantDialog, closeAvailableFormsForTenantDialog),
    takeLatest(tenantFormsActions.openFolder, openFolder),
  ])
}
export default tenantFormsSaga
