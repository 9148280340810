import PropTypes from 'prop-types'
import { Button, Grid } from '@material-ui/core'

import { ConfirmationDialog } from '@tabeeb/modules/shared/dialogs'
import AddEditDeviceModal from '@tabeeb/modules/admin/devices/components/AddEditDeviceModal'
import { DeviceCategoryType } from '@tabeeb/enums'

import HealthDevices from '../HealthDevices'

import useStyles from './styles'

const TabletsAssignation = ({
  handleAssignTabletClick,
  onClearTabletSubmit,
  assignedLife365DeviceStatus,
  confirmationDialogProps,
  healthDevices,
  showTabletsAssignation,
  openAddEditDeviceModal,
  addEditDeviceModalProps,
}) => {
  const classes = useStyles()
  return (
    <>
      <Grid container wrap='nowrap' direction='column' spacing={2}>
        {showTabletsAssignation && (
          <Grid item>
            <Button
              size='large'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={handleAssignTabletClick}
              disableElevation
            >
              Add health device
            </Button>
          </Grid>
        )}
        <HealthDevices
          healthDevices={healthDevices}
          openAddEditDeviceModal={openAddEditDeviceModal}
          onClearTabletSubmit={onClearTabletSubmit}
          assignedLife365DeviceStatus={assignedLife365DeviceStatus}
        />
      </Grid>
      <ConfirmationDialog {...confirmationDialogProps} />
      <AddEditDeviceModal {...addEditDeviceModalProps} />
    </>
  )
}

TabletsAssignation.propTypes = {
  handleAssignTabletClick: PropTypes.func.isRequired,
  onClearTabletSubmit: PropTypes.func.isRequired,
  assignedLife365DeviceStatus: PropTypes.shape({
    TabletId: PropTypes.string,
    BatteryLife: PropTypes.number,
    OnCharger: PropTypes.bool,
    DeviceTime: PropTypes.instanceOf(Date),
  }),
  confirmationDialogProps: PropTypes.shape({
    onCloseDialog: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string,
    submitButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
  }).isRequired,
  healthDevices: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      DeviceTypeId: PropTypes.number.isRequired,
      CategoryType: PropTypes.number.isRequired,
      VendorType: PropTypes.number.isRequired,
      ExternalId: PropTypes.string.isRequired,
      IsDisabled: PropTypes.bool.isRequired,
      UserId: PropTypes.string.isRequired,
      ProgramId: PropTypes.string,
      TenoviSensorCodeType: PropTypes.number,
    })
  ),
  showTabletsAssignation: PropTypes.bool.isRequired,
  openAddEditDeviceModal: PropTypes.func.isRequired,
  addEditDeviceModalProps: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    selectedDevice: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Description: PropTypes.string.isRequired,
      Type: PropTypes.number.isRequired,
      DeviceId: PropTypes.string.isRequired,
      IsDisabled: PropTypes.bool.isRequired,
      UserId: PropTypes.number.isRequired,
      ProgramId: PropTypes.string,
    }),
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showEmailField: PropTypes.bool,
    excludeDeviceTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DeviceCategoryType)).isRequired),
    assignedForUserId: PropTypes.number,
    onExited: PropTypes.func,
  }).isRequired,
}

export default TabletsAssignation
