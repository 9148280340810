import { memo } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

/**
 * @typedef {Object} PageLayoutProps
 * @property {import('react').ReactNode} children - The content of the page layout.
 * @property {import('react').ComponentType} [component] - The component type to render the layout.
 * @property {'none' | 'page'} [spacing] - The spacing type for the layout.
 * @property {'xs' | 'sm' | 'md' | 'lg' | 'xl'} [maxWidth] - The maximum width for the layout.
 */

/**
 * @param {PageLayoutProps} props
 */
const PageLayout = ({ children, component: Component, spacing, maxWidth, ...rest }) => {
  const margin = spacing === 'page' ? 2 : 0

  return (
    <Box display='flex' width='100%' minHeight='100%' maxHeight='100%' p={margin}>
      <Component
        sx={{
          display: 'flex',
          marginInline: 'auto',
          padding: spacing === 'page' ? 2 : 0,
          paddingTop: spacing === 'page' ? 1 : 0,
          flexDirection: 'column',
          minHeight: '100%', // (theme) => `calc(100% - ${theme.spacing(margin * 2)})`,
          maxHeight: '100%',
          width: '100%',
          maxWidth: maxWidth ? (theme) => theme.breakpoints.values[maxWidth] : 'initial',
        }}
        {...rest}
      >
        {children}
      </Component>
    </Box>
  )
}

PageLayout.defaultProps = {
  component: Box,
  spacing: 'none',
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.elementType,
  spacing: PropTypes.oneOf(['none', 'page']),
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
}

export default memo(PageLayout)
