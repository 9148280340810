import { Document, pdfjs, Page } from 'react-pdf'
import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, ButtonGroup, Button, Typography } from '@mui/material'
import { NavigateNext, NavigateBefore } from '@mui/icons-material'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

/**
 * Pdf file viewer, with pagination
 * @param {(string|object)} pdfFileSource - Pdf file source. File can be a URL, base64 content, Uint8Array
 * @param {function} onPdfLoadedSuccessfully - invoked when pdf was successfully loaded
 * @param {number} numberOfPdfPages - Total count of pdf pages
 * @param {function} setNumberOfPdfPages - Function to set total count of pdf pages
 * @param {function} nextPageClick - Function to handle next page click
 * @param {function} previousPageClick - Function to handle previous page click
 * @param {number} pageNumber - Current page number
 * @param {function} setInitialPage - Function to set initial (1) page number
 * @param {bool} showNavigation - Show navigation
 * @param {bool} showPageNumberCounter - Show page number counter
 * @param {number} pageHeight - Default pdf height in px
 * @param {number} pageWidth - Default pdf width in px. If you define width and height at the same time, height will be ignored
 * @param {function} onRootElementClick - Root element onClick handler
 * @param {function} setCurrentPdfDimensions - set current pdf dimensions
 * @param {object} pdfPageParentDivSx - sx
 * @returns {JSX.Element}
 */
const PdfViewer = ({
  pdfFileSource,
  onPdfLoadedSuccessfully,
  numberOfPdfPages,
  setNumberOfPdfPages,
  nextPageClick,
  previousPageClick,
  pageNumber,
  setInitialPage,
  showNavigation,
  showPageNumberCounter,
  pageHeight,
  pageWidth,
  onRootElementClick,
  setCurrentPdfDimensions,
  pdfPageParentDivSx,
}) => {
  const onLoadSuccess = useCallback(
    ({ numPages }) => {
      setNumberOfPdfPages(numPages)
      setInitialPage()
      onPdfLoadedSuccessfully()
    },
    [onPdfLoadedSuccessfully, setInitialPage, setNumberOfPdfPages]
  )

  return (
    <Box
      sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}
      onClick={(e) => {
        onRootElementClick(e)
        setInitialPage()
      }}
    >
      <Box sx={{ ...pdfPageParentDivSx, overflow: 'hidden' }}>
        <Document file={pdfFileSource} onLoadSuccess={onLoadSuccess}>
          <Page
            onLoadSuccess={(pdf) => {
              const width = pdf.view[2]
              const height = pdf.view[3]
              setCurrentPdfDimensions({ height, width })
            }}
            pageNumber={pageNumber}
            height={pageHeight}
            width={pageWidth}
          />
        </Document>
      </Box>
      {((showNavigation && numberOfPdfPages > 1) || showPageNumberCounter) && (
        <ButtonGroup
          variant='outlined'
          size='large'
          sx={{
            boxShadow: 2,
            zIndex: 1,
            backgroundColor: 'white',
            position: 'absolute',
            bottom: '2%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          onClick={(e) => {
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()
          }}
        >
          {showNavigation && (
            <Button
              disabled={pageNumber === 1}
              sx={{ pr: 0, borderColor: 'rgba(0, 0, 0, 0.12) !important' }}
              onClick={previousPageClick}
              startIcon={<NavigateBefore />}
            />
          )}
          {showPageNumberCounter && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: 1,
                borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
                borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
                minWidth: '50px',
              }}
            >
              <Typography>{`${pageNumber}/${numberOfPdfPages}`}</Typography>
            </Box>
          )}
          {showNavigation && (
            <Button
              disabled={pageNumber >= numberOfPdfPages}
              sx={{ pl: 0, borderColor: 'rgba(0, 0, 0, 0.12) !important' }}
              onClick={nextPageClick}
              endIcon={<NavigateNext />}
            />
          )}
        </ButtonGroup>
      )}
    </Box>
  )
}

PdfViewer.defaultProps = {
  onPdfLoadedSuccessfully: () => {},
  showNavigation: true,
  showPageNumberCounter: true,
  onRootElementClick: () => {},
  setCurrentPdfDimensions: () => {},
}

PdfViewer.propTypes = {
  pdfFileSource: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  onPdfLoadedSuccessfully: PropTypes.func,
  numberOfPdfPages: PropTypes.number,
  setNumberOfPdfPages: PropTypes.func,
  nextPageClick: PropTypes.func,
  previousPageClick: PropTypes.func,
  pageNumber: PropTypes.number,
  setInitialPage: PropTypes.func,
  showNavigation: PropTypes.bool,
  showPageNumberCounter: PropTypes.bool,
  pageHeight: PropTypes.number,
  pageWidth: PropTypes.number,
  onRootElementClick: PropTypes.func,
  setCurrentPdfDimensions: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  pdfPageParentDivSx: PropTypes.object,
}

export default PdfViewer
